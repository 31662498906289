import logo from './logo.svg';
import './App.css';
import React, { useCallback, useState, useEffect, useMemo } from 'react';
import AdminDash from './Pages/AdminDash'
import LandingPage from './Pages/LandingPage'
import Header from './Components/Header'


import { ConnectionProvider, WalletProvider, useConnection, useWallet } from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork, WalletNotConnectedError } from '@solana/wallet-adapter-base';
import {
    GlowWalletAdapter,
    LedgerWalletAdapter,
    PhantomWalletAdapter,
    SlopeWalletAdapter,
    SolflareWalletAdapter,
    SolletExtensionWalletAdapter,
    SolletWalletAdapter,
    TorusWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import {
    WalletModalProvider,
    WalletDisconnectButton,
    WalletMultiButton
} from '@solana/wallet-adapter-react-ui';

import { clusterApiUrl } from "@solana/web3.js";
require('@solana/wallet-adapter-react-ui/styles.css');

function App() {
  const [page, setPage] = useState("password")
  const [password, setPassword] = useState(null)

  const network = process.env.REACT_APP_SOLANA_NETWORK;
  const endpoint = process.env.QUICKNODE
  const wallets = useMemo(() => [
    new PhantomWalletAdapter(),
    new GlowWalletAdapter(),
    new SlopeWalletAdapter(),
    new SolflareWalletAdapter({ network }),
    new TorusWalletAdapter(),
  ],[network]);

  const { connection } = useConnection();
  const { publicKey, sendTransaction, wallet } = useWallet();

  return (
    <section className='bg-white py-24'>
      <div className="App">
      <ConnectionProvider endpoint={endpoint}>
          <WalletProvider wallets={wallets} autoConnect>
              <WalletModalProvider>
                <div className='text-end mb-1 flex justify-end'>
                    <WalletMultiButton className= 'customButton'/>
                </div>
                  <LandingPage/>
              </WalletModalProvider>
          </WalletProvider>
      </ConnectionProvider>
      </div>
    </section>
  );
}

export default App;
