import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { WalletNotConnectedError } from '@solana/wallet-adapter-base';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import BackLink from '../Components/BackLink';
import BuilderHeader from '../Components/BuilderHeader';
import DefaultTraitLayering from '../Components/DefaultTraitLayering';

import axios from "axios";


const CategoryOrder = (props) => {

  const [builderState, setBuilderState] = useState("Base")
  const [builderStates, setBuilderStates] = useState([])

  const [orderArrayDict, setOrderArrayDict] = useState({})

  const [categoryOrderArray, setCategoryOrderArray] = useState([])
  const [tempOrderArray, setTempOrderArray] = useState([])

  const [firstSwap, setFirstSwap] = useState()

  useEffect(() => {
    var data = JSON.stringify({
      "projectID": props.projectID,
      "builderState": builderState,
      "action": "getOrderLayerings"
    });

    var config = {
      method: 'post',
      url: 'https://59200uzilj.execute-api.us-east-1.amazonaws.com/Production/updatebuilderconfig',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      console.log(response.data)
      setOrderArrayDict(response.data.orderArrayDict)
      setBuilderStates(Object.keys(response.data.orderArrayDict))
      setCategoryOrderArray(response.data.orderArrayDict[builderState])
      setTempOrderArray(response.data.orderArrayDict[builderState])
    })
    .catch(function (error) {
      console.log(error);
    })
  }, []);

  const saveOrder = () => {
    var data = JSON.stringify({
      "action": "updateDefaultOrder",
      "orderArray": tempOrderArray,
      "builderState": builderState,
      "projectID": props.projectID
    });
    console.log(data)

    var config = {
      method: 'post',
      url: 'https://59200uzilj.execute-api.us-east-1.amazonaws.com/Production/updatebuilderconfig',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      console.log(response)
      setFirstSwap()
      setCategoryOrderArray(tempOrderArray)
    })
    .catch(function (error) {
      console.log(error);
    })
  }


  const handleClick = async(category) => {
    if (!firstSwap){
      setFirstSwap(category)
    }
    else{
      let newArray = [...tempOrderArray]
      let index1 = newArray.findIndex(item => item === firstSwap)
      let index2 = newArray.findIndex(item => item === category)

      var thing1 = newArray[index1];
      newArray[index1] = newArray[index2];
      newArray[index2] = thing1;

      setTempOrderArray(newArray)
      setFirstSwap()
    }
  }

  const switchBuilderState = (collectionName) => {
    console.log(collectionName, orderArrayDict)
    setBuilderState(collectionName)
    setCategoryOrderArray(orderArrayDict[collectionName])
    setTempOrderArray(orderArrayDict[collectionName])
  }

  return (
          <div className='w-[80%] ml-auto px-5'>
              {
                builderStates.length ?
                  <div>
                    <select className="bg-gray-light border-2 border-gray-400 font-bold text-[18px] px-2 uppercase cursor-pointer text-grey-deep w-[30%] h-12 leading-8" value={builderState} onChange={(e) => switchBuilderState(e.target.value)}>
                        {builderStates.map((collectionName) => (
                            <option value={collectionName} className="bg-gray-400 text-[16px] font-bold uppercase hover:bg-gray-deep cursor-pointer text-white hover:text-yellow-light px-2 py-[2px]">{collectionName}</option>
                        ))}
                    </select>
                  </div>
                  :
                  <div>
                  </div>
              }
              <div className='max-w-4xl grid grid-cols-2 gap-10 p-5  items-center'>
                  <div className='bg-gray-200 p-5 max-w-[300px] mx-auto'>
                      <h2 className='font-gilroy-bold text-gray-deep text-lg mb-3'>Trait layers</h2>
                      <p className='text-lg text-gray-400 mb-5 leading-5'>This is the current order to your categories:</p>
                        <ul className='flex flex-col gap-5 mb-5'>
                          {
                            tempOrderArray.map((category) => {
                            return (
                              <li className='w-full'> <button className='w-full p-1 bg-gray-300 text-white font-lg font-bold hover:bg-gray-deep active:bg-gray-deep focus:bg-gray-deep' onClick={() => handleClick(category)}>{category}</button></li>
                            )
                          })
                          }
                        </ul>
                        <div className='text-center'>
                            <p className='text-lg text-gray-400 mb-5 leading-5'>Click any to save order </p>
                            <button
                                 className="bg-black text-white text-sm py-2 px-6 font-gilroy-bold rounded-md"
                                 onClick={() => {saveOrder()}}>Save New Order!
                            </button>
                        </div>
                  </div>
                  <p className='text-lg text-gray-400 text-left'>
                      Trait layering is the default ordering of your layers. Traits will appear on top of them in this order for your builder
                      and customizer. If you would like to define custom logic for individual traits or
                      layers you can do so in the conflicts section of the config.
                  </p>
              </div>
          </div>

  )

};

export default CategoryOrder;
