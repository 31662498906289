import React, {useState, useEffect} from "react";
import axios from "axios";
import Arweave from 'arweave';
import ProgressBar from "@ramonak/react-progress-bar";

import Loader from "../assets/img/loading.gif"

import { Metaplex, keypairIdentity, walletAdapterIdentity, toMetaplexFileFromBrowser } from "@metaplex-foundation/js";
import { Connection, clusterApiUrl, PublicKey } from "@solana/web3.js";
import * as bs58 from "bs58";

import { WalletNotConnectedError } from '@solana/wallet-adapter-base';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';

import { uploadFile } from 'react-s3';
import S3 from 'react-aws-s3';
window.Buffer = window.Buffer || require("buffer").Buffer;


const MutationImageMapping = (props) => {

  const [assetDict, setAssetDict] = useState()
  const [categories, setCategories] = useState()
  const [uploadingCollection, setUploadingCollection] = useState(false)

  const [popupState, setPopupState] = useState()
  const [popup, setPopup] = useState(false)

  const [missingAssetDict, setMissingAssetDict] = useState({})
  const [mutatedAssetDict, setMutatedAssetDict] = useState({})
  const [gotMissingDict, setGotMissingDict] = useState(false)

  const [selectedCategory, setSelectedCategory] = useState()
  const [selectedTrait, setSelectedTrait] = useState()

  const [traitImage, setTraitImage] = useState()
  const [mappedTraitName, setMappedTraitName] = useState()


  useEffect(() => {
    var data = JSON.stringify({
      "action": "getMutationMapping",
      "projectID": props.projectID
    });

    var config = {
      method: 'post',
      url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/mutations',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': process.env.GATEWAY_KEY
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      console.log(response.data)
      if (Object.keys(response.data.missingAssetDict).length){
        setMissingAssetDict(response.data.missingAssetDict)
        setAssetDict(response.data.assetDict)
        setMutatedAssetDict(response.data.mutatedAssetDict)
        setSelectedCategory(Object.keys(response.data.missingAssetDict)[0])
      }
      setGotMissingDict(true)
    })
    .catch(function (error) {
      console.log(error);
    })
  }, []);

  const uploadTrait = async() => {

    const s3Config = {
      bucketName:"maxinbodyshop",
      region: "us-east-2",
      accessKeyId: process.env.AWS_KEY,
      secretAccessKey: process.env.AWS_SECRET,
      s3Url: 'https://maxinbodyshop.s3.amazonaws.com'
    }

    console.log(traitImage)

    const ReactS3Client = new S3(s3Config);

    const fileName = "mutations/" + props.projectID + "/" + traitImage.name.replaceAll(" ", "_")
    const link = "https://maxinbodyshop.s3.us-east-2.amazonaws.com/" + fileName
    let s3Response = await ReactS3Client.uploadFile(traitImage, fileName)

    var data = JSON.stringify({
      "projectID": props.projectID,
      "category": selectedCategory,
      "selectedTraitName": JSON.parse(selectedTrait),
      "traitName": mappedTraitName,
      "link": link,
      "action": "mapMutatedImage"
    });

    var config = {
      method: 'post',
      url: 'https://59200uzilj.execute-api.us-east-1.amazonaws.com/Production/uploadimages',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      console.log(response)
      var listTemp = missingAssetDict[selectedCategory]
      console.log(listTemp)
      var index = listTemp.indexOf(JSON.parse(selectedTrait))
      console.log(index)
      listTemp.splice(index, 1)
      console.log(listTemp)
      const missingDictCopy = {...missingAssetDict}
      console.log(missingDictCopy)
      missingDictCopy[selectedCategory] = listTemp
      console.log(missingDictCopy)
      setSelectedTrait()
      setMissingAssetDict(missingDictCopy)
    })
    .catch(function (error) {
      console.log(error);
    })

  }

  const saveMutation = () => {
    var data = JSON.stringify({
      "projectID": props.projectID,
      "action": "readyForMutations"
    });

    var config = {
      method: 'post',
      url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/editClients',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      props.setReadyForMutations(true)
    })
    .catch(function (error) {
      console.log(error);
    })
  }


  return (
    <div className="mb-20">
      {
        gotMissingDict ?
          <div>
          {
            Object.keys(missingAssetDict).length ?
              <div>
                {
                  selectedTrait && selectedTrait !== "----------------" ?
                  <div>
                    <br></br>
                    <h3 className='font-gilroy-bold text-xl text-gray-deep'>Upload Trait Image for: </h3>
                    <div className='grid grid-cols-2 m-auto bg-gray-300 gap-2 p-5 w-[40%]'>
                      <div className='flex flex-col relative'>
                        <div className="bg-gray-400 relative font-bold text-[16px] px-2 uppercase cursor-pointer text-white w-full h-7 leading-8">
                          Category
                        </div>
                        <p className='text-gray-deep text-lg p-1 leading-normal'>{selectedCategory}</p>
                      </div>
                      <div className='flex flex-col relative'>
                        <div className="bg-gray-400 relative font-bold text-[16px] px-2 uppercase cursor-pointer text-white w-full h-7 leading-8">
                            Trait Name
                        </div>
                        <p className='text-gray-deep text-lg p-1 leading-normal'>{JSON.parse(selectedTrait)}</p>
                      </div>
                    </div>
                    <br></br>
                    <button onClick={() => {setSelectedTrait()}} className="bg-gray-deep text-white py-2 w-40 font-bold text-sm text-center rounded-md">
                      Choose New Trait
                    </button>
                    <br></br><br></br><br></br>
                    <input onChange={e => {setTraitImage(e.target.files[0])}} name="png" accept="image/*" type="file" className="text-gray-deep font-bold " />
                    <br></br><br></br>
                    <h3 className='font-gilroy-bold text-xl text-gray-deep'>Trait Name</h3>
                    <input name={"newTrait"} type="text" onChange={e => {setMappedTraitName(e.target.value)}} className="text-gray-deep font-normal bg-gray-light w-[40%] border-2 border-gray-400 p-[1px] focus:outline-0" autoComplete="off"/>
                    <br></br><br></br>
                    {
                      traitImage ?
                      <button onClick={() => {uploadTrait()}} className="bg-red-deep text-white py-2 w-40 font-bold text-sm text-center rounded-md">
                        Upload and Save
                      </button>
                      :
                      <div>
                      </div>
                    }
                  </div>
                  :
                  <div>
                    <p className='font-gilroy-bold text-xl text-gray-deep mt-5'>There are still some traits that have not been mapped to new mutations yet</p>
                    <p className='text-gray-400 text-sm'>Please select an unused trait you would like to map</p>
                    <div className='flex w-[60%] justify-between items-start m-auto'>
                      <select className="bg-gray-light border-2 border-gray-400 font-bold text-[18px] px-2 uppercase cursor-pointer text-grey-deep w-full h-12 leading-8" value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
                          {Object.keys(missingAssetDict).map((category) => (
                              <option value={category} className="bg-gray-400 text-[16px] font-bold uppercase hover:bg-gray-deep cursor-pointer text-white hover:text-yellow-light px-2 py-[2px]">{category}</option>
                          ))}
                      </select>
                      <select className="bg-gray-light border-2 border-gray-400 font-bold text-[18px] px-2 uppercase cursor-pointer text-grey-deep w-full h-12 leading-8" value={selectedTrait} onChange={(e) => setSelectedTrait(e.target.value)}>
                        <option className="bg-gray-400 text-[16px] font-bold uppercase hover:bg-gray-deep cursor-pointer text-white hover:text-yellow-light px-2 py-[2px]">----------------</option>
                        {missingAssetDict[selectedCategory].map(trait => (
                            <option value={JSON.stringify(trait)} className="bg-gray-400 text-[16px] font-bold uppercase hover:bg-gray-deep cursor-pointer text-white hover:text-yellow-light px-2 py-[2px]">{trait}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                }
              </div>
              :
              <div className='mb-5'>
                <p className='font-gilroy-bold text-xl text-gray-deep mt-5'></p>
                <p className='text-gray-400 text-sm'>All of your traits have been mapped to a mutated trait.</p>
                <p className='text-gray-400 text-sm'>Click the button below to set your mutation details</p>
                <button onClick={() => {saveMutation()}} className='text-2xl text-white'>CONTINUE</button>
              </div>
          }
          </div>
          :
          <div>
          </div>
      }
    </div>
  )
};

export default MutationImageMapping;
