import React from 'react';

import EditBuilderConfig from '../Pages/EditBuilderConfig';
import BuilderHome from '../Pages/BuilderHome';

const BuilderHeader = (props) => {
  // <li className='w-[310px] bg-yellow-light px-2 py-1 text-white text-lg font-bold uppercase' onClick={() => {props.setNewPage("mutations")}}>Mutations</li>
  return (
    <nav className='absolute -left-[115px] top-16 z-50'>
      <ul className='flex flex-col justify-end items-end text-end gap-[2px]'>
          <li className='w-[310px] bg-yellow-light px-2 py-1 text-white text-xl font-bold uppercase' onClick={() => {props.setNewPage("traitShop")}}>Traits Shop</li>
          <li className='w-[310px] bg-rose-light px-2 py-1 text-white text-lg font-bold uppercase' onClick={() => {props.setNewPage("upgrades")}}>Upgrade Approvals</li>
          <li className='w-[310px] bg-yellow-deep px-2 py-1 text-white text-lg font-bold uppercase' onClick={() => {props.setInnerPage("default")}}>Collection Builder</li>
          {
            props.selectedPage === "config" ?
              <li className='w-[195px] bg-gray-deep px-2 py-1 text-yellow-deep text-lg font-normal' onClick={() => {props.resetConfig()}}>Edit Builder Config</li>
              :
              <li className='w-[195px] bg-gray-deep px-2 py-1 text-gray-light text-lg font-normal' onClick={() => {props.resetConfig()}}>Edit Builder Config</li>
          }
          {!props.hasMutation ? <div></div> : <li className='w-[310px] bg-red-light px-2 py-1 text-white text-lg font-bold uppercase' onClick={() => {props.setNewPage("mutations")}}>Mutations</li>}
          {!props.hasSlotMachine ? <div></div> : <li className='w-[310px] bg-red-deep px-2 py-1 text-white text-lg font-bold uppercase' onClick={() => {props.setNewPage("slotMachine")}}>Slot Machine</li>}
      </ul>
    </nav>
  )
}

export default BuilderHeader
