import React, {useState, useEffect} from "react";

const TraitHeader = (props) => {
  console.log(props)
  // <li className='w-[310px] bg-yellow-light px-2 py-1 text-white text-lg font-bold uppercase' onClick={() => {props.setNewPage("mutations")}}>Mutations</li>
  return (
    <nav className='absolute -left-[115px] top-16'>
        <ul className='flex flex-col justify-end items-end text-end gap-[2px]'>
            <li className='w-[310px] bg-yellow-light px-2 py-1 text-white text-xl font-bold uppercase' onClick={() => {props.setInnerPage("default")}}>Traits Shop</li>
            {
              props.selectedPage === "addTraits" ?
                <li className='w-[195px] bg-gray-deep px-2 py-1 text-yellow-deep text-lg font-normal' onClick={() => {props.setInnerPage("addTraits")}}>Add A New Trait</li>
                :
                <li className='w-[195px] bg-gray-deep px-2 py-1 text-gray-light text-lg font-normal' onClick={() => {props.setInnerPage("addTraits")}}>Add A New Trait</li>
            }
            {
              props.selectedPage === "pendingTraits" ?
                <li className='w-[195px] bg-gray-deep px-2 py-1 text-yellow-deep text-lg font-normal' onClick={() => {props.resetTrait("pending")}}>View Pending Traits</li>
                :
                <li className='w-[195px] bg-gray-deep px-2 py-1 text-gray-light text-lg font-normal' onClick={() => {props.resetTrait("pending")}}>View Pending Traits</li>
            }
            {
              props.selectedPage === "createdTraits" ?
                <li className='w-[195px] bg-gray-deep px-2 py-1 text-yellow-deep text-lg font-normal' onClick={() => {props.resetTrait("created")}}>View Created Traits</li>
                :
                <li className='w-[195px] bg-gray-deep px-2 py-1 text-gray-light text-lg font-normal' onClick={() => {props.resetTrait("created")}}>View Created Traits</li>
            }
            <li className='w-[310px] bg-rose-light px-2 py-1 text-white text-lg font-bold uppercase' onClick={() => {props.setNewPage("upgrades")}}>Upgrade Approvals</li>
            <li className='w-[310px] bg-yellow-deep px-2 py-1 text-white text-lg font-bold uppercase' onClick={() => {props.setNewPage("builder")}}>Collection Builder</li>
            {!props.hasMutation ? <div></div> : <li className='w-[310px] bg-red-light px-2 py-1 text-white text-lg font-bold uppercase' onClick={() => {props.setNewPage("mutations")}}>Mutations</li>}
            {!props.hasSlotMachine ? <div></div> : <li className='w-[310px] bg-red-deep px-2 py-1 text-white text-lg font-bold uppercase' onClick={() => {props.setNewPage("slotMachine")}}>Slot Machine</li>}
        </ul>
    </nav>
  )
}

export default TraitHeader
