import React from 'react'

const PendingTraitScroll = ({src,alt,traitNameTitle,traitName,traitSupply,
    traitSupplyStats,traitAmount, traitAmountStats, to, traitbtnText, traitText}) => {
  return (
    <div className='grid gap-2 text-center'>
        <img className='w-full h-auto border border-gray-400' src={src} alt={alt}/>
        <div className='bg-gray-400 text-white text-[16px] font-bold leading-4 p-1'>
            {traitNameTitle}<br/> {traitName}
        </div>
        <div className='bg-gray-400 text-white text-[16px] font-bold leading-4 p-1'>
            {traitSupply}<br/> {traitSupplyStats}</div>
        <div className='bg-gray-400 text-white text-[16px] font-bold leading-4 p-1'>{traitAmount}<br/> {traitAmountStats}</div>
    </div>
  )
}

export default PendingTraitScroll
