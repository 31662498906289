import React, {useState, useEffect} from "react";
import axios from "axios";
import Arweave from 'arweave';
import ProgressBar from "@ramonak/react-progress-bar";

import Loader from "../assets/img/loading.gif"

import { Metaplex, keypairIdentity, walletAdapterIdentity, toMetaplexFileFromBrowser } from "@metaplex-foundation/js";
import { Connection, clusterApiUrl, PublicKey } from "@solana/web3.js";
import * as bs58 from "bs58";

import { WalletNotConnectedError } from '@solana/wallet-adapter-base';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';

import { uploadFile } from 'react-s3';
import S3 from 'react-aws-s3';
window.Buffer = window.Buffer || require("buffer").Buffer;


const Onboarding = (props) => {
  var JSZip = require("jszip");
  const [uploadedHash, setUploadedhash] = useState()
  const [collectionName, setCollectionName] = useState()
  const [saving, setSaving] = useState(false)

  const [assetZip, setAssetZip] = useState()

  const [hash, setHash] = useState()
  const [art, setArt] = useState()
  const [name, setName] = useState()
  const [logo, setLogo] = useState()
  const [logoFile, setLogoFile] = useState()
  const [metadata, setMetadata] = useState()
  const [hashLink, setHashLink] = useState()
  const [gettingMetadata, setGettingMetadata] = useState(false)
  const [retrievedMetadata, setRetrievedMetadata] = useState(false)

  const [alreadyCreated, setAlreadyCreated] = useState([])
  const [tryAgain, setTryAgain] = useState(false)
  const [checkingZip, setCheckingZip] = useState(false)
  const [comparisonError, setComparisonError] = useState()
  const [errorArray, setErrorArray] = useState([])

  const [zipDict, setZipDict] = useState()
  const [zipNameDict, setZipNameDict] = useState()
  const [assetDict, setAssetDict] = useState()

  const [categories, setCategories] = useState()
  const [uploadingCollection, setUploadingCollection] = useState(false)

  const [popupState, setPopupState] = useState()
  const [popup, setPopup] = useState(false)

  const [colorOne, setColorOne] = useState()
  const [colorTwo, setColorTwo] = useState()
  const [colorThree, setColorThree] = useState()

  const [uploadCategory, setUploadCategory] = useState()

  const [hasUpgrade, setHasUpgrade] = useState(false)
  const [upgradeField, setUpgradeField] = useState()
  const [sampleMetadata, setSampleMetadata] = useState([])

  const [artworkArray, setArtworkArray] = useState([])
  const [nonArtworkArray, setNonArtworkArray] = useState([])

  const [metadataDetails, setMetadataDetails] = useState(false)
  const [metadataCategories, setMetadataCategories] = useState([])
  const [attributeDict, setAttributeDict] = useState({})

  const [upgradeNameDict, setUpgradeNameDict] = useState({})
  const [upgradesLeftToUpload, setUpgradesLeftToUpload] = useState([])

  const [uploadCollectionName, setUploadCollectionName] = useState()
  const [upgradeCategoryDict, setUpgradeCategoryDict] = useState({})

  const [sorciesArray, setSorciesArray] = useState(['Background'])


  useEffect(()=> {
    console.log(nonArtworkArray, artworkArray)
  }, [nonArtworkArray]);

  useEffect(() => {
    var data = JSON.stringify({
      "action": "getOnboardingStep",
      "projectID": props.projectID
    });

    var config = {
      method: 'post',
      url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/editClients',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': process.env.GATEWAY_KEY
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      console.log(response.data)
      setHash(response.data.uploadedHash)
      setArt(response.data.uploadedArt)
      setName(response.data.collectionNameUploaded)
      setLogo(response.data.hasLogo)
      setMetadata(response.data.uploadedMetadata)
      if (response.data.uploadedMetadata){
        setAttributeDict(response.data.attributeDictionaryTemp)
        setMetadataCategories(Object.keys(response.data.attributeDictionaryTemp))
        setNonArtworkArray(Object.keys(response.data.attributeDictionaryTemp))
      }
      setHashLink(response.data.hashLink)
      setAlreadyCreated(response.data.alreadyCreated)
      setMetadataDetails(response.data.metadataDetails)
      if (response.data.metadataDetails){
        setAttributeDict(response.data.attributeDict)
        setHasUpgrade(response.data.metadataDetailsDict.hasUpgrade)
        setArtworkArray(response.data.metadataDetailsDict.artworkArray)
        if (response.data.metadataDetailsDict.hasUpgrade){
          setUpgradeNameDict(response.data.metadataDetailsDict.upgradeNameDict)
          setUpgradeField(response.data.metadataDetailsDict.upgradeField)
          setUpgradesLeftToUpload(response.data.upgradesLeftToUpload)
        }
      }
    })
    .catch(function (error) {
      console.log(error);
    })
  }, []);

  useEffect(() => {
    if (!uploadedHash){
      setSampleMetadata([])
      setNonArtworkArray([])
      setArtworkArray([])
    }
  }, [uploadedHash]);


  useEffect(() => {
    // this is failing when uploading a new zip for the upgrade collectoin
    if (attributeDict && zipNameDict && uploadCollectionName){
      console.log(attributeDict, zipNameDict, uploadCollectionName)
      setErrorArray([])
      let errorArrayTemp = []
      let comparisonError
      Object.keys(attributeDict[uploadCollectionName]).forEach(category => {
        if (!(Object.keys(zipNameDict).includes(category))){
          errorArrayTemp.push("Category Error: " + category + " was found in your metadata but not in your zip file. You either forgot this category or have it spelled a different way.")
        }
      })
      if (!errorArrayTemp.length){
        Object.keys(attributeDict[uploadCollectionName]).forEach(category => {
          let traitArray = attributeDict[uploadCollectionName][category]
          traitArray.map(traitName => {
            if (!traitName.toLowerCase().includes("none")){
              if (!zipNameDict[category].includes(traitName)){
                errorArrayTemp.push("Trait Error: " + traitName + " under the category " + category + " was found in your metadata but not in your zip file. This trait is either missing or spelled in a different way.")
              }
            }
          })
        })
      }
      console.log(errorArrayTemp)


      if (errorArrayTemp.length){
        setZipDict()
        setCheckingZip(false)
        setErrorArray(errorArrayTemp)
      }
      else{
        uploadCollection()
        setErrorArray([])
      }
    }
  }, [attributeDict, zipNameDict]);

  useEffect(() => {
    if (comparisonError){
      setCheckingZip(false)
      setZipDict()
    }
  }, [comparisonError]);

  useEffect(() => {
    if (upgradeField){
      let upgradeCategoryDictTemp = {}
      attributeDict[upgradeField].map(metadataField => {
        upgradeCategoryDictTemp[metadataField] = []
      })
      setUpgradeCategoryDict(upgradeCategoryDictTemp)
    }
  }, [upgradeField]);

  const saveCollectionName = () => {
    var data = JSON.stringify({
      "projectID": props.projectID,
      "collectionName": collectionName,
      "action": "setCollectionName"
    });

    var config = {
      method: 'post',
      url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/editClients',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      console.log(response)
      setName(true)
    })
    .catch(function (error) {
      console.log(error);
    })
  }

  const saveHash = async() => {
    let fileName = "hashes/" + props.projectID.toString() + "/" + uploadedHash.name.replaceAll(" ", "_").replaceAll(".json", "")
    let link = "https://maxinbodyshop.s3.us-east-2.amazonaws.com/" + fileName + ".json"

    const s3Config = {
      bucketName:"maxinbodyshop",
      region: "us-east-2",
      accessKeyId: process.env.AWS_KEY,
      secretAccessKey: process.env.AWS_SECRET,
      s3Url: 'https://maxinbodyshop.s3.amazonaws.com'
    }

    const ReactS3Client = new S3(s3Config);

    const imageUpload = await ReactS3Client.uploadFile(uploadedHash, fileName)

    var data = JSON.stringify({
      "projectID": props.projectID,
      "hashListURL": link,
      "action": "setHashList"
    });

    var config = {
      method: 'post',
      url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/editClients',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      setHash(true)
      getMetadata(link)
    })
    .catch(function (error) {
      console.log(error);
    })
  }

  const saveLogo = async() => {
    console.log("got here")
    let fileName = "logos/" + props.projectID.toString() + "/" + logoFile.name.replaceAll(" ", "_").replaceAll(".png", "")
    let link = "https://maxinbodyshop.s3.us-east-2.amazonaws.com/" + fileName + ".png"

    const s3Config = {
      bucketName:"maxinbodyshop",
      region: "us-east-2",
      accessKeyId: process.env.AWS_KEY,
      secretAccessKey: process.env.AWS_SECRET,
      s3Url: 'https://maxinbodyshop.s3.amazonaws.com'
    }

    const ReactS3Client = new S3(s3Config);

    const imageUpload = await ReactS3Client.uploadFile(logoFile, fileName)

    var data = JSON.stringify({
      "projectID": props.projectID,
      "logoLink": link,
      "colorDict": {
        "color1": colorOne,
        "color2": colorTwo,
        "color3": colorThree
      },
      "action": "setLogoLink"
    });

    var config = {
      method: 'post',
      url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/editClients',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      console.log(response)
      setLogo(true)
    })
    .catch(function (error) {
      console.log(error);
    })
  }

  const getMetadata = async(link) => {
    setGettingMetadata(true)

    var config = {
      method: 'get',
      url: link,
      headers: {
        'Content-Type': 'application/json'
      },
    };

    let hashArray = await axios(config)
    console.log(hashArray)

    let hashList = hashArray.data
    // let hashList = hashArray.data.toString.replaceAll("[", "").replaceAll("]", "").replaceAll(/\s/g,'').split(",")
    console.log(hashList)

    const connection = new Connection(process.env.QUICKNODE);
    const metaplex = new Metaplex(connection);

    Promise.all(hashList.map( async(hash) => {
      if (!alreadyCreated?.includes(hash)){
        console.log(hash)
        const mintAddress = new PublicKey(hash)
        let nft = await metaplex.nfts().findByMint({ mintAddress });
        console.log("nft", nft)
        if (nft?.jsonLoaded){
          var data = JSON.stringify({
            "projectID": props.projectID,
            "hash": nft.address.toString(),
            "attributeList": nft.json.attributes,
            "metadataLink": nft.uri,
            "action": "addNFT"
          });

          var config = {
            method: 'post',
            url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/editClients',
            headers: {
              'x-api-key': process.env.GATEWAY_KEY,
              'Content-Type': 'application/json'
            },
            data: data
          };
          await axios(config).then(async (response) => {
            console.log(response)
          })
          alreadyCreated.push(hash)
        }
      }
    })).then(() => (
      checkMetadata(hashList)
    ))
    // console.log(hashArray.data)
  }

  const checkMetadata = (hashArray) => {
    var data = JSON.stringify({
      "projectID": props.projectID,
      "hashArray": hashArray,
      "action": "checkMetadata"
    });

    var config = {
      method: 'post',
      url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/editClients',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    // start here
    // need to return the attributeDict here (with all the fields)
    // need to edit editClient to return the attribute dict without the artwork array filter for when metadata details arent set and with artworkarray filter for when it is set

    axios(config)
    .then(function (response) {
      console.log(response.data)
      if (response.data.leftToGet.length){
        setAlreadyCreated(response.data.alreadyCreated)
        setGettingMetadata(false)
        setTryAgain(true)
      }
      else{
        setMetadataCategories(response.data.attributeArray)
        setNonArtworkArray(response.data.attributeArray)
        setAttributeDict(response.data.attributeDict)
        setMetadata(true)
        setGettingMetadata(false)
        setTryAgain(false)
      }
    })
    .catch(function (error) {
      console.log(error);
    })
  }

  const editAssetZip = async(e, collectionName) => {
    setCheckingZip(true)
    setErrorArray()
    setUploadCollectionName(collectionName)
    if (e.target.files) {
      const zipFile = e.target.files[0]

      let categoriesTemp = []
      let fileDictTemp = {}
      let fileNamesTemp = {}

      let zip = new JSZip()
      await zip.loadAsync(zipFile).then((files) => {
        Object.keys(files.files).map(key => {
          if (!key.includes("MACOS")){
            let file = files.files[key]
            if (!file.dir){
              let category = file.name.split("/")[0]
              let name = file.name.split("/")[1]
              if (!name.includes(".DS_Store")){
                if (Object.keys(fileDictTemp).includes(category)){
                  fileDictTemp[category].push(file)
                  fileNamesTemp[category].push(name.replaceAll(".png", "").replaceAll(".PNG", ""))
                }
                else{
                  fileDictTemp[category] = [file]
                  fileNamesTemp[category] = [name.replaceAll(".png","").replaceAll(".PNG", "")]
                }
              }
            }
          }
        })
        setZipDict(fileDictTemp)
        setZipNameDict(fileNamesTemp)
      })
    }
  }

  const uploadCollection = async() => {
    setUploadingCollection(true)
    const s3Config = {
      bucketName:"maxinbodyshop",
      region: "us-east-2",
      accessKeyId: process.env.AWS_KEY,
      secretAccessKey: process.env.AWS_SECRET,
      s3Url: 'https://maxinbodyshop.s3.amazonaws.com'
    }

    const ReactS3Client = new S3(s3Config);

    let categoryArray = Object.keys(zipDict)
    let x = 0

    let mapping = Object.keys(zipDict).map(async (category) => {
      setUploadCategory(categoryArray[x])
      let innerMapping = zipDict[category].map(async (file) => {
        let image = await file.async("blob")
        let fileName = "builder/" + props.projectID + "/" + uploadCollectionName + "/" + file.name.replaceAll(" ", "_")
        const link = "https://maxinbodyshop.s3.us-east-2.amazonaws.com/" + fileName
        let traitName = file.name.split("/")[1].replaceAll(".png", "").replaceAll(".PNG", "")
        let s3Response = await ReactS3Client.uploadFile(image, fileName)
        console.log(s3Response)

        var data = JSON.stringify({
          "projectID": props.projectID,
          "category": category,
          "traitName": traitName,
          "link": link,
          "collectionName": uploadCollectionName,
          "action": "addImage"
        });

        var config = {
          method: 'post',
          url: 'https://59200uzilj.execute-api.us-east-1.amazonaws.com/Production/uploadimages',
          headers: {
            'x-api-key': process.env.GATEWAY_KEY,
            'Content-Type': 'application/json'
          },
          data: data
        };

        return axios(config)
      })
      Promise.all(innerMapping).then(() => {
        x = x + 1
        setUploadCategory(categoryArray[x])
        categoryArray.pop()
        if (!categoryArray.length){
          completeMapping()
        }
      })
    })

  }

  const completeMapping = () => {
    var data = JSON.stringify({
      "projectID": props.projectID,
      "collectionName": uploadCollectionName,
      "artworkArray": artworkArray,
      "action": "completeMapping"
    });
    console.log(data)

    var config = {
      method: 'post',
      url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/editClients',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      console.log(response)
      if (uploadCollectionName === "Base"){
        setArt(true)
        setZipDict()
        setZipNameDict()
      }
      if (response.data.hasUpgrade){
        setUpgradesLeftToUpload(response.data.upgradesLeftToUpload)
        setZipDict()
        setZipNameDict()
      }
    })
    .catch(function (error) {
      console.log(error);
    })
  }

  const fetchMetadata = async (event) => {
    const hashList = JSON.parse(event.target.result)

    const connection = new Connection(process.env.QUICKNODE);
    const metaplex = new Metaplex(connection);

    Promise.all(hashList.map(hash => {
      const mintAddress = new PublicKey(hash)
      return metaplex.nfts().findByMint({ mintAddress });

    }))
      .then(
      results => {
        results.map( metadata => {
          if (metadata.jsonLoaded){
            console.log(metadata)
            var data = JSON.stringify({
              "projectID": props.projectID,
              "hash": metadata.address.toString(),
              "attributeList": metadata.json.attributes,
              "metadataLink": metadata.uri,
              "action": "addNFT"
            });
            console.log(data)

            var config = {
              method: 'post',
              url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/editClients',
              headers: {
                'x-api-key': process.env.GATEWAY_KEY,
                'Content-Type': 'application/json'
              },
              data: data
            };

            axios(config)
            .then(function (response) {
              console.log(response)
              return response
            })
            .catch(function (error) {
              console.log(error);
            })
          }
        })
      })
      .then(async() => {

        let fileName = "hashes/" + props.projectID.toString() + "/" + uploadedHash.name.replaceAll(" ", "_")

        const s3Config = {
          bucketName:"maxinbodyshop",
          region: "us-east-2",
          accessKeyId: process.env.AWS_KEY,
          secretAccessKey: process.env.AWS_SECRET,
          s3Url: 'https://maxinbodyshop.s3.amazonaws.com'
        }

        const ReactS3Client = new S3(s3Config);

        const imageUpload = await ReactS3Client.uploadFile(uploadedHash, fileName)

        var data = JSON.stringify({
          "projectID": props.projectID,
          "hashListURL": imageUpload.location,
          "collectionName": collectionName,
          "action": "finalizeOnboarding"
        });

        var config = {
          method: 'post',
          url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/editClients',
          headers: {
            'x-api-key': process.env.GATEWAY_KEY,
            'Content-Type': 'application/json'
          },
          data: data
        };

        axios(config)
        .then(function (response) {
          console.log(response)
          props.setOnboarding(true)
        })
        .catch(function (error) {
          console.log(error);
        })
    })
  }

  const editArtworkArray = (name) => {
    if (artworkArray.includes(name)){
      let index = artworkArray.indexOf(name)
      if (index > -1) {
        let artworkArrayTemp = [...artworkArray]
        artworkArrayTemp.splice(index, 1)
        setArtworkArray(artworkArrayTemp)
      }
      setNonArtworkArray([...nonArtworkArray, name])
    }
    else{
      setArtworkArray([...artworkArray, name])
      let index = nonArtworkArray.indexOf(name)
      if (index > -1) {
        let nonArtworkArrayTemp = [...nonArtworkArray]
        nonArtworkArrayTemp.splice(index, 1)
        setNonArtworkArray(nonArtworkArrayTemp)
      }
    }
  }

  const editUpgradeNameDict = (category, name) => {
    let tempUpgradeDict = {...upgradeNameDict}
    tempUpgradeDict[category] = name
    setUpgradeNameDict(tempUpgradeDict)
  }

  const editUpgradeCategoryDict = (category, name) => {
    console.log(upgradeCategoryDict)
    let currentArray
    if (Object.keys(upgradeCategoryDict).includes(name)){
      currentArray = upgradeCategoryDict[name]
    }
    else{
      currentArray = []
    }
    console.log(currentArray, category)
    if (currentArray.includes(category)){
      console.log("includes")
      let index = currentArray.indexOf(category)
      if (index > -1) {
        let categoryArrayTemp = [...currentArray]
        categoryArrayTemp.splice(index, 1)
        let tempDict = {...upgradeCategoryDict}
        tempDict[name] = categoryArrayTemp
        setUpgradeCategoryDict(tempDict)
      }
    }
    else {
      console.log("doesnt include", currentArray)

      currentArray.push(category)
      console.log(currentArray)
      let tempDict = {...upgradeCategoryDict}
      tempDict[name] = currentArray
      console.log(tempDict)
      setUpgradeCategoryDict(tempDict)
    }
  }

  const saveUpgradeDetails = () => {
    var data = JSON.stringify({
      "artworkArray": artworkArray,
      "hasUpgrade": hasUpgrade,
      "upgradeCategory": upgradeField,
      "upgradeNameDict": upgradeNameDict,
      "upgradeCategoryDict": upgradeCategoryDict,
      "projectID": props.projectID,
      "action": "setMetadataDetails"
    });
    console.log(data)

    var config = {
      method: 'post',
      url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/editClients',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      console.log(response)
      setAttributeDict(response.data.attributeDict)
      setMetadataDetails(true)
    })
    .catch(function (error) {
      console.log(error)
    })
  }

  const finishOnboarding = () => {
    var data = JSON.stringify({
      "projectID": props.projectID,
      "action": "finishOnboarding"
    });

    var config = {
      method: 'post',
      url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/editClients',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      console.log(response)
      props.setNewPage("builder")
    })
    .catch(function (error) {
      console.log(error);
    })
  }

  const renderStep = () => {
    if (!name){
      return(
        <div>
          <div className='mb-5'>
              <p className='font-gilroy-bold text-xl text-gray-deep mt-5'>Set your collection name below!</p>
              <p className='text-gray-400 text-sm'>This is the collection name that all your trait nfts will appear under in a wallet</p>
          </div>
          <input onChange={(e) => setCollectionName(e.target.value)} name="price" className='w-[30%] border border-gray-400 bg-gray-light focus:outline-none px-3 text-[16px] font-bold text-gray-deep' type='text'/>
          <br></br><br></br>
          <button style={{marginBottom: 400}} onClick={() => {saveCollectionName()}} className="bg-red-light font-gilroy-bold text-white px-10 text-lg font-semibold py-1 rounded-md">
            Submit!
          </button>
        </div>
      )
    }
    else if (!logo){
      return (
        <div>
          <div className='mb-5'>
              <p className='font-gilroy-bold text-xl text-gray-deep mt-5'>Upload your logo here!</p>
              <p className='text-gray-400 text-sm'>This will appear in your front end trait shop</p>
          </div>
          <div className='text-center ml-5'>
            <div>
              <input onChange={e => {setLogoFile(e.target.files[0])}} accept=".png" type="file" className="text-gray-deep font-bold" />
            </div>
          </div>
          <div className='mb-5'>
              <p className='font-gilroy-bold text-xl text-gray-deep mt-5'>Set your collections colors here (hex values only)</p>
              <p className='text-gray-400 text-sm'>This will appear as the main colors in your front end trait shop - leave this blank if you want to use the default colors</p>
          </div>
          <input onChange={(e) => setColorOne(e.target.value)} name="colorOne" className='w-[30%] border border-gray-400 bg-gray-light focus:outline-none px-3 text-[16px] font-bold text-gray-deep' type='text'/>
          <p className='text-gray-400 text-sm'>Color 1</p>
          <br></br>
          <input onChange={(e) => setColorTwo(e.target.value)} name="colorTwo" className='w-[30%] border border-gray-400 bg-gray-light focus:outline-none px-3 text-[16px] font-bold text-gray-deep' type='text'/>
          <p className='text-gray-400 text-sm'>Color 2</p>
          <br></br>
          <input onChange={(e) => setColorThree(e.target.value)} name="colorThree" className='w-[30%] border border-gray-400 bg-gray-light focus:outline-none px-3 text-[16px] font-bold text-gray-deep' type='text'/>
          <p className='text-gray-400 text-sm'>Color 3</p>
          <br></br><br></br>
          <button style={{marginBottom: 400}} onClick={() => {saveLogo()}} className="bg-red-light font-gilroy-bold text-white px-10 text-lg font-semibold py-1 rounded-md">
            Submit!
          </button>
        </div>
      )
    }
    else if (!hash){
      return (
        <div>
          <div className='mb-5'>
              <p className='font-gilroy-bold text-xl text-gray-deep mt-5'>Upload your hashlist here!</p>
              <p className='text-gray-400 text-sm'>We will use this to fetch all the metadata for your NFTs</p>
              <p className='text-gray-400 text-sm'>Please upload your full hashlist (even if you have multiple art upgrades)</p>
          </div>
          <div className='text-center ml-5'>
            <div>
              <input onChange={e => {setUploadedhash(e.target.files[0])}} accept=".json" type="file" className="text-gray-deep font-bold" />
            </div>
          </div>
          <br></br>
          <button style={{marginBottom: 400}} onClick={() => {saveHash()}} className="bg-red-light font-gilroy-bold text-white px-10 text-lg font-semibold py-1 rounded-md">
            Submit!
          </button>
        </div>
      )
    }
    else if (!metadata){
      if (gettingMetadata){
        return(
          <div>
            <img className='max-w-[25px] mx-auto' src={Loader} alt="loading..."/>
            <div className='mb-5'>
                <p className='font-gilroy-bold text-xl text-gray-deep mt-5'>Getting the metadata for your hashlist</p>
                <p className='text-gray-400 text-sm'>This could take a while for bigger collections</p>
            </div>
          </div>
        )
      }
      else{
        if (tryAgain){
          return(
            <div>
              <div className='mb-5'>
                  <p className='font-gilroy-bold text-xl text-gray-deep mt-5'>Not all NFTs loaded (this can be an issue with the RPC node)</p>
                  <p className='text-gray-400 text-sm'>Lets try again below</p>
              </div>
              <button style={{marginBottom: 400}} onClick={() => {getMetadata(hashLink)}} className="bg-red-light font-gilroy-bold text-white px-10 text-lg font-semibold py-1 rounded-md">
                Get Metadata!
              </button>
            </div>
          )
        }
        return (
          <div>
            <div className='mb-5'>
                <p className='font-gilroy-bold text-xl text-gray-deep mt-5'>Your hash has been uploaded</p>
                <p className='text-gray-400 text-sm'>Click below to retrieve your NFT metadata</p>
            </div>
            <button style={{marginBottom: 400}} onClick={() => {getMetadata(hashLink)}} className="bg-red-light font-gilroy-bold text-white px-10 text-lg font-semibold py-1 rounded-md">
              Get Metadata!
            </button>
          </div>
        )
      }
    }
    else if (!metadataDetails){
      return (
        <div>
          <div>
            <p className='font-gilroy-bold text-xl text-gray-deep mt-5'>Please select the categories that are part of your base artwork traits</p>
            <p className='text-gray-400 text-sm'>Do not select any categories that correspond to upgraded NFTs that do not appear in your base collection</p>
            {
              metadataCategories.map(category => (
                <div>
                  <p className='text-lg text-gray-400 font-medium'>{category}</p>
                  <input className='font-gilroy-bold text-xl text-gray-deep' onClick={() => {editArtworkArray(category)}} checked={artworkArray.includes(category)} type="radio" value="true" name={category} />
                </div>
              ))
            }
          </div>
          <div className='mb-5'>
              <p className='font-gilroy-bold text-xl text-gray-deep mt-5'>Does your collection have an art upgrade</p>
              <p className='text-gray-400 text-sm'>You will need to upload different artwork for each art upgrade you have done</p>
          </div>
          <div className='flex justify-between w-[20%] m-auto'>
            <div className='text-center'>
              <input className='font-gilroy-bold text-xl text-gray-deep' onChange={e => {setHasUpgrade(true)}} checked={hasUpgrade} type="radio" value="true" name="hasUpgradeYes" />
              <p className='text-lg text-gray-400 font-medium'>Yes</p>
            </div>
            <div className='text-center'>
              <input className='font-gilroy-bold text-xl text-gray-deep' onChange={e => {setHasUpgrade(false)}} checked={!hasUpgrade} type="radio" value="false" name="hasUpgradeNo" />
              <p className='text-lg text-gray-400 font-medium'>No</p>
            </div>
          </div>
          {
            hasUpgrade ?
                <div>
                  <p className='font-gilroy-bold text-xl text-gray-deep mt-5'>Please select the category that we can use to check art upgrade</p>
                  {
                    nonArtworkArray.map(category => (
                      <div>
                        <p className='text-lg text-gray-400 font-medium'>{category}</p>
                        <input className='font-gilroy-bold text-xl text-gray-deep' onClick={() => {setUpgradeField(category)}} checked={upgradeField === category} type="radio" value="true" name={category} />
                      </div>
                    ))
                  }
                  {
                    upgradeField ?
                      <div>
                        <p className='font-gilroy-bold text-xl text-gray-deep mt-5'>We've found {attributeDict[upgradeField].length} values for this category. Please name each upgrade collection and select the metadata categories that correspond for each collection</p>
                        {
                          attributeDict[upgradeField].map(attribute => (
                            <div>
                              <p className='text-lg text-gray-400 font-medium'>{attribute}</p>
                              <input onChange={(e) => editUpgradeNameDict(attribute, e.target.value)} className='w-[30%] border border-gray-400 bg-gray-light focus:outline-none px-3 text-[16px] font-bold text-gray-deep' type='text'/>
                              <br></br>
                              {
                                metadataCategories.map(category => (
                                  <div>
                                    <p className='text-lg text-gray-400 font-medium'>{category}</p>
                                    <input className='font-gilroy-bold text-xl text-gray-deep' onClick={() => {editUpgradeCategoryDict(category, attribute)}} checked={upgradeCategoryDict[attribute]?.includes(category)} type="radio" value="true" />
                                  </div>
                                ))
                              }
                            </div>
                          ))
                        }
                      </div>
                      :
                      <div>
                      </div>
                  }
                </div>
                :
                <div>
                </div>
          }
          <button style={{marginBottom: 400}} onClick={() => {saveUpgradeDetails()}} className="bg-red-light font-gilroy-bold text-white px-10 text-lg font-semibold py-1 rounded-md">
            Submit!
          </button>
        </div>
      )
    }
    else if (!art){
        return (
          <div>
            {
              uploadingCollection ?
                <div className='mb-5'>
                    <p className='font-gilroy-bold text-xl text-gray-deep mt-5'>We are uploading your collection assets to an AWS S3 bucket for easy access</p>
                    <p className='text-gray-400 text-sm'>This could take a bit - give us a second</p>
                    <br></br>
                    <img className='max-w-[25px] mx-auto' src={Loader} alt="loading..."/>
                </div>
                :
                <div>
                  <div className='mb-5'>
                      <p className='font-gilroy-bold text-xl text-gray-deep mt-5'>Its time to upload your base collection assets (in .zip format)</p>
                      <p className='text-gray-400 text-sm'>These are the individual traits that make up your original collection - this must match the metadata names from your NFTs and must be pngs</p>
                      <p className='text-gray-400 text-sm'>If you have any doubts, download the example file below</p>
                  </div>
                  <div className='text-center ml-5'>
                    {
                      checkingZip ?
                        <div>
                          <img className='max-w-[25px] mx-auto' src={Loader} alt="loading..."/>
                          <p className='font-gilroy-bold text-xl text-gray-deep mt-5'>We are checking your uploaded zip against your metadata to make sure everything is in order</p>
                        </div>
                        :
                        errorArray.length ?
                          <div>
                            <p className='font-gilroy-bold text-xl text-gray-deep mt-5'>We found these errors in your zip file - please correct them and try the upload again</p>
                            {
                              errorArray.map(error => {
                                return (
                                  <p className='text-gray-400 text-sm'>{error}</p>
                                )
                              })
                            }
                            <input onChange={e => {editAssetZip(e, "Base")}} accept=".zip" type="file" className="text-gray-deep font-bold" />
                          </div>
                          :
                          <div>
                            <input onChange={e => {editAssetZip(e, "Base")}} accept=".zip" type="file" className="text-gray-deep font-bold" />
                          </div>
                    }
                  </div>
                  <br></br><br></br>
                  <div>
                    <p className='font-gilroy-bold text-xl text-gray-deep mt-5'>You can download this sample file for reference</p>
                    <button className="bg-black text-white text-lg px-7 font-gilroy-bold py-1 mt-5 rounded-md">
                      <a href={"https://maxinbodyshop.s3.us-east-2.amazonaws.com/sampleCollection.zip"}>
                        Download
                      </a>
                    </button>
                  </div>
                </div>
            }
          </div>
        )
    }
    // art uploaded - find out why this was skipped
    else if (upgradesLeftToUpload.length){
      return (
        <div>
          {
            uploadingCollection ?
              <div className='mb-5'>
                  <p className='font-gilroy-bold text-xl text-gray-deep mt-5'>We are uploading your upgraded collection assets to an AWS S3 bucket for easy access</p>
                  <p className='text-gray-400 text-sm'>This could take a bit - give us a second</p>
                  <br></br>
                  <img className='max-w-[25px] mx-auto' src={Loader} alt="loading..."/>
              </div>
              :
              <div>
                <div className='mb-5'>
                    <p className='font-gilroy-bold text-xl text-gray-deep mt-5'>Its time to upload your {upgradesLeftToUpload[0]} upgrade assets (in .zip format)</p>
                    <p className='text-gray-400 text-sm'>These are the individual traits that make up the {upgradesLeftToUpload[0]} collection - this must match the metadata names from your NFTs and must be pngs</p>
                    <p className='text-gray-400 text-sm'>If you have any doubts, download the example file below</p>
                </div>
                <div className='text-center ml-5'>
                  {
                    checkingZip ?
                      <div>
                        <img className='max-w-[25px] mx-auto' src={Loader} alt="loading..."/>
                        <p className='font-gilroy-bold text-xl text-gray-deep mt-5'>We are checking your uploaded zip against your metadata to make sure everything is in order</p>
                      </div>
                      :
                      errorArray.length ?
                        <div>
                          <p className='font-gilroy-bold text-xl text-gray-deep mt-5'>We found these errors in your zip file - please correct them and try the upload again</p>
                          {
                            errorArray.map(error => {
                              return (
                                <p className='text-gray-400 text-sm'>{error}</p>
                              )
                            })
                          }
                          <input onChange={e => {editAssetZip(e, upgradesLeftToUpload[0])}} accept=".zip" type="file" className="text-gray-deep font-bold" />
                        </div>
                        :
                        <div>
                          <input onChange={e => {editAssetZip(e, upgradesLeftToUpload[0])}} accept=".zip" type="file" className="text-gray-deep font-bold" />
                        </div>
                  }
                </div>
                <br></br><br></br>
                <div>
                  <p className='font-gilroy-bold text-xl text-gray-deep mt-5'>You can download this sample file for reference</p>
                  <button className="bg-black text-white text-lg px-7 font-gilroy-bold py-1 mt-5 rounded-md">
                    <a href={"https://maxinbodyshop.s3.us-east-2.amazonaws.com/sampleCollection.zip"}>
                      Download
                    </a>
                  </button>
                </div>
              </div>
          }
        </div>
      )
    }
    else{
      return (
        <div>
          <div className='mb-5'>
              <p className='font-gilroy-bold text-xl text-gray-deep mt-5'>You have completed the onboarding!</p>
              <p className='text-gray-400 text-sm'>Click below to start customizing your builder</p>
          </div>
          <button style={{marginBottom: 400}} onClick={() => {finishOnboarding()}} className="bg-red-light font-gilroy-bold text-white px-10 text-lg font-semibold py-1 rounded-md">
            Finalize Onboarding!
          </button>
        </div>
      )
    }
  }

  const renderPopup = () => {
    if (popupState === "checkingAssets"){
      return(
        <div style={{position: "absolute",  height: "100%", width: "100%"}} >
          <div className='fixed bg-red-deep p-10 left-1/2 top-1/2 w-fit h-fit -translate-x-1/2 -translate-y-1/2 text-center rounded-full duration-100 z-10'>
              <div className='w-24 mx-auto mb-5'>
                  <img className='max-w-[25px] mx-auto' src={Loader} alt="loading..."/>
              </div>
              <p className='max-w-[300px] mx-auto font-text text-white'>We are checking your zip file against the metadata for your NFTs</p>
          </div>
        </div>
      )
    }
    else if (popupState === "uploadingAssets"){
      if (uploadCategory){
        return (
          <div style={{position: "absolute" , height: "100%", width: "100%"}} >
            <div className='fixed bg-red-deep p-10 left-1/2 top-1/2 w-fit h-fit -translate-x-1/2 -translate-y-1/2 text-center rounded-full duration-100 z-10'>
              <div className='w-24 mx-auto mb-5'>
                  <img className='max-w-[25px] mx-auto' src={Loader} alt="loading..."/>
              </div>
              <p className='max-w-[300px] mx-auto font-text text-white'> We are uploading your collection assets. {uploadCategory} images are being uploaded</p>
            </div>
          </div>
        )
      }
      else{
        return (
          <div style={{position: "absolute" , height: "100%", width: "100%"}} >
            <div className='fixed bg-red-deep p-10 left-1/2 top-1/2 w-fit h-fit -translate-x-1/2 -translate-y-1/2 text-center rounded-full duration-100 z-10'>
              <div className='w-24 mx-auto mb-5'>
                  <img className='max-w-[25px] mx-auto' src={Loader} alt="loading..."/>
              </div>
              <p className='max-w-[300px] mx-auto font-text text-white'> We are starting to upload your collection assets.</p>
            </div>
          </div>
        )
      }
    }
    else if (popupState === "mapping"){
      return(
        <div style={{position: "absolute" , height: "100%", width: "100%"}} >
          <div className='fixed bg-red-deep p-10 left-1/2 top-1/2 w-fit h-fit -translate-x-1/2 -translate-y-1/2 text-center rounded-full duration-100 z-10'>
            <div className='w-24 mx-auto mb-5'>
                <img className='max-w-[25px] mx-auto' src={Loader} alt="loading..."/>
            </div>
            <p className='max-w-[300px] mx-auto font-text text-white'> Your assets have been uploaded. We are mapping your metadata to the trait names your provided - this shouldnt take long.</p>
          </div>
        </div>
      )
    }
  }

  const resetPopup = () => {
    setPopup(false)
    setPopupState()
  }


  return (
    <div>
      {
        popup  ?
          renderPopup()
          :
        <>
        </>
      }
      {renderStep()}
    </div>
  )

};

export default Onboarding;
