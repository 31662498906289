import React, {useState, useEffect} from "react";
import CustomBtn from '../Components/CustomBtn'
import Header from '../Components/Header'
import TraitHeader from '../Components/TraitHeader'
import TraitThumbnail from '../Components/TraitThumbnail';
import thumbnailImg from '../assets/img/thumbnail.png';
import PendingNFTs from './PendingNFTs'
import CreatedTraits from './CreatedTraits'
import axios from "axios";
import IndividualLiveTrait from "./IndividualLiveTrait"

const TraitShopHome = (props) => {
  const [page, setPage] = useState("viewTraits")
  const [pendingNFTs, setPendingNFTs] = useState([])
  const [createdNFTs, setCreatedNFTs] = useState([])
  const [filterName, setFilterName] = useState("")
  const [filterID, setFilterID] = useState("")
  const [selectedHash, setSelectedHash] = useState(null)
  const [selectedTrait, setSelectedTrait] = useState(null)

  useEffect(() => {
    console.log(props)
    var data = JSON.stringify({
      "projectID": props.projectID
    });

    var config = {
      method: 'post',
      url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/getallpendingnfts',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      console.log(response.data.data, 'response')
      if (response.data.data.length !== 0){
        var pendingNFTsTemp = []
        if (props.page === 'traitShop'){
          var newArray = response.data.data.filter((nft) =>
              nft.isSlotTrait !== 1
          )
        }
        else if (props.page === 'slotMachine'){
          var newArray = response.data.data.filter((nft) =>
              nft.isSlotTrait === 1
          )
        }
        newArray.forEach(nft => {
          pendingNFTsTemp.push({
            id: nft.id,
            identifyingHash: nft.identifyingHash,
            name: nft.name,
            imageLink: nft.imageLink,
            videoLink: nft.videoLink,
            status: nft.status
          })
        })
      }
      setPendingNFTs(pendingNFTsTemp)
    })
    .catch(function (error) {
      console.log(error);
    })
  }, []);

  const renderPendingTraits = () => {
    const traitsList = []
    const limitedList = []
    var num = 0
    if (pendingNFTs.length !== 0){
      pendingNFTs.forEach(trait => {
        const traitInfo = (
          <div className=''>
              <TraitThumbnail src={trait.imageLink} alt="Trait Thumbnail" to={"/"}/>
              <button className='inline-block bg-[#B9B5B9] text-white uppercase text-sm font-bold px-2 py-1' onClick={() => {props.setPendingTrait(trait.identifyingHash)}}>
                  View Details
              </button>
          </div>
        )
        if (num < 4){
          limitedList.push(traitInfo)
          num += 1
        }
        if (filterID === "" && filterName === ""){
          traitsList.push(traitInfo)
        }
        else if (filterID !== "" && parseInt(filterID) === trait.id){
          traitsList.push(traitInfo)
        }
        else if (filterName !== "" && trait.name.includes(filterName)){
          traitsList.push(traitInfo)
        }
      })
    }
    return limitedList
  }

  useEffect(() => {
    var data = JSON.stringify({
      "traitType": "alltraits",
      "projectID": props.projectID
    });

    var config = {
      method: 'post',
      url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/gettraits',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      console.log(response)
      if (response.data.data.length !== 0){
        var createdNFTs = []
        if (props.page === 'traitShop'){
          var newArray = response.data.data.filter((nft) =>
              nft.isSlotTrait !== 1
          )
        }
        else if (props.page === 'slotMachine'){
          var newArray = response.data.data.filter((nft) =>
              nft.isSlotTrait === 1
          )
        }
        newArray.forEach(trait => {
          createdNFTs.push({
              id: trait.id,
              name: trait.traitName,
              type: trait.type,
              creator: trait.creator,
              supply: trait.supply,
              price: trait.price,
              currency: trait.currencyHash,
              imageLink: trait.image,
              description: trait.description,
              live: trait.live,
              soldOut: trait.soldOut,
              amountPurchased: trait.amountPurchased,
              identifyingHash: trait.identifyingHash
            })
        })
      }
      setCreatedNFTs(createdNFTs)
    })
    .catch(function (error) {
      console.log(error);
    })
  }, []);

  const renderCreatedTraits = () => {
    const traitsList = []
    const limitedList = []
    var num = 0
    if (createdNFTs.length !== 0){
      createdNFTs.forEach(trait => {
        const traitInfo = (
          <div className=''>
              <TraitThumbnail src={trait.imageLink} alt="Trait Thumbnail" to={"/"}/>
              <button className='inline-block bg-[#B9B5B9] text-white uppercase text-sm font-bold px-2 py-1' onClick={() => {props.setCreatedTrait(trait.id)}}>
                  View Details
              </button>
          </div>
        )
        if (num < 4){
          limitedList.push(traitInfo)
          num += 1
        }
        if (filterID === "" && filterName === ""){
          traitsList.push(traitInfo)
        }
        else if (filterID !== "" && parseInt(filterID) === trait.id){
          traitsList.push(traitInfo)
        }
        else if (filterName !== "" && trait.name.includes(filterName)){
          traitsList.push(traitInfo)
        }
      })
    }
    return limitedList
  }

  const renderPage = () => {
    if (page === "viewTraits"){
      return (
        <>
        { createdNFTs || pendingNFTs ?
          <div className='w-[80%] ml-auto px-5'>
              <div className='mb-5'>
                  <button className="bg-gray-deep text-yellow-light font-semibold flex items-start text-xl rounded-sm px-5 py-1" onClick={() => {props.setInnerPage("addTraits")}}>Add New Trait</button>
              </div>
              <div className='max-w-3xl mr-auto mb-5 relative'>
                  <div className='flex items-center justify-between mb-2'>
                      <h2 className='text-gray-deep text-2xl font-bold'>Pending Traits</h2>
                      <button className='text-gray-deep text-2xl font-normal italic' onClick={() => {props.setInnerPage("pendingTraits")}}>See All</button>
                  </div>
                  <div className='grid grid-cols-4 gap-5'>
                      {pendingNFTs ?
                        renderPendingTraits()
                        :
                        <div>
                        </div>
                      }
                  </div>
                  <div className='absolute top-1/2 -right-24 -translate-y-1/2 flex gap-3'>
                      <span className='bg-red-light inline-block w-3 h-3 rounded-full'></span>
                      <span className='bg-red-light inline-block w-3 h-3 rounded-full'></span>
                      <span className='bg-red-light inline-block w-3 h-3 rounded-full'></span>
                  </div>
              </div>
              <div className='max-w-3xl mr-auto relative'>
                  <div className='flex items-center justify-between mb-2'>
                      <h2 className='text-gray-deep text-2xl font-bold'>Created Traits</h2>
                      <button className='text-gray-deep text-2xl font-normal italic' onClick={() => {props.setInnerPage("createdTraits")}}>See All</button>
                  </div>
                  <div className='grid grid-cols-4 gap-5'>
                      {createdNFTs ?
                        renderCreatedTraits()
                        :
                        <div>
                        </div>
                      }
                  </div>
                  <div className='absolute top-1/2 -right-24 -translate-y-1/2 flex gap-3'>
                      <span className='bg-red-light inline-block w-3 h-3 rounded-full'></span>
                      <span className='bg-red-light inline-block w-3 h-3 rounded-full'></span>
                      <span className='bg-red-light inline-block w-3 h-3 rounded-full'></span>
                  </div>
              </div>
          </div>
          :
          <div className='w-[80%] ml-auto px-5'>
              <div className='mb-5'>
                  <button className="bg-gray-deep text-yellow-light font-semibold flex items-start text-xl rounded-sm px-5 py-1" onClick={() => {props.setInnerPage("addTraits")}}>Add New Trait</button>
              </div>
              <div className='max-w-3xl mr-auto mb-5 relative'>
                <div className='flex items-left justify-between mb-2'>
                    <p className='text-gray-deep text-2xl font-bold text-left'>No traits have been created. Click on the Add New Traits button to get started.</p>
                </div>
              </div>
          </div>
        }
        </>
    )
    }
    else if (page === "pendingTraits"){
      return(
        <PendingNFTs setPage={page} projectID={props.projectID} identifyingHash={selectedHash} setSelectedHash={setSelectedHash} pendingTraits = {pendingNFTs}/>
      )
    }
    else if (page === "createdTraits"){
      return(
        <CreatedTraits setPage={page} projectID={props.projectID} identifyingHash={selectedHash} setSelectedHash={setSelectedHash} createdTraits = {createdNFTs}/>
      )
    }
  }

  return (
    <div>
      {renderPage()}
    </div>
  )
}

export default TraitShopHome
