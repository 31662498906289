import React, {useState, useEffect} from "react";
import axios from "axios";
import IndividualLiveTrait from "./IndividualLiveTrait"
import TraitShopHome from "./TraitShopHome"
import TraitHeader from '../Components/TraitHeader';
import PendingTraitImg from '../assets/img/thumbnail.png';
import CreatedTraitsScroll from '../Components/CreatedTraitsScroll';
import CreatedTraitsArchivedScroll from '../Components/CreatedTraitsArchivedScroll';

const CreatedTraits = (props) => {
  console.log(props)
  const [page, setPage] = useState(props.setPage)
  const [createdTraits, setCreatedTraits] = useState([])
  const [liveTraits, setLiveTraits] = useState([])
  const [nonLiveTraits, setNonLiveTraits] = useState([])
  const [filterName, setFilterName] = useState("")
  const [filterType, setFilterType] = useState("")
  const [filterID, setFilterID] = useState("")
  const [selectedTrait, setSelectedTrait] = useState(null)
  const [options, setOptions] = useState([])
  const [catField, setCatField] = useState('');
  const [statusField, setStatusField] = useState('');
  const [statusArrow, setstatusArrow] = useState(false);
  const [catArrow,  setCatArrow] = useState(false);
  const [category,setCategory] = useState(null)
  const [status,setStatus] = useState('')

  const onClickCategory = event => {
    setCatField(current => !current);
    setCatArrow(current => !current);
  };
  const onChangeCat = (e)=>{
    setCategory(e.target.innerText)
   }

  const onClickstatus = event => {
     setStatusField(current => !current);
     setstatusArrow(current => !current);
   };
  const onChangestatus = (e)=>{
    // console.log(e.target.innerText)
    setStatus(e.target.innerText)
   };

  useEffect(() => {
    var data = JSON.stringify({
      "traitType": "alltraits",
      "projectID": props.projectID
    });

    var config = {
      method: 'post',
      url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/gettraits',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      console.log(response)
      if (response.data.typeDict.length !==0){
        setOptions(response.data.typeDict)
      }
      if (response.data.data.length !== 0){
        var createdTraits = []
        if (props.page === 'traitShop'){
          var newArray = response.data.data.filter((nft) =>
              nft.isSlotTrait !== 1
          )
        }
        else if (props.page === 'slotMachine'){
          var newArray = response.data.data.filter((nft) =>
              nft.isSlotTrait === 1
          )
        }
        newArray.forEach(trait => {
          createdTraits.push({
              id: trait.id,
              name: trait.traitName,
              type: trait.type,
              creator: trait.creator,
              supply: trait.supply,
              price: trait.price,
              currency: trait.currencyHash,
              imageLink: trait.image,
              description: trait.description,
              live: trait.live,
              soldOut: trait.soldOut,
              amountPurchased: trait.amountPurchased,
              identifyingHash: trait.identifyingHash
            })
        })
        setCreatedTraits(createdTraits)
      }
    })
    .catch(function (error) {
      console.log(error);
    })
  }, []);

  const renderCreatedTraitScroll = () => {
    const traitsList = []
    if (createdTraits.length !== 0){
      createdTraits.forEach(trait => {
        // console.log(trait.live)
        const traitInfo = (
          <div className='grid gap-2 text-center'>
              <CreatedTraitsScroll
                src={trait.imageLink}
                alt="Pending Trait Scroll"
                traitNameTitle={"Trait Name:"}
                traitName={trait.name}
                traitSupply={"TraitSupply:"}
                traitSupplyStats={trait.supply}
                traitAmount={"Amount Purchased:"}
                traitAmountStats={trait.amountPurchased}
                traitsRemaining={"Amount Left:"}
                traitsRemainingStats = {trait.supply - trait.amountPurchased}
                />
              {
                props.page === 'traitShop' ?
                <div className='border border-gray-400 p-3'>
                    <button className='bg-gray-deep text-[16px] font-bold px-4 py-1 text-yellow-light rounded-sm' onClick={() => {props.setCreatedTrait(trait.id)}}>{"View Details"}</button>
                </div>
                :
                ""
              }
              {
                trait.live ?
                <>
                  <div className='border border-gray-400 p-3'>
                      <button className='bg-gray-deep text-[16px] font-bold px-4 py-1 text-yellow-light rounded-sm' onClick={() => {takeLive(trait.id, 0)}}>{"Archive Traits"}</button>
                  </div>
                  <p className='text-sm text-gray-400'>{"These are the live traits"}</p>
                </>
                :
                <>
                  <div className='border border-gray-400 p-3'>
                      <button className='bg-red-deep text-[16px] font-bold px-4 py-1 text-white rounded-sm' onClick={() => {takeLive(trait.id, 1)}}>{"Push Live"}</button>
                  </div>
                  <p className='text-sm text-gray-400'>{"These are the non live traits"}</p>
                </>
              }
          </div>
        )
        if (!category || category == '-----'){
          if (status === '' || status == '-----'){
            if (filterID === "" && filterName === ""){
              traitsList.push(traitInfo)
            }
            else if (filterID !== "" && parseInt(filterID) === trait.id){
              traitsList.push(traitInfo)
            }
            else if (filterName !== "" && trait.name.toLowerCase().includes(filterName.toLowerCase())){
            // console.log(trait.name, filterName, 'includes')
            traitsList.push(traitInfo)
          }
          }
          else if (status === 'ARCHIVE' & trait.live === 1){
            if (filterID === "" && filterName === ""){
              traitsList.push(traitInfo)
            }
            else if (filterID !== "" && parseInt(filterID) === trait.id){
              traitsList.push(traitInfo)
            }
            else if (filterName !== "" && trait.name.toLowerCase().includes(filterName.toLowerCase())){
            // console.log(trait.name, filterName, 'includes')
            traitsList.push(traitInfo)
          }
          }
          else if (status === 'PUSH LIVE' & trait.live === 0){
            if (filterID === "" && filterName === ""){
              traitsList.push(traitInfo)
            }
            else if (filterID !== "" && parseInt(filterID) === trait.id){
              traitsList.push(traitInfo)
            }
            else if (filterName !== "" && trait.name.toLowerCase().includes(filterName.toLowerCase())){
            // console.log(trait.name, filterName, 'includes')
            traitsList.push(traitInfo)
          }
          }
        }
        else if (category.toLowerCase() == trait.type.toLowerCase()){
          if (status === '' || status == '-----'){
            if (filterID === "" && filterName === ""){
              traitsList.push(traitInfo)
            }
            else if (filterID !== "" && parseInt(filterID) === trait.id){
              traitsList.push(traitInfo)
            }
            else if (filterName !== "" && trait.name.toLowerCase().includes(filterName.toLowerCase())){
          // console.log(trait.name, filterName, 'includes')
          traitsList.push(traitInfo)
        }
          }
          else if (status === 'ARCHIVE' & trait.live === 1){
            if (filterID === "" && filterName === ""){
              traitsList.push(traitInfo)
            }
            else if (filterID !== "" && parseInt(filterID) === trait.id){
              traitsList.push(traitInfo)
            }
            else if (filterName !== "" && trait.name.toLowerCase().includes(filterName.toLowerCase())){
            // console.log(trait.name, filterName, 'includes')
            traitsList.push(traitInfo)
          }
          }
          else if (status === 'PUSH LIVE' & trait.live === 0){
            if (filterID === "" && filterName === ""){
              traitsList.push(traitInfo)
            }
            else if (filterID !== "" && parseInt(filterID) === trait.id){
              traitsList.push(traitInfo)
            }
            else if (filterName !== "" && trait.name.toLowerCase().includes(filterName.toLowerCase())){
            // console.log(trait.name, filterName, 'includes')
            traitsList.push(traitInfo)
          }
          }
        }
      })
    }
    return traitsList
  }

  const takeLive  = async (traitID, pushLive) => {
    var data = JSON.stringify({
      "action": 'pushTraitsLive',
      "pushLive": pushLive,
      "TraitID": traitID,
      "projectID": props.projectID
    });

    var config = {
      method: 'post',
      url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/togglelive',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      // console.log(response)
      if (response.data.typeDict.length !==0){
        // console.log(response.data.typeDict, )
        setOptions(response.data.typeDict)
      }
      if (response.data.data.length !== 0){
        var createdTraits = []
        if (props.page === 'traitShop'){
          var newArray = response.data.data.filter((nft) =>
              nft.isSlotTrait !== 1
          )
        }
        else if (props.page === 'slotMachine'){
          var newArray = response.data.data.filter((nft) =>
              nft.isSlotTrait === 1
          )
        }
        newArray.forEach(trait => {
          createdTraits.push({
            id: trait.id,
            name: trait.traitName,
            type: trait.type,
            creator: trait.creator,
            category: trait.category,
            supply: trait.supply,
            price: trait.price,
            currency: trait.currency,
            imageLink: trait.image,
            description: trait.description,
            live: trait.live,
            tokenAddress: trait.tokenAddress,
            soldOut: trait.soldOut,
            amountPurchased: trait.amountPurchased
          })
        })
        setCreatedTraits(createdTraits)
      }
    })
    .catch(function (error) {
      console.log(error);
    })
  }

  return (
    <div>
          <div className='w-[80%] ml-auto px-5'>
              <div className='max-w-3xl ml-24 mr-auto relative'>
                {
                  createdTraits.length ?
                  <div>
                    <form>
                        <div className='mb-5 max-w-2xl grid grid-cols-3 gap-5'>
                            <div>
                                <label className='text-lg text-gray-deep font-gilroy-bold'>filter by name</label>
                                <input className='bg-gray-light border-2 border-gray-400 px-1 py-[2px] w-full text-[16px] font-bold text-gray-deep focus:outline-none uppercase'
                                    type="text"
                                    onChange={(e) => setFilterName(e.target.value)}
                                />
                            </div>
                            <div className='flex flex-col relative'>
                                <label className='text-lg text-gray-deep font-gilroy-bold'>
                                  filter by category
                                </label>
                                <div onClick={onClickCategory} className="bg-gray-400 relative font-bold text-[16px] px-2
                                    uppercase cursor-pointer text-white w-full h-8 leading-8">
                                    {category}
                                    <span className={ ` text-white absolute top- right-2 ${catArrow && 'rotate-180'}` }>⮟</span>
                                </div>
                                {catField && (
                                    <ul className="absolute w-full top-[103%] flex flex-col gap-[2px]" onClick={onClickCategory}>
                                        <li onClick={(e)=>onChangeCat(e)} className="bg-gray-400 text-[16px] font-bold uppercase hover:bg-gray-deep cursor-pointer text-white hover:text-yellow-light px-2 py-[2px]">-----</li>
                                        {
                                          options.map((option) => (
                                            <li onClick={(e)=>onChangeCat(e)} className="bg-gray-400 text-[16px] font-bold uppercase hover:bg-gray-deep cursor-pointer text-white hover:text-yellow-light px-2 py-[2px]">{option.label}</li>
                                          ))
                                        }
                                    </ul>
                                )}
                            </div>
                            <div className='flex flex-col relative'>
                                <label className='text-lg text-gray-deep font-gilroy-bold'>filter by Status</label>
                                <div onClick={onClickstatus} className="bg-gray-400 relative font-bold text-[16px] px-2 uppercase cursor-pointer text-white w-full h-8 leading-8">
                                    {status}
                                    <span className={ ` text-white absolute top-0 right-2 ${statusArrow && 'rotate-180'}` }>⮟</span>
                                </div>
                                {statusField && (
                                    <ul className="absolute w-full top-[103%] flex flex-col gap-[2px]" onClick={onClickstatus}>
                                        <li onClick={(e)=>onChangestatus(e)} className="bg-gray-400 text-[16px] font-bold uppercase hover:bg-gray-deep cursor-pointer text-white hover:text-yellow-light px-2 py-[2px]">-----</li>
                                        <li onClick={(e)=>onChangestatus(e)} className="bg-gray-400 text-[16px] font-bold uppercase hover:bg-gray-deep cursor-pointer text-white hover:text-yellow-light px-2 py-[2px]">ARCHIVE</li>
                                        <li onClick={(e)=>onChangestatus(e)} className="bg-gray-400 text-[16px] font-bold uppercase hover:bg-gray-deep cursor-pointer text-white hover:text-yellow-light px-2 py-[2px]">PUSH LIVE</li>
                                    </ul>
                                )}
                            </div>
                        </div>

                    </form>
                    <div className='grid grid-cols-3 gap-10'>
                      {renderCreatedTraitScroll()}
                    </div>
                  </div>
                  :
                  <div>
                    You have no created Trait NFTs
                  </div>
                }
              </div>
          </div>
    </div>
  );
};

export default CreatedTraits;
