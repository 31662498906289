import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { WalletNotConnectedError } from '@solana/wallet-adapter-base';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';

import EditBuilderConfig from './EditBuilderConfig';
import BuilderHeader from '../Components/BuilderHeader';
import BackLink from '../Components/BackLink';
import builderimg from '../assets/img/builderimg.png'
import BuilderArray from '../Components/BuilderArray';
import EditBuilderImages from './EditBuilderImages'

import lock from "./lock.png"

import axios from "axios";


const BuilderHome = (props) => {

  const [loaded, setLoaded] = useState(false)
  const [page, setPage] = useState('BuilderHome')

  const [selectedCategory, setSelectedCategory] = useState()
  const [selectedTrait, setSelectedTrait] = useState()

  const [categoryOrderArray, setCategoryOrderArray] = useState()
  const [assetDict, setAssetDict] = useState()

  const [metadata, setMetadata] = useState()
  const [idMetadata, setIDMetadata] = useState()

  const [imageArray, setImageArray] = useState([])
  const [categoryMappings, setCategoryMappings] = useState({})
  const [secondaryCategories, setSecondaryCategories] = useState([])

  const [blockedTraits, setBlockedTraits] = useState()

  const [edit, setEdit] = useState(false)
  const [editType, setEditType] = useState()
  const [imageUpload, setImageUpload] = useState()

  const [builderState, setBuilderState] = useState("Base")
  const [builderStates, setBuilderStates] = useState([])
  const [hasUpgrade, setHasUpgrade] = useState(false)

  useEffect(() => {
    // console.log(selectedCategory, selectedTrait, builderStates)

  }, [selectedCategory, selectedTrait, builderStates]);

  useEffect(() => {
    var data = JSON.stringify({
      "projectID": props.projectID,
      "action": "setBuilder"
    });

    var config = {
      method: 'post',
      url: 'https://59200uzilj.execute-api.us-east-1.amazonaws.com/Production/renderimage',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      // console.log(response.data)
      // console.log(Object.keys(response.data.assetDict))
      setSelectedCategory(response.data.categoryOrderArray[0])
      setSelectedTrait(response.data.assetDict[builderState][response.data.categoryOrderArray[0]][0])

      setCategoryOrderArray(response.data.categoryOrderArray)

      setHasUpgrade(response.data.hasUpgrade)

      if (response.data.hasUpgrade){
        setBuilderStates(Object.keys(response.data.assetDict))
      }
      else{
        setBuilderStates(["Base"])
      }

      setImageArray(response.data.imageArray)

      setCategoryMappings(response.data.categoryMappings)
      setSecondaryCategories(response.data.secondaryCategories)
      setMetadata(response.data.sampleMetadata)
      setIDMetadata(response.data.sampleIDMetdata)
      setBlockedTraits(response.data.blockedTraits)
      setAssetDict(response.data.assetDict[builderState])
      setLoaded(true)
    })
    .catch(function (error) {
      console.log(error);
    })
  }, []);

  const renderCategories = () => {
    const categoryArray = []
    categoryOrderArray.map(category => {
      if (secondaryCategories.includes(category)){
        categoryArray.push(
          <div>
            <li className='flex items-center gap-5 cursor-pointer'> <button className='w-36  p-1 bg-gray-deep text-white font-lg font-bold' onClick={() => setSelectedCategory(category)}>{category}</button> <span className='text-red-light text-lg'>➤</span></li>
          </div>
        )
      }
      else{
        categoryArray.push(
          <div>
            <li className='flex items-center gap-5 cursor-pointer'> <button className='w-36  p-1 bg-gray-300 text-white font-lg font-bold' onClick={() => setSelectedCategory(category)}>{category}</button> <span className='text-gray-300 text-lg'>➤</span></li>
          </div>
        )
      }
    })
    return categoryArray
  }

  const renderTraits = () => {
    const traitArray = []
    const blockedTraitArray = []

    blockedTraits.map(blockedTrait => {
      if (metadata[blockedTrait.traitCategory] === blockedTrait.traitName){
        // console.log(blockedTrait)
        blockedTrait.blockedTraits.map(tempBlockedTraits => {
          blockedTraitArray.push(tempBlockedTraits.id)
        })
      }
    })

    assetDict[selectedCategory].map(trait => {
      if (secondaryCategories.includes(selectedCategory)){
        traitArray.push(
          <div>
            <li className='text-lg font-bold text-gray-400 cursor-pointer hover:text-red-light'>{trait.traitName}</li>
          </div>
        )
      }
      else{
        if (blockedTraitArray.includes(trait.id)){
          traitArray.push(
            <div>
              <li className='text-lg font-bold text-red-deep hover:text-red-light opacity-50'>{trait.traitName}</li>
            </div>
          )
        }
        else{
          traitArray.push(
            <div>
              <li onClick={() => renderImage(trait)} className='text-lg font-bold text-gray-400 cursor-pointer hover:text-red-light'>{trait.traitName}</li>
            </div>
          )
        }
      }
    })
    return traitArray
  }

  const renderImage = async(trait) => {
    let metadataDict = await generateMetadata(trait)
    // console.log(metadataDict)

    let newMetadata = metadataDict.newMetadata
    let newIDMetadata = metadataDict.newIDMetadata
    // console.log(newMetadata, newIDMetadata, builderState)

    var data = JSON.stringify({
      "projectID": props.projectID,
      "metadata": newMetadata,
      "idMetadata": newIDMetadata,
      "builderState": builderState,
      "action": "renderImage"
    });

    var config = {
      method: 'post',
      url: 'https://59200uzilj.execute-api.us-east-1.amazonaws.com/Production/renderimage',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      // console.log(response)
      setImageArray(response.data.imageArray)
      setMetadata(newMetadata)
      setIDMetadata(newIDMetadata)
    })
    .catch(function (error) {
      console.log(error);
    })
  }

  const resetEdit = (image) => {
    if (image){
      setEdit(false)
      setImageUpload(true)
      setEditType()
    }else{
      setEdit(false)
      setImageUpload(false)
      setEditType()
    }
    var data = JSON.stringify({
      "projectID": props.projectID,
      "action": "setBuilder"
    });

    var config = {
      method: 'post',
      url: 'https://59200uzilj.execute-api.us-east-1.amazonaws.com/Production/renderimage',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      // console.log(response)
      setImageArray(response.data.imageArray)
      setLoaded(true)
      setCategoryMappings(response.data.categoryMappings)
      setSecondaryCategories(response.data.secondaryCategories)
      setMetadata(response.data.sampleMetadata)
      setAssetDict(response.data.assetDict)
    })
    .catch(function (error) {
      console.log(error);
    })
  }

  const generateMetadata = (trait) => {
    // console.log(trait)
    let newMetadata = {...metadata}
    let newIDMetadata = {...idMetadata}
    // this is for mappings - need to redo this because I dont remember how it works
    // if (Object.keys(categoryMappings).includes(selectedCategory)){
    //   let matchedSecondary = categoryMappings[selectedCategory]
    //   newMetadata[selectedCategory] = trait.traitName
    //   newMetadata[matchedSecondary] = trait.traitName
    // }
    // else{
    //   newMetadata[selectedCategory] = trait.traitName
    //   idMetadata[selectedCategory] = trait.id
    // }
    newMetadata[selectedCategory] = trait.traitName
    newIDMetadata[selectedCategory] = trait.id
    return {'newMetadata': newMetadata, 'newIDMetadata': newIDMetadata}
  }

  const renderRandom = () => {
    var data = JSON.stringify({
      "projectID": props.projectID,
      "builderState": builderState,
      "action": "randomize"
    });

    var config = {
      method: 'post',
      url: 'https://59200uzilj.execute-api.us-east-1.amazonaws.com/Production/renderimage',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      // console.log(response)
      setImageArray(response.data.imageArray)
      setMetadata(response.data.metadata)
      setIDMetadata(response.data.idMetadata)
    })
    .catch(function (error) {
      console.log(error);
    })
  }

  const loadNewBuilderState = (newState) => {
    var data = JSON.stringify({
      "projectID": props.projectID,
      "builderState": newState,
      "action": "switchBuilderState"
    });

    var config = {
      method: 'post',
      url: 'https://59200uzilj.execute-api.us-east-1.amazonaws.com/Production/renderimage',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      // console.log(response)
      setBuilderState(newState)
      setSelectedCategory(response.data.categoryOrderArray[0])
      setSelectedTrait(response.data.assetDict[newState][response.data.categoryOrderArray[0]][0])

      setCategoryOrderArray(response.data.categoryOrderArray)

      setImageArray(response.data.imageArray)

      setCategoryMappings(response.data.categoryMappings)
      setSecondaryCategories(response.data.secondaryCategories)
      setMetadata(response.data.sampleMetadata)
      setIDMetadata(response.data.sampleIDMetdata)
      setBlockedTraits(response.data.blockedTraits)
      setAssetDict(response.data.assetDict[newState])
      setLoaded(true)
    })
    .catch(function (error) {
      console.log(error);
    })
  }

  return (
    <div>
        <div className='w-[70%] mr-40 mx-auto px-5 pt-16'>
            <div className='max-w-5xl mx-auto flex gap-8 items-start'>
              <div className='w-full flex-col items-end'>
                <button className="text-white bg-red-light font-gilroy-bold text-lg py-1 px-7 align-bottom rounded-md mb-5" onClick={() => {renderRandom()}}>
                  Randomize!
                </button>
                {
                  loaded ?
                  <div className='grid grid-cols-2 gap-2 w-full'>
                    <div className='bg-gray-200 p-5'>
                      <ul className='flex flex-col gap-5'>
                        {renderCategories()}
                      </ul>
                    </div>
                    <div className='bg-gray-200  p-5 max-h-[650px] w-full overflow-y-auto scrollbar-thumb-red-light scrollbar-thin'>
                        <ul className='flex flex-col gap-2'>
                          {renderTraits()}
                        </ul>
                    </div>
                  </div>
                  :
                  <div>
                  </div>
                }
                {
                  builderStates.length > 1 ?
                    <div>
                      <select className="bg-gray-light border-2 border-gray-400 font-bold text-[18px] px-2 uppercase cursor-pointer text-grey-deep w-full h-12 leading-8" value={builderState} onChange={(e) => loadNewBuilderState(e.target.value)}>
                          {builderStates.map((collectionName) => (
                              <option value={collectionName} className="bg-gray-400 text-[16px] font-bold uppercase hover:bg-gray-deep cursor-pointer text-white hover:text-yellow-light px-2 py-[2px]">{collectionName}</option>
                          ))}
                      </select>
                    </div>
                    :
                    <div>
                    </div>
                }
              </div>
              <div className='flex-auto min-h-[547px] min-w-[547px]'>
                  {
                    imageArray.length ?
                      <div>
                        {imageArray.map((link, index) => {
                          return (
                              <img className='w-full h-auto object-cover' style={{ position: "absolute", right:100, bottom: 50, height: 600, width: 600, zIndex: index }} src={link}/>
                          )
                        })}
                      </div>
                    :
                    <div></div>
                  }
              </div>
            </div>
        </div>
    </div>
  )

};

export default BuilderHome;
