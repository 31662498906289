import React from 'react'

const TraitThumbnail = ({src, alt, to}) => {
  return (
        <div className='border border-[#B9B5B9] mb-2 shadow-sm'>
            <img className='w-full h-auto' src={src} alt={alt}/>
        </div>
  )
}

export default TraitThumbnail
