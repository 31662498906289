import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { WalletNotConnectedError } from '@solana/wallet-adapter-base';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';

import axios from "axios";


const EditBlockedTraits = (props) => {

  const [builderState, setBuilderState] = useState("Base")
  const [builderStates, setBuilderStates] = useState([])

  const [orderArrayDict, setOrderArrayDict] = useState({})

  const [conflictDict, setConflictDict] = useState({})

  const [conflicts, setConflicts] = useState([])
  const [selectedConflict, setSelectedConflict] = useState("---------")

  const [addNew, setAddNew] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState()
  const [categories, setCategories] = useState([])
  const [assetDict, setAssetDict] = useState({})

  const [primaryTrait, setPrimaryTrait] = useState()

  const [issueTraits, setIssueTraits] = useState([])
  const [editIssueTraits, setEditIssueTraits] = useState([])

  const [edit, setEdit] = useState(false)

  const [editConflict, setEditConflict] = useState()
  const [editConflictTraitArray, setEditConflictTraitArray] = useState()

  const [conflictName, setConflictName] = useState("")
  const [firstCategory, setFirstCategory] = useState()
  const [secondCategory, setSecondCategory] = useState()

  const [categoriesSubsection, setCategoriesSubsection] = useState([])

  const [firstConflict, setFirstConflict] = useState()

  const [conflictTraits, setConflictTraits] = useState([])

  useEffect(() => {
    var data = JSON.stringify({
      "projectID": props.projectID,
      "action": "getIndConflicts",
    });

    var config = {
      method: 'post',
      url: 'https://59200uzilj.execute-api.us-east-1.amazonaws.com/Production/updatebuilderconfig',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      console.log(response)
      setConflictDict(response.data.conflicts)
      setAssetDict(response.data.assetDict)

      setOrderArrayDict(response.data.categoryOrderArray)
      setBuilderStates(Object.keys(response.data.categoryOrderArray))
      setSelectedCategory(response.data.categoryOrderArray[builderState][0])
      setCategories(response.data.categoryOrderArray[builderState])

      setFirstCategory(response.data.categoryOrderArray[builderState][0])
      setSecondCategory(response.data.categoryOrderArray[builderState][1])
      setCategoriesSubsection(response.data.categoryOrderArray[builderState])
    })
    .catch(function (error) {
      console.log(error);
    })
  }, []);

  useEffect(() => {
    if (firstCategory === secondCategory){
      setSecondCategory(categoriesSubsection.find(element => {
        return element !== firstCategory
      }))
    }
    setCategoriesSubsection(categories.filter(category => {
      return category !== firstCategory
    }))
    setConflictTraits([])

  }, [firstCategory]);

  const onChange = (e) => {
    if (e.target.name === "conflictName"){
        setConflictName(e.target.value)
    }
  }

  const addToConflict = (traitName) => {
    // console.log(traitName)
    let conflictTraitsTemp = [...conflictTraits]
    if (!conflictTraitsTemp.includes(traitName)){
      conflictTraitsTemp.push(traitName)
    }
    setConflictTraits(conflictTraitsTemp)
  }

  const removeFromConflict = (traitName) => {
    let index = conflictTraits.indexOf(traitName);
    if (index > -1) {
      let conflictTraitsTemp = [...conflictTraits]
      conflictTraitsTemp.splice(index, 1)
      setConflictTraits(conflictTraitsTemp)
    }
  }

  const addToEditConflict = (traitName) => {
    let conflictTraitsTemp = [...editConflictTraitArray]
    if (!conflictTraitsTemp.includes(traitName)){
      conflictTraitsTemp.push(traitName)
    }
    setEditConflictTraitArray(conflictTraitsTemp)
  }

  const removeFromEditConflict = (traitName) => {
    // console.log("got here")
    let index = editConflictTraitArray.indexOf(traitName);
    if (index > -1) {
      let conflictTraitsTemp = [...editConflictTraitArray]
      conflictTraitsTemp.splice(index, 1)
      setEditConflictTraitArray(conflictTraitsTemp)
    }
  }

  const saveIssue = () => {
    // console.log(primaryTrait)
    var data = JSON.stringify({
      "projectID": props.projectID,
      "action": "addIndConflict",
      "traitName": primaryTrait.traitName,
      "traitCategory": primaryTrait.category,
      "primaryTraitID": primaryTrait.id,
      "blockedTraits": issueTraits,
      "collectionName": builderState
    });

    console.log(data)


    var config = {
      method: 'post',
      url: 'https://59200uzilj.execute-api.us-east-1.amazonaws.com/Production/updatebuilderconfig',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      console.log(response)
      setConflictDict(response.data.conflicts)
      setPrimaryTrait()
      setAddNew(false)
      setIssueTraits([])
    })
    .catch(function (error) {
      console.log(error);
    })
  }

  const saveEdit = () => {
    var data = JSON.stringify({
      "projectID": props.projectID,
      "action": "editIndConflict",
      "editConflictID": editConflict.id,
      "blockedTraits": editIssueTraits
    });


    var config = {
      method: 'post',
      url: 'https://59200uzilj.execute-api.us-east-1.amazonaws.com/Production/updatebuilderconfig',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      // console.log(response)
      setConflictDict(response.data.conflicts)
      setEdit(false)
      setEditConflict()
      setEditIssueTraits([])
    })
    .catch(function (error) {
      console.log(error);
    })
  }

  const renderConflicts = () => {
    let conflictsArray = []
    console.log(conflictDict)
    conflictDict[builderState].map(conflict => {
      conflictsArray.push(
        <div className='mb-5 max-w-[350px] mx-auto'>
          <div className='grid grid-cols-2 bg-gray-300 gap-2 p-5'>
              <div className='flex flex-col relative'>
                  <div className="bg-gray-400 relative font-bold text-[16px] px-2
                      uppercase cursor-pointer text-white w-full h-7 leading-8 mb-2">
                    PRIMARY
                  </div>
                  <p className='text-gray-deep text-lg p-1 leading-normal font-lekton text-[12px]'>{conflict.traitCategory} - {conflict.traitName}</p>
              </div>

              <div className='flex flex-col relative'>
                  <div className="bg-gray-400 relative font-bold text-[16px] px-2
                      uppercase cursor-pointer text-white w-full h-7 leading-8 mb-2">
                    BLOCKED
                  </div>
                  {
                    conflict.blockedTraits.map(blockedTrait => (
                      <p className='text-gray-deep text-lg p-1 leading-normal font-lekton text-[12px]'>{blockedTrait.category} - {blockedTrait.traitName}</p>
                    ))
                  }


              </div>
          </div>
          <div className='text-center mt-5'>
              <button onClick={() => {setToEdit(conflict)}} className={"border border-gray-400 w-24 text-sm rounded-md font-gilroy-bold text-gray-deep py-1 mr-2"}>edit</button>
              <button onClick={() => {deleteConflict(conflict)}} className={"border border-gray-400 w-24 text-sm rounded-md font-gilroy-bold text-gray-deep py-1"}>delete</button>
          </div>
        </div>

      )
    })
    return conflictsArray
  }

  const deleteConflict = (conflict) => {
    var data = JSON.stringify({
      "projectID": props.projectID,
      "action": "deleteIndConflict",
      "id": conflict.id
    });

    var config = {
      method: 'post',
      url: 'https://59200uzilj.execute-api.us-east-1.amazonaws.com/Production/updatebuilderconfig',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      // console.log(response)
      setConflictDict(response.data.conflicts)
    })
    .catch(function (error) {
      console.log(error);
    })
  }

  const setToEdit = (conflict) => {
    setEdit(true)
    setEditConflict(conflict)
    setEditIssueTraits(conflict.blockedTraits)
  }

  const renderEditConflict = () => {
    // console.log("got here")
    // console.log(editConflict)
    return (
      <>
      <div className='w-full'>
          <div className='w-full bg-gray-200 p-5 min-h-[600px]'>
              <h2 className='mb-5 text-xl text-gray-deep font-gilroy-bold text-left'>Selected Primary Trait</h2>
              <div className='grid grid-cols-2 items-start gap-x-5 gap-y-2 mb-2'>
                  <div>
                      <div className="bg-gray-300 relative font-bold text-[16px] px-2
                          uppercase cursor-pointer text-white w-full mb-5 text-left">
                          CATEGORY
                      </div>
                      <p className='text-lg font-lekton text-gray-deep px-1 mb-4 text-left lowercase'>{editConflict.traitCategory}</p>
                  </div>
                  <div>
                      <div className="bg-gray-300 relative font-bold text-[16px] px-2
                          uppercase cursor-pointer text-white w-full mb-5 text-left">
                          TRAIT
                      </div>
                      <p className='text-lg font-lekton text-gray-deep px-1 text-left lowercase'>{editConflict.traitName}</p>
                  </div>
              </div>
              <div className='grid grid-cols-2 gap-x-5'>
                  <div className='w-full py-2'>
                      <h4 className='mb-2 text-lg text-gray-deep font-gilroy-bold'>Select blocked traits</h4>
                      <select className="bg-gray-400 relative font-bold text-sm py-[2px] px-2
                          uppercase cursor-pointer text-white w-full mb-5" value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
                        {categories.map((category) => (
                          <option value={category}>{category}</option>
                        ))}
                      </select>
                      <ul className="w-full flex flex-col gap-2">
                        {renderIssueTraits(true)}
                      </ul>
                  </div>
                  <div>
                      <h4 className='mb-2 text-lg text-gray-deep font-gilroy-bold'>Blocked traits</h4>
                      {
                        mapEditIssueTraits()
                      }
                  </div>
              </div>
          </div>
          {
            mapEditIssueTraits().length > 0 ?
            <>
            <div className='mt-2 text-center'>
                <button onClick={() => {saveEdit()}} className={"text-white bg-black text-lg py-1 font-gilroy-bold w-32"}>save block</button>
            </div>
            </>
            :
            ""
          }
      </div>
      </>
    )
  }

  const renderTraits = () => {
    const traitArray = []
    assetDict[builderState][selectedCategory].map(trait => {
      traitArray.push(
        <>
          <li onClick={() => setPrimaryTrait(trait)} className="text-[16px] font-bold uppercase  cursor-pointer text-gray-deep px-2 py-[2px] text-left">{trait.traitName}</li>
        </>
      )
    })
    return traitArray
  }

  const addIssueTrait = (trait) => {
    if (!issueTraits.map(traits => traits.id).includes(trait.id)){
      let tempIssueArray = [...issueTraits]
      tempIssueArray.push(trait)
      setIssueTraits(tempIssueArray)
    }
  }

  const removeIssueTrait = (trait) => {
    let index = issueTraits.findIndex(traits => traits.id === trait.id)
    if (index > -1) {
      let tempIssueArray = [...issueTraits]
      tempIssueArray.splice(index, 1)
      setIssueTraits(tempIssueArray)
    }
  }

  const renderIssueTraits = (editing) => {
    const traitArray = []
    assetDict[builderState][selectedCategory].map(trait => {
      if (editing){
        traitArray.push(
          <>
            <li onClick={() => addEditIssueTrait(trait)} className="text-sm font-bold uppercase  cursor-pointer text-gray-deep px-2 py-[2px]">{trait.traitName}</li>
          </>
        )
      }
      else{
        traitArray.push(
          <>
            <li onClick={() => addIssueTrait(trait)} className="text-sm font-bold uppercase  cursor-pointer text-gray-deep px-2 py-[2px] text-left font-lekton">{trait.traitName}</li>
          </>
        )
      }
    })
    return traitArray
  }

  const mapIssueTraits = () => {
    const issueTraitArray = []
    issueTraits.map(trait => {
      issueTraitArray.push(
        <div className='grid grid-cols-2 items-start gap-2 mb-3 bg-gray-300 p-2'>
            <div className={"bg-gray-400 text-[12px] py-[2px] mb-2"}>
                <div className="relative font-bold px-2 uppercase cursor-pointer text-white text-[12px]">
                    Category
                </div>
                <p onClick={() => removeIssueTrait(trait)} className='text-sm font-bold text-gray-deep px-1 text-[10px]'>{trait.category}</p>
            </div>
            <div className={"bg-gray-400 text-sm py-[2px] mb-2"}>
                <div className="relative font-bold px-2 uppercase cursor-pointer text-white text-[12px]">
                    Trait
                </div>
                <p onClick={() => removeIssueTrait(trait)} className='text-sm font-bold text-gray-deep px-1 text-[10px]'>{trait.traitName}</p>
            </div>
        </div>
      )
    })
    return issueTraitArray
  }

  const addEditIssueTrait = (trait) => {
    if (!editIssueTraits.map(traits => traits.id).includes(trait.id)){
      let tempIssueArray = [...editIssueTraits]
      tempIssueArray.push(trait)
      setEditIssueTraits(tempIssueArray)
    }
  }

  const removeEditIssueTrait = (trait) => {
    let index = editIssueTraits.findIndex(traits => traits.id === trait.id)
    if (index > -1) {
      let tempIssueArray = [...editIssueTraits]
      tempIssueArray.splice(index, 1)
      setEditIssueTraits(tempIssueArray)
    }
  }

  const mapEditIssueTraits = () => {
    const issueTraitArray = []
    editIssueTraits.map(trait => {
      issueTraitArray.push(
        <div className='grid grid-cols-2 items-start gap-2 mb-3 bg-gray-300 p-2'>
            <div className={"bg-gray-400 text-[12px] py-[2px] mb-2"}>
                <div className="relative font-bold px-2 uppercase cursor-pointer text-white text-[12px]">
                    Category
                </div>
                <p onClick={() => removeEditIssueTrait(trait)} className='text-sm font-bold text-gray-deep px-1 text-[10px]'>{trait.category}</p>
            </div>
            <div className={"bg-gray-400 text-sm py-[2px] mb-2"}>
                <div className="relative font-bold px-2 uppercase cursor-pointer text-white text-[12px]">
                    Trait
                </div>
                <p onClick={() => removeEditIssueTrait(trait)} className='text-sm font-bold text-gray-deep px-1 text-[10px]'>{trait.traitName}</p>
            </div>
        </div>
      )
    })
    return issueTraitArray
  }

  const switchBuilderState = (collectionName) => {
    // console.log(collectionName)
    setBuilderState(collectionName)
    setSelectedCategory(orderArrayDict[collectionName][0])
    setCategories(orderArrayDict[collectionName])
    setFirstCategory(orderArrayDict[collectionName][0])
    setSecondCategory(orderArrayDict[collectionName][1])
    setCategoriesSubsection(orderArrayDict[collectionName])
  }

  return (
    <div className='w-[80%] ml-auto px-5'>
        {
          builderStates.length ?
            <div>
              <select className="bg-gray-light border-2 border-gray-400 font-bold text-[18px] px-2 uppercase cursor-pointer text-grey-deep w-[30%] h-12 leading-8" value={builderState} onChange={(e) => switchBuilderState(e.target.value)}>
                  {builderStates.map((collectionName) => (
                      <option value={collectionName} className="bg-gray-400 text-[16px] font-bold uppercase hover:bg-gray-deep cursor-pointer text-white hover:text-yellow-light px-2 py-[2px]">{collectionName}</option>
                  ))}
              </select>
            </div>
            :
            <div>
            </div>
        }
        <div className='max-w-5xl grid grid-cols-2 gap-10 p-5  items-center'>
          {
            addNew ?
            <div className='w-full'>
                <div className='bg-gray-200 w-full p-5 min-h-[550px]'>
                    {
                      primaryTrait ?
                      <div className='w-full'>
                          <div className='w-full bg-gray-200 p-5 min-h-[600px]'>
                              <h2 className='mb-5 text-xl text-gray-deep font-gilroy-bold text-left'>Selected primary trait</h2>
                              <div className='grid grid-cols-2 items-start gap-x-5 gap-y-2 mb-2'>
                                  <div>
                                      <div className="bg-gray-300 relative font-bold text-[16px] px-2
                                          uppercase cursor-pointer text-white w-full mb-5 text-left">
                                          CATEGORY
                                      </div>
                                      <p className='text-lg font-lekton text-gray-deep px-1 mb-4 text-left lowercase'>{primaryTrait.category}</p>
                                  </div>
                                  <div>
                                      <div className="bg-gray-300 relative font-bold text-[16px] px-2
                                          uppercase cursor-pointer text-white w-full mb-5 text-left">
                                          TRAIT
                                      </div>
                                      <p className='text-lg font-lekton text-gray-deep px-1 text-left lowercase'>{primaryTrait.traitName}</p>
                                  </div>
                                  <button onClick={() => {setPrimaryTrait()}} className="text-white bg-gray-deep text-sm  py-1 font-gilroy-bold w-32">edit</button>
                              </div>
                              <div className='grid grid-cols-2 gap-x-5'>
                                  <div className='w-full py-2'>
                                      <h4 className='mb-2 text-lg text-gray-deep font-gilroy-bold'>Select blocked traits</h4>
                                      <select className="bg-gray-400 relative font-bold text-sm py-[2px] px-2
                                          uppercase cursor-pointer text-white w-full mb-5" value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
                                        {categories.map((category) => (
                                          <option value={category}>{category}</option>
                                        ))}
                                      </select>
                                      <ul className="w-full flex flex-col gap-2">
                                        {renderIssueTraits(false)}
                                      </ul>
                                  </div>
                                  <div>
                                      <h4 className='mb-2 text-lg text-gray-deep font-gilroy-bold'>Blocked traits</h4>
                                      {
                                        mapIssueTraits()
                                      }
                                  </div>
                              </div>
                          </div>
                      </div>
                      :
                      <>
                      <h2 className='mb-5 text-xl text-gray-deep font-gilroy-bold text-left'>Select primary trait</h2>
                      <select className="bg-gray-300 relative font-bold text-[16px] px-2
                          uppercase cursor-pointer text-white w-full mb-5 text-left" value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
                        {categories.map((category) => (
                          <option value={category}>{category}</option>
                        ))}
                      </select>
                      <ul className="w-full flex flex-col gap-5">
                        {renderTraits()}
                      </ul>
                      </>
                    }
                </div>
                {
                  mapIssueTraits().length > 0 ?
                  <>
                  <div className='mt-2 text-center'>
                      <button onClick={() => {saveIssue()}} className={"text-white bg-black text-lg py-1 font-gilroy-bold w-32"}>save block</button>
                  </div>
                  </>
                  :
                  ""
                }
            </div>
            :
            <>
            {
              edit ?
              <>
              {renderEditConflict()}
              </>
              :
              <div className='w-full min-h-[500]'>
                  <div className='bg-gray-200 w-full min-h-[650px] p-5'>
                       <h2 className='text-xl text-gray-deep font-gilroy-bold mb-5'>Block trait combinations</h2>
                       <div className='text-center mb-5'>
                           <button className="bg-gray-deep text-yellow-light py-1 px-8 rounded-sm" onClick={() => {setAddNew(true)}}>Create Blocker!</button>
                       </div>
                       {
                         conflictDict[builderState]?.length ?
                             <>
                             <p className='text-lg leading-6 text-gray-deep text-center max-w-[350px] mx-auto mb-5'>
                                   These are all the trait combinations that you have blocked so far.
                             </p>
                               {
                                 renderConflicts()
                               }
                             </>
                             :
                             <>
                             <p className='text-lg leading-6 text-gray-deep text-center max-w-[350px] mx-auto mb-5'>
                               There are no conflicts
                             </p>
                             </>
                       }
                  </div>
              </div>
            }
            </>
          }
        </div>
    </div>
  )

};

export default EditBlockedTraits;
