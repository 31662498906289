import React, {useState, useEffect} from "react";
import axios from "axios";
import {
  Transaction,
  SystemProgram,
  Keypair,
  Connection,
  PublicKey,
  LAMPORTS_PER_SOL,
  clusterApiUrl
} from "@solana/web3.js";
import {
  MINT_SIZE,
  TOKEN_PROGRAM_ID,
  createInitializeMintInstruction,
  getMinimumBalanceForRentExemptMint,
  getAssociatedTokenAddress,
  createAssociatedTokenAccountInstruction,
  createMintToInstruction
} from '@solana/spl-token';
import { createCreateMetadataAccountV3Instruction, createVerifyCollectionInstruction } from '@metaplex-foundation/mpl-token-metadata';
import {
  findMetadataPda,
  Metaplex,
  keypairIdentity,
  findMasterEditionV2Pda
} from '@metaplex-foundation/js';
import * as bs58 from "bs58";
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import Loader from "../assets/img/loading.gif"

const IndividualPendingNFT = (props) => {
  console.log(props)
  // wallet and metaplex connections
  const { connection } = useConnection();
  const { publicKey, sendTransaction } = useWallet();

  const [supply, setSupply] = useState()
  const [image, setImage] = useState()
  const [name, setName] = useState()
  const [sellerFee, setSellerFee] = useState()
  const [symbol, setSymbol] = useState()
  const [price, setPrice] = useState()
  const [purchasingCurrency, setPurchasingCurrency] = useState()
  const [jsonLink, setJSONLink] = useState()
  const [id, setID] = useState()
  const [cosmetic, setCosmetic] = useState(0)
  const [gateLink, setGateLink] = useState()
  const [isSlotTrait, setIsSlotTrait] = useState()
  const [traitCategory, setTraitCategory] = useState()

  const [popup, setPopup] = useState(false)

  // getting the relevant information from the pending trait
  useEffect(() => {
    var data = JSON.stringify({
      "identifyingHash": props.identifyingHash,
      "projectID": props.projectID,
      "action": "sfts"
    });

    var config = {
      method: 'post',
      url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/getindividualpendingnft',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      // console.log(response, 'got here')
      setCosmetic(response.data.cosmetic)
      setSupply(response.data.supply)
      setImage(response.data.imageLink)
      setSymbol(response.data.symbol)
      setName(response.data.name)
      setSellerFee(response.data.sellerFee)
      setPrice(response.data.price)
      setID(response.data.id)
      setPurchasingCurrency(response.data.purchasingCurrency)
      setJSONLink(response.data.jsonLink)
      setGateLink(response.data.gateLink)
      setIsSlotTrait(response.data.isSlotTrait)
      setTraitCategory(response.data.traitCategory)
    })
    .catch(function (error) {
      // console.log(error);
    })
  }, []);

  const createSFTs = async () => {
    setPopup(true)

    const quicknodeURL = process.env.QUICKNODE
    const solanaConnection = new Connection(quicknodeURL)
    const traitWallet = Keypair.fromSecretKey(bs58.decode(process.env.WALLET))
    // if (props.projectID === 52){
    //   const traitWallet = Keypair.fromSecretKey(bs58.decode(process.env.ASTRAL_WALLET))
    // }
    const collectionToken = new PublicKey(props.collectionHash)
    const tokenMetadata = {
      symbol: symbol,
      name: name,
      uri: jsonLink,
      sellerFeeBasisPoints: parseInt(sellerFee) * 100,
      creators: [
            {
                address: traitWallet.publicKey,
                verified: true,
                share: 100
            }
        ],
      collection: {
        verified: false,
        key: collectionToken,
      },
      uses: null,
    }
    console.log(tokenMetadata)
    const MINT_CONFIG = {
      numDecimals: 0,
      numberTokens: supply
    }

    let mintKeypair = Keypair.generate();

    //Get the minimum lamport balance to create a new account and avoid rent payments
    const requiredBalance = await getMinimumBalanceForRentExemptMint(solanaConnection);

    //metadata account associated with mint
    const metadataPDA = await findMetadataPda(mintKeypair.publicKey);
    console.log(metadataPDA)
    //get associated token account of your wallet
    const tokenATA = await getAssociatedTokenAddress(mintKeypair.publicKey, traitWallet.publicKey);
    console.log(tokenATA)
    const collectionMetadata = await findMetadataPda(collectionToken);
    console.log(collectionMetadata)
    const masterEditionCollection = await findMasterEditionV2Pda(collectionToken)
    console.log(masterEditionCollection)
    const createNewTokenTransaction = new Transaction().add(
        SystemProgram.createAccount({
            fromPubkey: traitWallet.publicKey,
            newAccountPubkey: mintKeypair.publicKey,
            space: MINT_SIZE,
            lamports: requiredBalance,
            programId: TOKEN_PROGRAM_ID,
        }),
        createInitializeMintInstruction(
          mintKeypair.publicKey, //Mint Address
          MINT_CONFIG.numDecimals, //Number of Decimals of New mint
          traitWallet.publicKey, //Mint Authority
          traitWallet.publicKey, //Freeze Authority
          TOKEN_PROGRAM_ID),
        createAssociatedTokenAccountInstruction(
          traitWallet.publicKey, //Payer
          tokenATA, //Associated token account
          traitWallet.publicKey, //token owner
          mintKeypair.publicKey, //Mint
        ),
        createMintToInstruction(
          mintKeypair.publicKey, //Mint
          tokenATA, //Destination Token Account
          traitWallet.publicKey, //Authority
          MINT_CONFIG.numberTokens * Math.pow(10, MINT_CONFIG.numDecimals), //number of tokens
        ),
        createCreateMetadataAccountV3Instruction(
          {
            metadata: metadataPDA,
            mint: mintKeypair.publicKey,
            mintAuthority: traitWallet.publicKey,
            payer: traitWallet.publicKey,
            updateAuthority: traitWallet.publicKey,
          },
          {
            createMetadataAccountArgsV3:
              {
                data: tokenMetadata,
                isMutable: true,
                collectionDetails: null
              }
          }
        ),
        createVerifyCollectionInstruction(
          {
            metadata: metadataPDA,
            collectionAuthority: traitWallet.publicKey,
            payer: traitWallet.publicKey,
            collectionMint: collectionToken,
            collection: collectionMetadata,
            collectionMasterEditionAccount: masterEditionCollection,
          }
        )
    )
    console.log(createNewTokenTransaction)
    const transactionID = await solanaConnection.sendTransaction(createNewTokenTransaction, [traitWallet, mintKeypair], {skipPreflight: true});
    console.log(transactionID)
    // log created trait into the DB
    var data = JSON.stringify({
      "action": "createSFT",
      "creationID": id,
      "projectID": props.projectID,
      "identifyingHash": props.identifyingHash,
      "createdBy": publicKey,
      "SFTHash": mintKeypair.publicKey,
      "name": name,
      "jsonLink": jsonLink,
      "creationTransactionID": transactionID,
      "nftRequirementHash": gateLink
    });
    console.log(data)

    var config = {
      method: 'post',
      url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/nftcreation',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    // change pages back to created traits
    axios(config)
    .then(function (response) {
      console.log(response)
      setPopup(false)
      props.setCreatedTrait(response.data.traitID)
    })
    .catch(function (error) {
      // console.log(error);
    })
  }

  const renderPopup = () => {
    return(
      <div style={{position: "absolute" , height: "100%", width: "100%"}} >
          <div className='fixed bg-red-deep p-10 left-1/2 top-1/2 w-fit h-fit -translate-x-1/2 -translate-y-1/2 text-center rounded-full duration-100 z-10'>
              <div className='w-24 mx-auto mb-5'>
                  <img className='max-w-[25px] mx-auto' src={Loader} alt="loading..."/>
              </div>
              <p className='max-w-[300px] mx-auto font-text text-white'> The NFTs are getting created! Please dont leave this page until they are completed.</p>
          </div>
      </div>
    )
  }


  return (
    <div>
        <div className='w-[80%] ml-auto px-5'>
            <div className='max-w-6xl grid grid-cols-1-4 gap-5 mr-auto relative'>
                { popup  ?
                    renderPopup()
                    :
                    <>
                    </>
                }
                <div className='grid gap-2 text-center'>
                    <img className='w-full h-auto border border-gray-400' src={image} alt={image}/>
                    <div className='bg-gray-400 text-white text-[16px] font-bold leading-4 p-1'>
                        Trait Name:<br/> {name}
                    </div>
                    <div className='bg-gray-400 text-white text-[16px] font-bold leading-4 p-1'>
                        Trait Supply:<br/> {supply}
                    </div>
                    <div className='bg-gray-400 text-white text-[16px] font-bold leading-4 p-1'>
                        Price:<br/> {isSlotTrait ? 'N/A' : price}
                    </div>
                    <div className='bg-gray-400 text-white text-[16px] font-bold leading-4 p-1'>
                        Purchasing Currency:<br/> {purchasingCurrency === "XXX" ? "SOL" : purchasingCurrency}
                    </div>
                </div>
                <div className='p-5 border-2 border-gray-300'>
                    <div>
                        <ul className='relative flex justify-between items-start before:absolute before:w-[88%] before:h-[1px] before:bg-gray-300 before:left-1/2 before:-translate-x-1/2 before:top-5'>
                            <li className='relative z-10 flex flex-col items-center justify-center text-center font-gilroy-bold text-lg leading-5 text-gray-deep gap-1'>
                                <span className='inline-block w-10 h-10 rounded-full bg-yellow-light'></span> upload <br/> assets
                            </li>
                            <li className='relative z-10 flex flex-col items-center justify-center text-center font-gilroy-bold text-lg leading-5 text-gray-deep gap-1'>
                                <span className='inline-block w-10 h-10 rounded-full bg-yellow-light'></span> upload <br/> metadata
                            </li>
                            <li className='relative z-10 flex flex-col items-center justify-center text-center font-gilroy-bold text-lg leading-5 text-gray-deep gap-1'>
                                <span className='inline-block w-10 h-10 rounded-full bg-yellow-light'></span> fund SFTs
                            </li>
                            <li className='relative z-10 flex flex-col items-center justify-center text-center font-gilroy-bold text-lg leading-5 text-gray-300 gap-1'>
                                <span className='inline-block w-10 h-10 rounded-full bg-gray-300'></span> Create NFTs
                            </li>
                        </ul>
                        <div className='mb-5'>
                            <p className='font-gilroy-bold text-xl text-gray-deep mt-5'>The information you submitted has been uploaded and verified, we are now ready to create the SFTs</p>
                            <div>
                                <p className='text-gray-400 text-sm'>The SFT will be placed in a secure maxin' wallet until purchased</p>
                                <p className='text-gray-400 text-sm'>This allows trait purchases to run smoothly without the need for your individual approval</p>
                            </div>
                        </div>
                        <div>
                            <div>
                                <div className='mb-5'>
                                    <p className='font-gilroy-bold text-xl text-gray-deep mt-5'>Click below to create SFTs!</p>
                                    <p className='text-gray-400 text-sm'>Please remain on the page until all SFTs have been created</p>
                                </div>
                                <br></br><br></br>
                                <button onClick={() => {createSFTs()}} className="bg-red-light font-gilroy-bold text-white px-10 text-lg font-semibold py-1 rounded-md">
                                    Create SFTs!
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

  );

};

export default IndividualPendingNFT;
