import React, {useState, useEffect} from "react";
import axios from "axios";
import IndividualPendingNFT from "./IndividualPendingNFT";
import TraitHeader from '../Components/TraitHeader';
import BackLink from '../Components/BackLink';
import IndividualPendingTrait from '../Components/IndividualPendingTrait';
import PendingTraitCreate from '../Components/PendingTraitCreate';
import PendingTraitImg from '../assets/img/thumbnail.png';
import TraitsFiltering from '../Components/TraitsFiltering';
import PendingTraitScroll from '../Components/PendingTraitScroll'
import TraitShopHome from "./TraitShopHome"

import {
  Transaction,
  SystemProgram,
  Keypair,
  Connection,
  PublicKey,
  LAMPORTS_PER_SOL,
  clusterApiUrl
} from "@solana/web3.js";

import {
  MINT_SIZE,
  TOKEN_PROGRAM_ID,
  createInitializeMintInstruction,
  getMinimumBalanceForRentExemptMint,
  getAssociatedTokenAddress,
  createAssociatedTokenAccountInstruction,
  createMintToInstruction
} from '@solana/spl-token';

import { createCreateMetadataAccountV2Instruction, createVerifyCollectionInstruction } from '@metaplex-foundation/mpl-token-metadata';
import {
  findMetadataPda,
  Metaplex,
  keypairIdentity,
  findMasterEditionV2Pda
} from '@metaplex-foundation/js';
import * as bs58 from "bs58";
import { useConnection, useWallet } from '@solana/wallet-adapter-react';


const PendingNFTs = (props) => {
  console.log(props)
  const { publicKey, sendTransaction } = useWallet();
  const { connection } = useConnection();


  const [pendingNFTs, setPendingNFTs] = useState([])
  const [filterName, setFilterName] = useState("")
  const [filterID, setFilterID] = useState("")
  const [selectedHash, setSelectedHash] = useState(null)
  const [options, setOptions] = useState([])
  const [catField, setCatField] = useState('');
  const [catArrow,  setCatArrow] = useState(false);
  const [category,setCategory] = useState(null)

  useEffect(() => {
    var data = JSON.stringify({
      "projectID": props.projectID
    });

    var config = {
      method: 'post',
      url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/getallpendingnfts',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      console.log(response)
      if (response.data.data.length !== 0){
        var pendingNFTsTemp = []
        if (props.page === 'traitShop'){
          var newArray = response.data.data.filter((nft) =>
              nft.isSlotTrait !== 1
          )
        }
        else if (props.page === 'slotMachine'){
          var newArray = response.data.data.filter((nft) =>
              nft.isSlotTrait === 1
          )
        }

        newArray.forEach(nft => {
          pendingNFTsTemp.push({
            identifyingHash: nft.identifyingHash,
            name: nft.name,
            imageLink: nft.imageLink,
            videoLink: nft.videoLink,
            supply: nft.supply,
            status: nft.status,
            isSlotTrait: nft.isSlotTrait,
            id: nft.id,
            jsonLink: nft.jsonLink,
            sellerFee: nft.sellerFee,
            symbol: nft.symbol
          })
        })

        console.log(pendingNFTsTemp)
        setPendingNFTs(pendingNFTsTemp)
      }
    })
    .catch(function (error) {
      console.log(error);
    })
  }, []);

  const onClickCategory = event => {
    setCatField(current => !current);
    setCatArrow(current => !current);
  };
  const onChangeCat = (e)=>{
    setCategory(e.target.innerText)
   }

  const renderPendingTraitScroll = () => {
    const traitsList = []
    if (pendingNFTs.length !== 0){
      pendingNFTs.forEach(trait => {
        const traitInfo = (
          <div className='grid gap-2 text-center'>
              <PendingTraitScroll
                src={trait.imageLink}
                alt="Pending Trait Scroll"
                traitNameTitle={"Trait Name:"}
                traitName={trait.name}
                traitSupply={"Trait Supply:"}
                traitSupplyStats={trait.supply}
                traitAmount={"Production Status:"}
                traitAmountStats={trait.status}
                traitbtnText={"View Details"}
                traitText={"These are the non live traits"}
                />
              <div className='border border-gray-400 p-3'>
                  {/* <button className='bg-gray-deep text-[16px] font-bold px-4 py-1 text-yellow-light rounded-sm' onClick={() => {props.setPendingTrait(trait.identifyingHash)}}>{"View Details"}</button> */}
                  {/* <button className='bg-gray-deep text-[16px] font-bold px-4 py-1 text-yellow-light rounded-sm' onClick={() => {createTrait(trait)}}>{"View Details"}</button> */}
              </div>
          </div>
        )
        if (filterID === "" && filterName === ""){
          traitsList.push(traitInfo)
        }
        else if (filterID !== "" && parseInt(filterID) === trait.id){
          traitsList.push(traitInfo)
        }
        else if (filterName !== "" && trait.name.toLowerCase().includes(filterName.toLowerCase())){
          traitsList.push(traitInfo)
        }
      })
    }
    return traitsList
  }

  const createSFTs = async () => {
    console.log(pendingNFTs)

    pendingNFTs.slice(0,15).map(async (trait) => {
      console.log('starting trait', trait)
      const quicknodeURL = process.env.QUICKNODE
      const solanaConnection = new Connection(quicknodeURL)
      const traitWallet = Keypair.fromSecretKey(bs58.decode(process.env.ASTRAL_WALLET))
      const collectionToken = new PublicKey("Eajo6DaL8Wm63ncSDfhRnuPVfUc4SfXPGhytiASbwi4D")

      const tokenMetadata = {
        symbol: trait.symbol,
        name: trait.name,
        uri: trait.jsonLink,
        sellerFeeBasisPoints: parseInt(trait.sellerFee),
        creators: [
              {
                  address: traitWallet.publicKey,
                  verified: true,
                  share: 100
              }
          ],
        collection: {
          verified: false,
          key: collectionToken,
        },
        uses: null,
      }
      console.log(tokenMetadata)

      const MINT_CONFIG = {
        numDecimals: 0,
        numberTokens: trait.supply
      }

      let mintKeypair = await Keypair.generate();

      //Get the minimum lamport balance to create a new account and avoid rent payments
      const requiredBalance = await getMinimumBalanceForRentExemptMint(solanaConnection);

      //metadata account associated with mint
      const metadataPDA = await findMetadataPda(mintKeypair.publicKey);

      //get associated token account of your wallet
      const tokenATA = await getAssociatedTokenAddress(mintKeypair.publicKey, traitWallet.publicKey);

      const collectionMetadata = await findMetadataPda(collectionToken);

      const masterEditionCollection = await findMasterEditionV2Pda(collectionToken)

      const createNewTokenTransaction = new Transaction().add(
          SystemProgram.createAccount({
              fromPubkey: traitWallet.publicKey,
              newAccountPubkey: mintKeypair.publicKey,
              space: MINT_SIZE,
              lamports: requiredBalance,
              programId: TOKEN_PROGRAM_ID,
          }),
          createInitializeMintInstruction(
            mintKeypair.publicKey, //Mint Address
            MINT_CONFIG.numDecimals, //Number of Decimals of New mint
            traitWallet.publicKey, //Mint Authority
            traitWallet.publicKey, //Freeze Authority
            TOKEN_PROGRAM_ID),
          createAssociatedTokenAccountInstruction(
            traitWallet.publicKey, //Payer
            tokenATA, //Associated token account
            traitWallet.publicKey, //token owner
            mintKeypair.publicKey, //Mint
          ),
          createMintToInstruction(
            mintKeypair.publicKey, //Mint
            tokenATA, //Destination Token Account
            traitWallet.publicKey, //Authority
            MINT_CONFIG.numberTokens * Math.pow(10, MINT_CONFIG.numDecimals), //number of tokens
          ),
          createCreateMetadataAccountV2Instruction(
            {
              metadata: metadataPDA,
              mint: mintKeypair.publicKey,
              mintAuthority: traitWallet.publicKey,
              payer: traitWallet.publicKey,
              updateAuthority: traitWallet.publicKey,
            },
            {
              createMetadataAccountArgsV2:
                {
                  data: tokenMetadata,
                  isMutable: true
                }
            }
          ),
          createVerifyCollectionInstruction(
            {
              metadata: metadataPDA,
              collectionAuthority: traitWallet.publicKey,
              payer: traitWallet.publicKey,
              collectionMint: collectionToken,
              collection: collectionMetadata,
              collectionMasterEditionAccount: masterEditionCollection,
            }
          )
      )

      console.log("transaction Created")
      let transactionID
      try {
        transactionID = await solanaConnection.sendTransaction(createNewTokenTransaction, [traitWallet, mintKeypair]);
        console.log('transactionID', transactionID)
        var data = JSON.stringify({
          "action": "createSFT",
          "creationID": trait.id,
          "projectID": 52,
          "identifyingHash": trait.identifyingHash,
          "createdBy": publicKey,
          "SFTHash": mintKeypair.publicKey,
          "name": trait.name,
          "jsonLink": trait.jsonLink,
          "creationTransactionID": transactionID,
          "nftRequirementHash": 0
        });
        console.log(data)

        var config = {
          method: 'post',
          url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/nftcreation',
          headers: {
            'x-api-key': process.env.GATEWAY_KEY,
            'Content-Type': 'application/json'
          },
          data: data
        };

        // change pages back to created traits
        let response = await axios(config)
        console.log('createdTrait in table', response)
      }
      catch (error){
        console.log(error)
      }
    })

  }

  return (
    <div>
        {/*
        <button onClick ={() => createSFTs()}>
          createSFTs
        </button>
        */}
        <div className='w-[80%] ml-auto px-5'>
            <div className='max-w-3xl ml-24 mr-auto relative'>
                {
                  pendingNFTs.length ?
                  <div>
                    <form>
                        <div className='mb-5 max-w-2xl grid grid-cols-3 gap-5'>
                            <div>
                                <label className='text-lg text-gray-deep font-gilroy-bold'>filter by name</label>
                                <input className='bg-gray-light border-2 border-gray-400 px-1 py-[2px] w-full text-[16px] font-bold text-gray-deep focus:outline-none uppercase'
                                    type="text"
                                    onChange={(e) => setFilterName(e.target.value)}
                                />
                            </div>
                        </div>
                    </form>
                    <div className='grid grid-cols-3 gap-10'>
                        {renderPendingTraitScroll()}
                    </div>
                  </div>
                  :
                  <div>
                    You have no pending NFTs
                  </div>
                }
            </div>
        </div>
    </div>
  );
};

export default PendingNFTs;
