import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { WalletNotConnectedError } from '@solana/wallet-adapter-base';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';


import axios from "axios";

import EditCategoryConflicts from './EditCategoryConflicts'
import EditBlockedTraits from './EditBlockedTraits'
import EditBuilderImages from './EditBuilderImages'
import Mapping from './Mapping'
import EditTraitLayering from './EditTraitLayering'
import BuilderHeader from '../Components/BuilderHeader';
import CustomBtn from '../Components/CustomBtn';


const EditBuilderConfig = (props) => {

  return (
    <div>
        <div className='w-[80%] ml-auto px-5'>
            <div className='max-w-3xl bg-gray-200 p-5 text-center'>
                    <h2 className='text-xl text-gray-deep font-gilroy-bold mb-10'>Builder configuration</h2>
                    <div className='mb-14'>
                        <button className={"bg-red-light text-white py-2 px-6 rounded-md text-lg font-gilroy-bold"} onClick={() => {props.setConfigPage("layerOrdering")}}>edit layer ordering</button>
                        <p className='text-xl text-gray-deep leading-8 mt-5'>This allows you to edit the default layer order of your trait.</p>
                    </div>
                    <div className='mb-14'>
                        <button className={"bg-yellow-deep text-white py-2 px-6 rounded-md text-lg font-gilroy-bold"} onClick={() => {props.setConfigPage("conflicts")}}>
                          edit conflicts
                        </button>
                        <p className='text-xl text-gray-deep leading-8 mt-5'>Conflicts allow you to put conditional on specific traits,
                            changing the layering order to ensure the network is always perfect.
                        </p>
                    </div>
                    <div className='mb-14'>
                        <button className={"bg-yellow-light text-white py-2 px-6 rounded-md text-lg font-gilroy-bold"} onClick={() => {props.setConfigPage("blockers")}}>
                          edit blockers
                        </button>
                        <p className='text-xl text-gray-deep leading-8 mt-5'>Sometimes there are issues with trait combinations, this allows you to block off specific trait combinations. </p>
                    </div>
            </div>
        </div>
    </div>
  )

};

export default EditBuilderConfig;
