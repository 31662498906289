import React, {useState, useEffect} from "react";
import axios from "axios";
import Arweave from 'arweave';
import ProgressBar from "@ramonak/react-progress-bar";
import MutationDash from './MutationDash'

import Loader from "../assets/img/loading.gif"

import { Metaplex, keypairIdentity, walletAdapterIdentity, toMetaplexFileFromBrowser } from "@metaplex-foundation/js";
import { Connection, clusterApiUrl, PublicKey } from "@solana/web3.js";
import * as bs58 from "bs58";

import { WalletNotConnectedError } from '@solana/wallet-adapter-base';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';

import { uploadFile } from 'react-s3';
import S3 from 'react-aws-s3';
window.Buffer = window.Buffer || require("buffer").Buffer;


const MutationOnboarding = (props) => {
  var JSZip = require("jszip");

  const [hasMutation, setHasMutation] = useState(0)

  const [mutationID, setMutationID] = useState()
  const [mutationName, setMutationName] = useState()
  const [mutationNameTemp, setMutationNameTemp] = useState()
  const [uploadedHash, setUploadedhash] = useState(0)
  const [hashLink, setHashLink] = useState()
  const [uploadedAttributeDict, setUploadedAttributeDict] = useState(0)
  const [attributeDictLink, setAttributeDictLink] = useState()
  const [assetsLoaded, setAssetsLoaded] = useState(0)
  const [conditionsSet, setConditionsSet] = useState(0)
  const [metadataFieldSet, setMetadataFieldSet] = useState(0)
  const [onboardingCompleted, setOnboardingCompleted] = useState(0)
  const [isLive, setIsLive] = useState(0)

  const [uploadedMetadata, setUploadedMetadata] = useState(0)
  const [metadataLink, setMetadataLink] = useState()

  const [hashFile, setHashFile] = useState()
  const [hashArray, setHashArray] = useState([])
  const [gettingMetadata, setGettingMetadata] = useState(false)
  const [metadataDict, setMetadataDict] = useState({})
  const [checkedHashes, setCheckedHashes] = useState([])

  const [artworkArray, setArtworkArray] = useState([])
  const [categoriesSet, setCategoriesSet] = useState(0)

  // art asset variables
  const [errorArray, setErrorArray] = useState([])
  const [checkingZip, setCheckingZip] = useState(false)
  const [comparisonError, setComparisonError] = useState()
  const [zipDict, setZipDict] = useState()
  const [zipNameDict, setZipNameDict] = useState()
  const [uploadingCollection, setUploadingCollection] = useState(false)

  const [conditionalState, setConditionalState] = useState("default")

  const [metadataRestrictionDict, setMetadataRestrictionDict] = useState({})
  const [selectedCategory, setSelectedCategory] = useState("---------------")
  const [selectedTrait, setSelectedTrait] = useState()

  const [nftRestrictionFileArray, setNFTRestrictionFileArray] = useState([])
  const [currentRestrictionHash, setCurrentRestrictionHash] = useState()

  const [radioCurrencyType, setRadioCurrencyType] = useState()
  const [splHash, setSPLHash] = useState()
  const [price, setPrice] = useState()
  const [paymentArray, setPaymentArray] = useState([])




  const [readyForMutations, setReadyForMutations] = useState(false)

  const [metadata, setMetadata] = useState()

  const [alreadyCreated, setAlreadyCreated] = useState([])
  const [tryAgain, setTryAgain] = useState(false)

  const [categories, setCategories] = useState()

  const [popupState, setPopupState] = useState()
  const [popup, setPopup] = useState(false)

  const [uploadCategory, setUploadCategory] = useState()

  const [step, setStep] = useState("default")
  const [uploadedMutations, setUploadedMutations] = useState(false)

  const [missingAssetDict, setMissingAssetDict] = useState({})
  const [mutatedAssetDict, setMutatedAssetDict] = useState({})
  const [gotMissingDict, setGotMissingDict] = useState(false)

  const [traitImage, setTraitImage] = useState()
  const [mappedTraitName, setMappedTraitName] = useState()


  useEffect(() => {
    var data = JSON.stringify({
      "action": "getMutations",
      "projectID": props.projectID
    });

    var config = {
      method: 'post',
      url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/setmutations',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': process.env.GATEWAY_KEY
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      console.log(response.data)
      if (response.data.hasMutation){
        setHasMutation(1)
        if (response.data.mutationDetails.isLive){
          setIsLive(1)
        }
        else{
          setMutationName(response.data.mutationDetails.mutationName)
          setMutationID(response.data.mutationDetails.id)
          setAssetsLoaded(response.data.mutationDetails.assetsLoaded)
          setCategoriesSet(response.data.mutationDetails.categoriesSet)
          setConditionsSet(response.data.mutationDetails.conditionsSet)
          setUploadedhash(response.data.mutationDetails.hashLoaded)
          if (response.data.mutationDetails.hashLoaded){
            setHashLink(response.data.mutationDetails.hashLink)
          }
          setUploadedAttributeDict(response.data.mutationDetails.uploadedAttributeDict)
          if (response.data.mutationDetails.uploadedAttributeDict){
            setAttributeDictLink(response.data.mutationDetails.attributeDictLink)
          }
          setMetadataFieldSet(response.data.mutationDetails.metadataFieldSet)
          setOnboardingCompleted(response.data.mutationDetails.onboardingComplete)
        }
      }
      else {
        setHasMutation(0)
      }
    })
    .catch(function (error) {
      console.log(error);
    })
  }, []);

  useEffect(() => {
    // this is failing when uploading a new zip for the upgrade collectoin
    if (metadataDict && zipNameDict){
      setErrorArray([])
      let errorArrayTemp = []
      let comparisonError
      console.log(metadataDict, zipNameDict)
      Object.keys(metadataDict).forEach(category => {
        console.log(category)
        if (!(Object.keys(zipNameDict).includes(category))){
          errorArrayTemp.push("Category Error: " + category + " was found in your metadata but not in your zip file. You either forgot this category or have it spelled a different way.")
        }
      })
      if (!errorArrayTemp.length){
        Object.keys(metadataDict).forEach(category => {
          let traitArray = metadataDict[category]
          traitArray.map(traitName => {
            if (!traitName.toLowerCase().includes("none")){
              if (!zipNameDict[category].includes(traitName)){
                errorArrayTemp.push("Trait Error: " + traitName + " under the category " + category + " was found in your metadata but not in your zip file. This trait is either missing or spelled in a different way.")
              }
            }
          })
        })
      }
      console.log(errorArrayTemp)


      if (errorArrayTemp.length){
        setZipDict()
        setCheckingZip(false)
        setErrorArray(errorArrayTemp)
      }
      else{
        uploadCollection()
        setErrorArray([])
      }
    }
  }, [metadataDict, zipNameDict]);

  useEffect(() => {
    if (comparisonError){
      setCheckingZip(false)
      setZipDict()
    }
  }, [comparisonError]);

  useEffect(() => {
    if (hashLink){
      var config = {
        method: 'get',
        url: hashLink,
        headers: {
          'Content-Type': 'application/json'
        },
      };

      let hashArray = axios(config).then(hashlist => {
        setHashArray(hashlist.data)
      })
    }
  }, [hashLink]);

  useEffect(() => {
    if (attributeDictLink){
      var config = {
        method: 'get',
        url: attributeDictLink,
        headers: {
          'Content-Type': 'application/json'
        },
      };

      let attributeDictTemp = axios(config).then(attributeDict => {
        console.log(attributeDict.data)
        setMetadataDict(attributeDict.data)
      })
    }
  }, [attributeDictLink]);


  const saveMutation = () => {
    var data = JSON.stringify({
      "projectID": props.projectID,
      "action": "readyForMutations"
    });

    var config = {
      method: 'post',
      url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/editClients',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      props.setReadyForMutations(true)
    })
    .catch(function (error) {
      console.log(error);
    })
  }

  const uploadTrait = async() => {

    const s3Config = {
      bucketName:"maxinbodyshop",
      region: "us-east-2",
      accessKeyId: process.env.AWS_KEY,
      secretAccessKey: process.env.AWS_SECRET,
      s3Url: 'https://maxinbodyshop.s3.amazonaws.com'
    }

    console.log(traitImage)

    const ReactS3Client = new S3(s3Config);

    const fileName = "mutations/" + props.projectID + "/" + traitImage.name.replaceAll(" ", "_")
    const link = "https://maxinbodyshop.s3.us-east-2.amazonaws.com/" + fileName
    let s3Response = await ReactS3Client.uploadFile(traitImage, fileName)

    var data = JSON.stringify({
      "projectID": props.projectID,
      "category": selectedCategory,
      "selectedTraitName": JSON.parse(selectedTrait),
      "traitName": mappedTraitName,
      "link": link,
      "action": "mapMutatedImage"
    });

    var config = {
      method: 'post',
      url: 'https://59200uzilj.execute-api.us-east-1.amazonaws.com/Production/uploadimages',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      console.log(response)
      var listTemp = missingAssetDict[selectedCategory]
      console.log(listTemp)
      var index = listTemp.indexOf(JSON.parse(selectedTrait))
      console.log(index)
      listTemp.splice(index, 1)
      console.log(listTemp)
      const missingDictCopy = {...missingAssetDict}
      console.log(missingDictCopy)
      missingDictCopy[selectedCategory] = listTemp
      console.log(missingDictCopy)
      setSelectedTrait()
      setMissingAssetDict(missingDictCopy)
    })
    .catch(function (error) {
      console.log(error);
    })

  }

  const saveMutationName = () => {
    console.log("getting here")
    var data = JSON.stringify({
      "projectID": props.projectID,
      "mutationName": mutationNameTemp,
      "action": "startMutation"
    });

    var config = {
      method: 'post',
      url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/setmutations',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      console.log(response)
      setMutationID(response.data.mutationDetails.id)
      setMutationName(mutationNameTemp)
    })
    .catch(function (error) {
      console.log(error);
    })
  }

  const saveHash = async() => {
    console.log(hashFile)
    let fileName = "mutations/" + props.projectID.toString() + "/hash/" + hashFile.name.replaceAll(" ", "_").replaceAll(".json", "")
    let link = "https://maxinbodyshop.s3.us-east-2.amazonaws.com/" + fileName + ".json"

    const s3Config = {
      bucketName:"maxinbodyshop",
      region: "us-east-2",
      accessKeyId: process.env.AWS_KEY,
      secretAccessKey: process.env.AWS_SECRET,
      s3Url: 'https://maxinbodyshop.s3.amazonaws.com'
    }

    const ReactS3Client = new S3(s3Config);

    const fileUpload = await ReactS3Client.uploadFile(hashFile, fileName)

    var data = JSON.stringify({
      "projectID": props.projectID,
      "hashLink": link,
      "action": "setHashList"
    });

    var config = {
      method: 'post',
      url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/setmutations',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      console.log(response)
      setUploadedhash(1)
      setHashLink(link)
    })
    .catch(function (error) {
      console.log(error);
    })
  }

  const getMetadata = async () => {
    setGettingMetadata(true)

    const connection = new Connection(process.env.QUICKNODE);
    const metaplex = new Metaplex(connection);


    let metadataDictTemp = {...metadataDict}
    let checkedHashesTemp = checkedHashes

    await Promise.all(hashArray.map( async(hash) => {
      if (!checkedHashesTemp?.includes(hash)){
        const mintAddress = new PublicKey(hash)
        try {
          let nft = await metaplex.nfts().findByMint({ mintAddress });
          if (nft?.jsonLoaded){
            checkedHashesTemp.push(hash)
            nft.json.attributes.map(attribute => {
              if (!Object.keys(metadataDictTemp).includes(attribute.trait_type)){
                metadataDictTemp[attribute.trait_type] = [attribute.value]
              }
              else{
                if (!metadataDictTemp[attribute.trait_type].includes(attribute.value)){
                    metadataDictTemp[attribute.trait_type].push(attribute.value)
                }
              }
            })
          }
        }
        catch(e){
          // console.error(e)
        }
      }
    }))

    console.log("done", metadataDictTemp, checkedHashesTemp)
    setGettingMetadata(false)
    setMetadataDict(metadataDictTemp)
    setCheckedHashes(checkedHashesTemp)
    if (hashArray.length === checkedHashesTemp.length){
      saveMetadataDict(metadataDictTemp)
    }
  }

  const saveMetadataDict = async (metadataDictTemp) => {
    console.log(metadataDictTemp)
    let fileName = "mutations/" + props.projectID.toString() + "/attributeDict/attributeDict.json"
    let link = "https://maxinbodyshop.s3.us-east-2.amazonaws.com/" + fileName

    const s3Config = {
      bucketName:"maxinbodyshop",
      region: "us-east-2",
      accessKeyId: process.env.AWS_KEY,
      secretAccessKey: process.env.AWS_SECRET,
      s3Url: 'https://maxinbodyshop.s3.amazonaws.com'
    }

    const ReactS3Client = new S3(s3Config);

    const jsonFile = JSON.stringify(metadataDictTemp)

    const fileUpload = await ReactS3Client.uploadFile(jsonFile, fileName)

    var data = JSON.stringify({
      "projectID": props.projectID,
      "attributeDictLink": link,
      "action": "setAttributeDict"
    });
    console.log(data)

    var config = {
      method: 'post',
      url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/setmutations',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      console.log(response)
      setUploadedAttributeDict(1)
      setAttributeDictLink(link)
    })
    .catch(function (error) {
      console.log(error);
    })
  }

  const editArtworkArray = (name) => {
    if (artworkArray.includes(name)){
      let index = artworkArray.indexOf(name)
      if (index > -1) {
        let artworkArrayTemp = [...artworkArray]
        artworkArrayTemp.splice(index, 1)
        setArtworkArray(artworkArrayTemp)
      }
    }
    else{
      setArtworkArray([...artworkArray, name])
    }
  }

  const uploadCategories = () => {
    var data = JSON.stringify({
      "projectID": props.projectID,
      "artworkArray": artworkArray,
      "action": "uploadCategories",
    });

    var config = {
      method: 'post',
      url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/setmutations',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      console.log(response)
      setCategoriesSet(1)
    })
    .catch(function (error) {
      console.log(error);
    })

  }

  const editAssetZip = async(e) => {
    setCheckingZip(true)
    setComparisonError()
    if (e.target.files) {
      const zipFile = e.target.files[0]

      const categoriesTemp = []
      const fileDictTemp = {}
      const fileNamesTemp = {}

      const zip = new JSZip()
      await zip.loadAsync(zipFile).then((files) => {
        Object.keys(files.files).map(key => {
          if (!key.includes("MACOS")){
            let file = files.files[key]
            if (!file.dir){
              let category = file.name.split("/")[0]
              let name = file.name.split("/")[1]
              if (!name.includes(".DS_Store")){
                if (Object.keys(fileDictTemp).includes(category)){
                  fileDictTemp[category].push(file)
                  fileNamesTemp[category].push(name.replaceAll(".png", ""))
                }
                else{
                  fileDictTemp[category] = [file]
                  fileNamesTemp[category] = [name.replaceAll(".png","")]
                }
              }
            }
          }
        })
        setZipDict(fileDictTemp)
        setZipNameDict(fileNamesTemp)
      })
    }
  }

  const uploadCollection = async() => {
    setUploadingCollection(true)
    const s3Config = {
      bucketName:"maxinbodyshop",
      region: "us-east-2",
      accessKeyId: process.env.AWS_KEY,
      secretAccessKey: process.env.AWS_SECRET,
      s3Url: 'https://maxinbodyshop.s3.amazonaws.com'
    }

    const ReactS3Client = new S3(s3Config);

    let categoryArray = Object.keys(zipDict)
    let x = 0

    let mapping = Object.keys(zipDict).map(async (category) => {
      setUploadCategory(categoryArray[x])
      let innerMapping = zipDict[category].map(async (file) => {
        let image = await file.async("blob")
        let fileName = "mutations/" + props.projectID + "/" + mutationName + "/" + file.name.replaceAll(" ", "_")
        const link = "https://maxinbodyshop.s3.us-east-2.amazonaws.com/" + fileName
        let traitName = file.name.split("/")[1].replaceAll(".png", "")
        let s3Response = await ReactS3Client.uploadFile(image, fileName)
        console.log(s3Response)

        var data = JSON.stringify({
          "projectID": props.projectID,
          "category": category,
          "traitName": traitName,
          "link": link,
          "collectionName": mutationName,
          "action": "addImage"
        });

        var config = {
          method: 'post',
          url: 'https://59200uzilj.execute-api.us-east-1.amazonaws.com/Production/uploadimages',
          headers: {
            'x-api-key': process.env.GATEWAY_KEY,
            'Content-Type': 'application/json'
          },
          data: data
        };

        return axios(config)
      })
      Promise.all(innerMapping).then(() => {
        x = x + 1
        setUploadCategory(categoryArray[x])
        categoryArray.pop()
        if (!categoryArray.length){
          finalizeAssetUpload()
        }
      })
    })

  }

  const finalizeAssetUpload = () => {
    var data = JSON.stringify({
      "projectID": props.projectID,
      "action": "finalizeAssetUpload"
    });

    var config = {
      method: 'post',
      url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/setmutations',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      console.log(response)
      setAssetsLoaded(1)
    })
    .catch(function (error) {
      console.log(error);
    })
  }

  const renderConditional = () => {
    console.log(Object.keys(metadataDict))
    if (conditionalState === "metadata") {
      return (
        <div>
          <select className="bg-gray-light border-2 border-gray-400 font-bold text-[18px] px-2 uppercase cursor-pointer text-grey-deep w-full h-12 leading-8" value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
            <option className="bg-gray-400 text-[16px] font-bold uppercase hover:bg-gray-deep cursor-pointer text-white hover:text-yellow-light px-2 py-[2px]">----------------</option>
            {Object.keys(metadataDict).map(category => (
                  <option value={category} className="bg-gray-400 text-[16px] font-bold uppercase hover:bg-gray-deep cursor-pointer text-white hover:text-yellow-light px-2 py-[2px]">{category}</option>
              ))}
          </select>
          {
            selectedCategory !== "---------------" ?
            <select className="bg-gray-light border-2 border-gray-400 font-bold text-[18px] px-2 uppercase cursor-pointer text-grey-deep w-full h-12 leading-8" value={selectedTrait} onChange={(e) => setSelectedTrait(e.target.value)}>
              <option className="bg-gray-400 text-[16px] font-bold uppercase hover:bg-gray-deep cursor-pointer text-white hover:text-yellow-light px-2 py-[2px]">----------------</option>
              {metadataDict[selectedCategory]?.map(trait => (
                    <option value={trait} className="bg-gray-400 text-[16px] font-bold uppercase hover:bg-gray-deep cursor-pointer text-white hover:text-yellow-light px-2 py-[2px]">{trait}</option>
                ))}
            </select>
            :
            <div>
            </div>
          }
          <button onClick={() => {saveMetadataRestriction()}} className="bg-red-light font-gilroy-bold text-white px-10 text-lg font-semibold py-1 rounded-md">
            Save
          </button>
        </div>
      )
    }

    else if (conditionalState === "nft"){
      return(
        <div>
          <div className='mb-5'>
              <p className='font-gilroy-bold text-xl text-gray-deep mt-5'>Upload a hashlist that a user needs to own in order to mutate!</p>
              <p className='text-gray-400 text-sm'>A user only needs to send one of these NFTs for mutation</p>
          </div>
          <div className='text-center ml-5'>
            <div>
              <input onChange={e => {setCurrentRestrictionHash(e.target.files[0])}} accept=".json" type="file" className="text-gray-deep font-bold" />
            </div>
          </div>
          <br></br>
          <button style={{marginBottom: 400}} onClick={() => {addRestrictionHash()}} className="bg-red-light font-gilroy-bold text-white px-10 text-lg font-semibold py-1 rounded-md">
            Submit!
          </button>
        </div>
      )
     }

    else if (conditionalState === "payment"){
      return (
        <div className='text-center'>
          <h3 className='font-gilroy-bold text-xl text-gray-deep'>Select the currency you want to charge in</h3>
          <p className='text-lg text-gray-400 font-medium'>you can sell the mutation in SOL or in any SPL-Token</p>
          <br></br>
          <div className='flex justify-between w-[60%] m-auto '>
            <div className='text-center'>
              <input className='font-gilroy-bold text-xl text-gray-deep' onChange={e => {setRadioCurrencyType(e.target.value)}} checked={radioCurrencyType === "solana"} type="radio" value="solana" name="solana" />
              <p className='text-lg text-gray-deep font-medium'>Sell in SOL</p>
            </div>
            <div className='text-center'>
              <input className='font-gilroy-bold text-xl text-gray-deep' onChange={e => {setRadioCurrencyType(e.target.value)}} checked={radioCurrencyType === "splToken"} type="radio" value="splToken" name="splToken" />
              <p className='text-lg text-gray-deep font-medium'>Sell in another SPL Token</p>
            </div>
          </div>
          <br></br>
          {
            radioCurrencyType ?
              radioCurrencyType !== "solana" ?
                <div>
                  <div className='text-center'>
                    <div>
                      <h3 className='font-gilroy-bold text-xl text-gray-deep'>SPL Hash</h3>
                      <p className='text-lg text-gray-400 font-medium'>please input the hash of the SPL-Token you want to sell this trait in</p>
                      <input name="purchasingCoin" type="text" onChange={e => {setSPLHash(e.target.value)}} className="text-gray-deep font-bold bg-gray-light w-[40%] border-2 border-gray-400 p-[1px] focus:outline-0 " autoComplete="off"/>
                    </div>
                  </div>
                </div>
                :
                <div>
                </div>
              :
              <div>
              </div>
          }

          <div className='text-center'>
            <div>
              <h3 className='font-gilroy-bold text-xl text-gray-deep'>Sales Price</h3>
              <p className='text-lg text-gray-400 font-medium'>the starting price for this trait NFT in your marketplace.</p>
              <p className='text-lg text-gray-400 font-medium'>please use whole numbers if you plan to sell traits in SPL tokens.</p>
              <input name="price" type="text" onChange={e => {setPrice(e.target.value)}} className="text-gray-deep font-bold bg-gray-light w-[40%] border-2 border-gray-400 p-[1px] focus:outline-0" autoComplete="off"/>
            </div>
          </div>
          <br></br>
          <button style={{marginBottom: 400}} onClick={() => {addPayment()}} className="bg-red-light font-gilroy-bold text-white px-10 text-lg font-semibold py-1 rounded-md">
            Save!
          </button>
        </div>
        )
    }
  }

  const saveMetadataRestriction = () => {
    let metadataRestrictionDictTemp = {...metadataRestrictionDict}

    if (Object.keys(metadataRestrictionDictTemp).includes(selectedCategory)){
      if (!metadataRestrictionDictTemp[selectedCategory].includes(selectedTrait)){
        metadataRestrictionDictTemp[selectedCategory].push(selectedTrait)
      }
    }
    else {
      metadataRestrictionDictTemp[selectedCategory] = [selectedTrait]
    }

    setMetadataRestrictionDict(metadataRestrictionDictTemp)
    setSelectedTrait()
    setSelectedCategory()
    setConditionalState("default")

    console.log(metadataRestrictionDictTemp)
  }

  const addRestrictionHash = () => {
    let nftRestrictionFileArrayTemp = nftRestrictionFileArray

    nftRestrictionFileArrayTemp.push(currentRestrictionHash)

    setNFTRestrictionFileArray(nftRestrictionFileArrayTemp)
    setCurrentRestrictionHash()
    setConditionalState("default")

    console.log(nftRestrictionFileArrayTemp)
  }

  const addPayment = () => {
    let paymentArrayTemp = paymentArray

    let tempDict = {"type": radioCurrencyType === "solana" ? "SOL" : "SPL", "hash": radioCurrencyType === "solana" ? "SOL" : splHash, "price": price}
    console.log(tempDict)

    paymentArrayTemp.push(tempDict)

    setPaymentArray(paymentArrayTemp)
    setPrice()
    setRadioCurrencyType()
    setSPLHash()

    setConditionalState("default")

    console.log(paymentArrayTemp)
  }

  const saveConditions = async () => {
    //start here -> need to iterate through them and create a table to deal with all the conditionals
    const s3Config = {
      bucketName:"maxinbodyshop",
      region: "us-east-2",
      accessKeyId: process.env.AWS_KEY,
      secretAccessKey: process.env.AWS_SECRET,
      s3Url: 'https://maxinbodyshop.s3.amazonaws.com'
    }

    const ReactS3Client = new S3(s3Config);
    let hashLinks = []

    await Promise.all(nftRestrictionFileArray.map( async file => {
      const fileName = "mutations/" + props.projectID + "/nftHashRestrictions/" + file.name.replaceAll(" ", "_")
      const link = "https://maxinbodyshop.s3.us-east-2.amazonaws.com/" + fileName
      let s3Response = await ReactS3Client.uploadFile(file, fileName)
      console.log(s3Response)
      hashLinks.push(link)
    }))

    console.log("uploadedHashes", hashLinks)

    var data = JSON.stringify({
      "projectID": props.projectID,
      "action": "setConditions",
      "hashLinks": hashLinks,
      "metadataRestrictionDict": metadataRestrictionDict,
      "paymentArray": paymentArray,
    });
    console.log(data)

    var config = {
      method: 'post',
      url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/setmutations',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      console.log(response)
      setConditionsSet(1)
    })
    .catch(function (error) {
      console.log(error);
    })



    // next step would be the metadata field we add to the upgrade and creating an upgrade row in the table

  }

  const finalizeOnboarding = () => {
    var data = JSON.stringify({
      "projectID": props.projectID,
      "action": "finalizeOnboarding",
    });

    var config = {
      method: 'post',
      url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/setmutations',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      console.log(response)
      setIsLive(1)
    })
    .catch(function (error) {
      console.log(error);
    })

  }

  const renderOnboardingStep = () => {
    if (!mutationName){
      return(
        <div>
          <div className='mb-5'>
              <p className='font-gilroy-bold text-xl text-gray-deep mt-5'>Set your mutation name below!</p>
          </div>
          <input onChange={(e) => setMutationNameTemp(e.target.value)} name="name" className='w-[30%] border border-gray-400 bg-gray-light focus:outline-none px-3 text-[16px] font-bold text-gray-deep' type='text'/>
          <br></br><br></br>
          <button style={{marginBottom: 400}} onClick={() => {saveMutationName()}} className="bg-red-light font-gilroy-bold text-white px-10 text-lg font-semibold py-1 rounded-md">
            Submit!
          </button>
        </div>
      )
    }

    else if (!uploadedHash){
      return(
        <div>
          <div className='mb-5'>
              <p className='font-gilroy-bold text-xl text-gray-deep mt-5'>Upload your hashlist here!</p>
              <p className='text-gray-400 text-sm'>This list should contain all NFTs that can be mutated</p>
              <p className='text-gray-400 text-sm'>Please don't include NFTs that will be burned during mutation</p>
          </div>
          <div className='text-center ml-5'>
            <div>
              <input onChange={e => {setHashFile(e.target.files[0])}} accept=".json" type="file" className="text-gray-deep font-bold" />
            </div>
          </div>
          <br></br>
          <button style={{marginBottom: 400}} onClick={() => {saveHash()}} className="bg-red-light font-gilroy-bold text-white px-10 text-lg font-semibold py-1 rounded-md">
            Submit!
          </button>
        </div>
      )
    }

    else if (!uploadedAttributeDict){
      if (hashArray.length){
        if (gettingMetadata){
          return(
            <div>
              <img className='max-w-[25px] mx-auto' src={Loader} alt="loading..."/>
              <div className='mb-5'>
                  <p className='font-gilroy-bold text-xl text-gray-deep mt-5'>Getting the metadata for your hashlist</p>
                  <p className='text-gray-400 text-sm'>This could take a while for bigger collections</p>
              </div>
            </div>
          )
        }
        else{
          if (checkedHashes.length){
            return (
              <div>
                <div className='mb-5'>
                    <p className='font-gilroy-bold text-xl text-gray-deep mt-5'>Not all of your NFTs were checked {checkedHashes.length}/{hashArray.length} c</p>
                    <p className='text-gray-400 text-sm'>Click below to continue the retrieval process</p>
                </div>
                <button style={{marginBottom: 400}} onClick={() => {getMetadata()}} className="bg-red-light font-gilroy-bold text-white px-10 text-lg font-semibold py-1 rounded-md">
                  Get Metadata!
                </button>
              </div>
            )
          }
          return (
            <div>
              <div className='mb-5'>
                  <p className='font-gilroy-bold text-xl text-gray-deep mt-5'>Your hash has been uploaded</p>
                  <p className='text-gray-400 text-sm'>Click below to retrieve the NFT metadata for your hashlist</p>
              </div>
              <button style={{marginBottom: 400}} onClick={() => {getMetadata()}} className="bg-red-light font-gilroy-bold text-white px-10 text-lg font-semibold py-1 rounded-md">
                Get Metadata!
              </button>
            </div>
          )
        }
      }
    }

    else if (!categoriesSet){
      return (
        <div>
          <div>
            <p className='font-gilroy-bold text-xl text-gray-deep mt-5'>Please select the categories that will be a part of your mutation</p>
            <p className='text-gray-400 text-sm'>You will need to upload artwork to match each trait in these categories</p>
            {
              Object.keys(metadataDict).map(category => (
                <div>
                  <p className='text-lg text-gray-400 font-medium'>{category}</p>
                  <input className='font-gilroy-bold text-xl text-gray-deep' onClick={() => {editArtworkArray(category)}} checked={artworkArray.includes(category)} type="radio" value="true" name={category} />
                </div>
              ))
            }
          </div>
          <button style={{marginBottom: 400}} onClick={() => {uploadCategories()}} className="bg-red-light font-gilroy-bold text-white px-10 text-lg font-semibold py-1 rounded-md">
            Submit!
          </button>
        </div>
      )
    }

    else if (!assetsLoaded){
      return (
        <div>
          {
            uploadingCollection ?
              <div className='mb-5'>
                  <p className='font-gilroy-bold text-xl text-gray-deep mt-5'>We are uploading your collection assets to an AWS S3 bucket for easy access</p>
                  <p className='text-gray-400 text-sm'>This could take a bit - give us a second</p>
                  <br></br>
                  <img className='max-w-[25px] mx-auto' src={Loader} alt="loading..."/>
              </div>
              :
              <div>
                <div className='mb-5'>
                    <p className='font-gilroy-bold text-xl text-gray-deep mt-5'>Its time to upload your mutation artwork assets (in .zip format)</p>
                    <p className='text-gray-400 text-sm'>These are the individual traits that make up your mutation collection - this must match the metadata names from your NFTs and must be pngs</p>
                    <p className='text-gray-400 text-sm'>If you have any doubts, download the example file below</p>
                </div>
                <div className='text-center ml-5'>
                  {
                    checkingZip ?
                      <div>
                        <img className='max-w-[25px] mx-auto' src={Loader} alt="loading..."/>
                        <p className='font-gilroy-bold text-xl text-gray-deep mt-5'>We are checking your uploaded zip against your metadata to make sure everything is in order</p>
                      </div>
                      :
                      errorArray.length ?
                        <div>
                          <p className='font-gilroy-bold text-xl text-gray-deep mt-5'>We found these errors in your zip file - please correct them and try the upload again</p>
                          {
                            errorArray.map(error => {
                              return (
                                <p className='text-gray-400 text-sm'>{error}</p>
                              )
                            })
                          }
                          <input onChange={e => {editAssetZip(e)}} accept=".zip" type="file" className="text-gray-deep font-bold" />
                        </div>
                        :
                        <div>
                          <input onChange={e => {editAssetZip(e)}} accept=".zip" type="file" className="text-gray-deep font-bold" />
                        </div>
                  }
                </div>
                <br></br><br></br>
                <div>
                  <p className='font-gilroy-bold text-xl text-gray-deep mt-5'>You can download this sample file for reference</p>
                  <button className="bg-black text-white text-lg px-7 font-gilroy-bold py-1 mt-5 rounded-md">
                    <a href={"https://maxinbodyshop.s3.us-east-2.amazonaws.com/sampleCollection.zip"}>
                      Download
                    </a>
                  </button>
                </div>
              </div>
          }
        </div>
      )
    }

    else if (!conditionsSet){
      return (
        <div>
          <div>
            <p className='font-gilroy-bold text-xl text-gray-deep mt-5'>You will now set the conditions on how a user can mutate their NFT</p>
            <p className='text-gray-400 text-sm'>You can add payment fees, NFT requirements, or specific metadata restrictions</p>
            <br></br><br></br>
              <div>
                <button onClick={() => {setConditionalState("metadata")}} className="bg-red-light font-gilroy-bold text-white px-10 text-lg font-semibold py-1 rounded-md">
                  Metadata Restriction
                </button>
                <p className='text-gray-400 text-sm'>Use this to restrict mutations to NFTs with a particular metadata field - if an NFT contains any of the added metadata fields they will be eligble</p>
              </div>

              <div>
                <button onClick={() => {setConditionalState("nft")}} className="bg-red-light font-gilroy-bold text-white px-10 text-lg font-semibold py-1 rounded-md">
                  NFT Requirements
                </button>
                <p className='text-gray-400 text-sm'>Users will have to burn this particular NFT in order to qualify for an upgrade - if multiple hashlists are uploaded, users need to have at least one from each list to be eligble</p>
              </div>

              <div>
                <button onClick={() => {setConditionalState("payment")}} className="bg-red-light font-gilroy-bold text-white px-10 text-lg font-semibold py-1 rounded-md">
                  Add Payment!
                </button>
                <p className='text-gray-400 text-sm'>You can add a cost (in SOL and/or in a SPL token of your choosing)</p>
              </div>
            </div>

          {
            conditionalState !== "default" ?
            renderConditional()
            :
            <button style={{marginBottom: 400}} onClick={() => {saveConditions()}} className="bg-red-light font-gilroy-bold text-white px-10 text-lg font-semibold py-1 rounded-md">
              Submit!
            </button>
          }
        </div>
      )
    }

    else if (!isLive) {
      return (
          <div>
            <div className='mb-5'>
                <p className='font-gilroy-bold text-xl text-gray-deep mt-5'>You have completed the onboarding! Push your mutation live by clicking below!</p>
            </div>
            <br></br>
            <button style={{marginBottom: 400}} onClick={() => {finalizeOnboarding()}} className="bg-red-light font-gilroy-bold text-white px-10 text-lg font-semibold py-1 rounded-md">
              Finalize!
            </button>
          </div>
        )
    }

  }


  return (
    <div>
      {
        hasMutation ?
          isLive ?
          <div>
            <MutationDash setNewPage={props.setNewPage}/>
          </div>
          :
          <div>
            {renderOnboardingStep()}
          </div>
        :
        <div className='mb-5'>
          <p className='font-gilroy-bold text-xl text-gray-deep mt-5'>You do not have a live mutation.</p>
          <p className='text-gray-400 text-lg'>If you would like to start a new mutation click below!</p>
          <br></br>
          <button onClick={() => {setHasMutation(true)}} className="bg-red-deep text-white py-2 w-40 font-bold text-lg text-center rounded-md">Create a Mutation</button>
        </div>
      }
    </div>
  )

};

export default MutationOnboarding;
