import React, {useState, useEffect} from "react";
import AdminDash from "./AdminDash"
import BuilderHome from "./BuilderHome"
import TraitShopHome from "./TraitShopHome"
import UpgradeApprovals from "./UpgradeApprovals"
import SlotMachineHome from './SlotMachineHome'
import AddSMTraits from './AddSMTraits'
import AddSMTraitsTwo from './AddSMTraitsTwo'
import CreatedSlotTraits from './CreatedSlotTraits'

import TraitHeader from '../Components/TraitHeader'
import BuilderHeader from '../Components/BuilderHeader'
import UpgradeHeader from '../Components/UpgradeHeader'
import MutationsHeader from '../Components/MutationsHeader'
import SlotHeader from '../Components/SlotHeader'
import AstralHeader from '../Components/AstralHeader'

import IndividualPendingNFT from './IndividualPendingNFT'
import PendingNFTs from './PendingNFTs'

import MutationOnboarding from "./MutationOnboarding"
import MutationImageMapping from './MutationImageMapping'

import IndividualLiveTrait from './IndividualLiveTrait'
import CreatedTraits from './CreatedTraits'

import AddTrait from './AddTrait'
import Onboarding from './Onboarding'

import EditBuilderConfig from './EditBuilderConfig'
import EditCategoryConflicts from './EditCategoryConflicts'
import EditBlockedTraits from './EditBlockedTraits'
import Mapping from './Mapping'
import EditTraitLayering from './EditTraitLayering'

const WelcomePage = (props) => {
  const [page, setPage] = useState('WelcomePage')
  const [innerPage, setInnerPage] = useState('default')

  console.log(props)

  const [currentProject, setCurrentProject] = useState(Object.keys(props.projectDict)[0])

  const [hasMutation, setHasMutation] = useState(props.projectDict[Object.keys(props.projectDict)[0]].hasMutation)
  const [hasSlotMachine, setHasSlotMachine] = useState(props.projectDict[Object.keys(props.projectDict)[0]].hasSlotMachine)
  const [slotMachineReady, setSlotMachineReady] = useState(props.projectDict[Object.keys(props.projectDict)[0]].slotMachineReady)

  const [selectedCreatedTrait, setSelectedCreatedTrait] = useState()
  const [selectedPendingTrait, setSelectedPendingTrait] = useState()

  const [selectedCreatedSlotTrait, setSelectedCreatedSlotTrait] = useState()

  const [configPage, setConfigPage] = useState()

  const changeProject = (projectID) => {
    setHasMutation(props.projectDict[projectID].hasMutation)
    setHasSlotMachine(props.projectDict[projectID].hasSlotMachine)
    setSlotMachineReady(props.projectDict[projectID].slotMachineReady)
    setCurrentProject(projectID)
    setPage('WelcomePage')
  }

  const setCreatedTrait = (traitID) => {
    setSelectedCreatedTrait(traitID)
    setInnerPage('createdTraits')
  }

  const setCreatedSlotTrait = (traitID) => {
    setSelectedCreatedSlotTrait(traitID)
    setInnerPage('createdSlotTraits')
  }

  const setPendingTrait = (identifyingHash) => {
    setSelectedPendingTrait(identifyingHash)
    setInnerPage('pendingTraits')
  }

  const resetTrait = (innerPage) => {
    if (innerPage === "pending"){
      setInnerPage('pendingTraits')
    }
    else if (innerPage === "created"){
      setInnerPage('createdTraits')
    }
    else if (innerPage === "createdSlot"){
      setInnerPage('createdSlotTraits')
    }
    setSelectedPendingTrait()
    setSelectedCreatedTrait()
    setSelectedCreatedSlotTrait()
  }

  const resetConfig = () => {
    setInnerPage('config')
    setConfigPage('default')
  }

  const setNewPage = (pageToSet) => {
    if (pageToSet === "builder"){
      setSelectedPendingTrait()
      setSelectedCreatedTrait()
      setInnerPage('default')
      setPage("builder")
    }
    else if (pageToSet === "traitShop"){
      setSelectedPendingTrait()
      setSelectedCreatedTrait()
      setInnerPage('default')
      setPage("traitShop")
    }
    else if (pageToSet === "upgrades"){
      setSelectedPendingTrait()
      setSelectedCreatedTrait()
      setInnerPage('default')
      setPage("upgrades")
    }
    else if (pageToSet === "mutations"){
      setSelectedPendingTrait()
      setSelectedCreatedTrait()
      setInnerPage('default')
      setPage("mutations")
    }
    else if (pageToSet === "slotMachine"){
      setSelectedCreatedSlotTrait()
      setInnerPage('default')
      setPage("slotMachine")
    }
  }

  const renderPage = () => {
    if (page === "WelcomePage"){
      if (parseInt(currentProject) === 52){
        setPage('traitShop')
      }
      if (props.projectDict[currentProject]?.completedOnboarding){
        return (
              <div className='bg-gray-light py-32 w-full'>
                  <div className='container mx-auto px-5'>
                      <div className='text-center max-w-md px-10 py-5 mx-auto bg-gray-deep'>
                          <p className='text-lg text-yellow-light uppercase font-semibold mb-5'>Welcome to the Body Shop Dashboard
                          </p>
                          <p className='text-lg text-yellow-light mb-10'>Here you will be able to create new traits and view/edit live traits</p>
                          <button className='block w-full bg-yellow-light px-5 py-1 text-lg text-white uppercase mb-5' onClick={() => {setPage("traitShop")}}>Trait Shop</button>
                          <button className='block w-full bg-rose-light px-5 py-1 text-lg text-white uppercase mb-5' onClick={() => {setPage("builder")}}>Collection Builder</button>
                          <button className='block w-full bg-yellow-deep px-5 py-1 text-lg text-white uppercase  mb-5' onClick={() => {setPage("upgrades")}}>Upgrade Approvals</button>
                          {!hasMutation ? <div></div> : <button className='block w-full bg-red-light px-5 py-1 text-lg text-white uppercase mb-5' onClick={() => {setPage("mutations")}}>Mutations</button>}
                          {!hasSlotMachine ? <div></div> : <button className='block w-full bg-red-deep px-5 py-1 text-lg text-white uppercase' onClick={() => {setNewPage("slotMachine")}}>Slot Machine</button>}
                      </div>
                  </div>
              </div>
          )
      }
      else{
        return (
              <div className='bg-gray-light py-32 w-full'>
                  <div className='container mx-auto px-5'>
                      <div className='text-center max-w-md px-10 py-5 mx-auto bg-gray-deep'>
                          <p className='text-lg text-yellow-light uppercase font-semibold mb-5'>Welcome to the Body Shop Dashboard
                          </p>
                          <p className='text-lg text-yellow-light mb-10'>Seems like you haven't gone through an onboarding... Let's get started!</p>
                          <button className='block w-full bg-red-light px-5 py-1 text-lg text-white uppercase' onClick={() => {setPage("onboarding")}}>Start Onboarding</button>
                      </div>
                  </div>
              </div>
          )
      }

  }
    else if (page === "traitShop"){
      if (innerPage === "default"){
        // if (parseInt(currentProject) === 52){
        //   return (
        //     <section className='w-[94%] relative h-full bg-gray-light ml-auto py-16'>
        //       <AstralHeader setInnerPage={setInnerPage} selectedPage={innerPage} resetTrait={resetTrait} setNewPage={setNewPage} hasMutation={hasMutation} hasSlotMachine={hasSlotMachine}/>
        //       <CreatedTraits projectID={currentProject} setInnerPage={setInnerPage} setCreatedTrait={setCreatedTrait} page={page}/>
        //     </section>
        //   )
        // }
        return (
          <section className='w-[94%] relative h-full bg-gray-light ml-auto py-16 mb-16'>
            <TraitHeader setInnerPage={setInnerPage} selectedPage={innerPage} resetTrait={resetTrait} setNewPage={setNewPage} hasMutation={hasMutation} hasSlotMachine={hasSlotMachine} slotMachineReady={slotMachineReady}/>
            <TraitShopHome setPage={page} projectID={currentProject} setCreatedTrait={setCreatedTrait} setPendingTrait={setPendingTrait} setInnerPage={setInnerPage} page={page}/>
          </section>
        )
      }
      else if (innerPage === "addTraits"){
        return (
          <section className='w-[94%] h-full bg-gray-light ml-auto py-[70px] mb-24 relative'>
            <TraitHeader setInnerPage={setInnerPage} selectedPage={innerPage} resetTrait={resetTrait} setNewPage={setNewPage} hasMutation={hasMutation} hasSlotMachine={hasSlotMachine} slotMachineReady={slotMachineReady}/>
            <AddTrait projectID={currentProject} creatorWallet={props.projectDict[currentProject].creatorWallet} setInnerPage={setInnerPage} setPendingTrait={setPendingTrait} hasTraitSwaps={props.projectDict[currentProject].swapTraits}/>
          </section>
        )
      }
      else if (innerPage === "pendingTraits"){
        if (selectedPendingTrait){
          return (
            <section className='w-[94%] relative h-full bg-gray-light ml-auto py-16'>
              <TraitHeader setInnerPage={setInnerPage} selectedPage={innerPage} resetTrait={resetTrait} setNewPage={setNewPage} hasMutation={hasMutation} hasSlotMachine={hasSlotMachine} slotMachineReady={slotMachineReady}/>
              <IndividualPendingNFT projectID={currentProject} identifyingHash={selectedPendingTrait} resetTrait={resetTrait} setCreatedTrait={setCreatedTrait} collectionHash={props.projectDict[currentProject].collectionHash}/>
            </section>
          )
        }
        else{
          return (
            <section className='w-[94%] relative h-full bg-gray-light ml-auto py-16'>
              <TraitHeader setInnerPage={setInnerPage} selectedPage={innerPage} resetTrait={resetTrait} setNewPage={setNewPage} hasMutation={hasMutation} hasSlotMachine={hasSlotMachine} slotMachineReady={slotMachineReady}/>
              <PendingNFTs projectID={currentProject} setInnerPage={setInnerPage} setPendingTrait={setPendingTrait} page={page}/>
            </section>
          )
        }
      }
      else if (innerPage === "createdTraits"){
        if (selectedCreatedTrait){
          return (
            <section className='w-[94%] relative h-full bg-gray-light ml-auto py-16'>
              <TraitHeader setInnerPage={setInnerPage} selectedPage={innerPage} resetTrait={resetTrait} setNewPage={setNewPage} hasMutation={hasMutation} hasSlotMachine={hasSlotMachine}/>
              <IndividualLiveTrait projectID={currentProject} setCreatedTrait={setCreatedTrait} traitID={selectedCreatedTrait} resetTrait={resetTrait}/>
            </section>
          )
        }
        else{
          return (
            <section className='w-[94%] relative h-full bg-gray-light ml-auto py-16'>
              <TraitHeader setInnerPage={setInnerPage} selectedPage={innerPage} resetTrait={resetTrait} setNewPage={setNewPage} hasMutation={hasMutation} hasSlotMachine={hasSlotMachine}/>
              <CreatedTraits projectID={currentProject} setInnerPage={setInnerPage} setCreatedTrait={setCreatedTrait} page={page}/>
            </section>
          )
        }
      }

    }
    else if (page === "builder"){
      console.log(page, innerPage)
      if (innerPage === "default"){
        return (
          <section className='w-[94%] h-full bg-gray-light ml-auto relative py-16 z-index-1'>
            <BuilderHeader selectedPage={innerPage} setNewPage={setNewPage} setInnerPage={setInnerPage} resetConfig={resetConfig} hasMutation={hasMutation} hasSlotMachine={hasSlotMachine}/>
            <BuilderHome projectID={currentProject} />
          </section>
        )
      }
      else if (innerPage === "config"){
        if (configPage === "layerOrdering"){
          return (
            <section className='w-[94%] h-full bg-gray-light ml-auto relative py-16 z-index-1'>
              <BuilderHeader selectedPage={innerPage} setNewPage={setNewPage} setInnerPage={setInnerPage} resetConfig={resetConfig} hasMutation={hasMutation} hasSlotMachine={hasSlotMachine}/>
              <EditTraitLayering projectID={currentProject}/>
            </section>
          )
        }
        else if (configPage === "conflicts"){
          return (
            <section className='w-[94%] h-full bg-gray-light ml-auto relative py-16 z-index-1'>
              <BuilderHeader selectedPage={innerPage} setNewPage={setNewPage} setInnerPage={setInnerPage} resetConfig={resetConfig} hasMutation={hasMutation} hasSlotMachine={hasSlotMachine}/>
              <EditCategoryConflicts projectID={currentProject}/>
            </section>
          )
        }
        else if (configPage === "blockers"){
          return (
            <section className='w-[94%] h-full bg-gray-light ml-auto relative py-16 z-index-1'>
              <BuilderHeader selectedPage={innerPage} setNewPage={setNewPage} setInnerPage={setInnerPage} resetConfig={resetConfig} hasMutation={hasMutation} hasSlotMachine={hasSlotMachine}/>
              <EditBlockedTraits projectID={currentProject}/>
            </section>
          )
        }
        else {
          return (
            <section className='w-[94%] h-full bg-gray-light ml-auto relative py-16 z-index-1'>
              <BuilderHeader selectedPage={innerPage} setNewPage={setNewPage} setInnerPage={setInnerPage} resetConfig={resetConfig} hasMutation={hasMutation} hasSlotMachine={hasSlotMachine}/>
              <EditBuilderConfig projectID={currentProject} setConfigPage={setConfigPage}/>
            </section>
          )
        }
      }
    }
    else if (page === "upgrades"){
      return (
        <section className='w-[94%] h-full bg-gray-light ml-auto relative py-16 z-index-1'>
          <UpgradeHeader setNewPage={setNewPage} hasMutation={hasMutation} hasSlotMachine={hasSlotMachine}/>
          <UpgradeApprovals setPage={setPage} projectID={currentProject} setNewPage={setNewPage}/>
        </section>
      )
    }
    else if (page === "onboarding"){
      return (
        <section className='w-[94%] h-full bg-gray-light ml-auto relative py-16 z-index-1'>
          <Onboarding projectID={currentProject} setNewPage={setNewPage}/>
        </section>
      )
    }
    else if (page === "mutations"){
      return (
        <section className='w-[94%] h-full bg-gray-light ml-auto relative py-16 z-index-1'>
          <MutationsHeader setNewPage={setNewPage} hasMutation={hasMutation} hasSlotMachine={hasSlotMachine}/>
          <MutationOnboarding setPage={setPage} projectID={currentProject} setNewPage={setNewPage} setMutation={setHasMutation}/>
        </section>
      )
    }
    else if (page === "slotMachine"){
      if (innerPage === "default"){
        return (
          <section className='w-[94%] h-full bg-gray-light ml-auto relative py-16 z-index-1'>
            <SlotHeader setNewPage={setNewPage} selectedPage={innerPage}  hasMutation={hasMutation} hasSlotMachine={hasSlotMachine} slotMachineReady={slotMachineReady} setInnerPage={setInnerPage} resetTrait={resetTrait} projectID={currentProject}/>
            <SlotMachineHome setPage={setPage} resetTrait={resetTrait} projectID={currentProject} setCreatedSlotTrait={setCreatedSlotTrait} creatorWallet={props.projectDict[currentProject].creatorWallet} setInnerPage={setInnerPage} setPendingTrait={setPendingTrait} hasTraitSwaps={props.projectDict[currentProject].swapTraits} slotMachineReady={slotMachineReady} projectName = {props.projectDict[currentProject].projectName} page={page}/>
          </section>
        )
      }
      else if (innerPage === "addSlotTraits"){
        return (
          <section className='w-[94%] h-full bg-gray-light ml-auto relative py-16 z-index-1'>
            <SlotHeader setNewPage={setNewPage} selectedPage={innerPage} hasMutation={hasMutation} hasSlotMachine={hasSlotMachine} slotMachineReady={slotMachineReady} setInnerPage={setInnerPage} resetTrait={resetTrait} projectID={currentProject}/>
            {
              currentProject == 40 ?
              <AddSMTraits setPage={setPage} resetTrait={resetTrait} projectID={currentProject} creatorWallet={props.projectDict[currentProject].creatorWallet} setInnerPage={setInnerPage} setPendingTrait={setPendingTrait} hasTraitSwaps={props.projectDict[currentProject].swapTraits} slotMachineReady={slotMachineReady} setCreatedSlotTrait={setCreatedSlotTrait} projectName = {props.projectDict[currentProject].projectName}/>
              :
              <AddSMTraitsTwo setPage={setPage} resetTrait={resetTrait} projectID={currentProject} creatorWallet={props.projectDict[currentProject].creatorWallet} setInnerPage={setInnerPage} setPendingTrait={setPendingTrait} hasTraitSwaps={props.projectDict[currentProject].swapTraits} slotMachineReady={slotMachineReady} setCreatedSlotTrait={setCreatedSlotTrait} projectName = {props.projectDict[currentProject].projectName}/>
            }

          </section>
        )
      }
      else if (innerPage === "pendingTraits"){
        if (selectedPendingTrait){
          return (
            <section className='w-[94%] relative h-full bg-gray-light ml-auto py-16'>
              <SlotHeader setNewPage={setNewPage} selectedPage={innerPage} hasMutation={hasMutation} hasSlotMachine={hasSlotMachine} slotMachineReady={slotMachineReady} setInnerPage={setInnerPage} resetTrait={resetTrait} projectID={currentProject}/>
              <IndividualPendingNFT projectID={currentProject} identifyingHash={selectedPendingTrait} resetTrait={resetTrait} setCreatedTrait={setCreatedTrait} collectionHash={props.projectDict[currentProject].collectionHash}/>
            </section>
          )
        }
        else{
          return (
            <section className='w-[94%] relative h-full bg-gray-light ml-auto py-16'>
              <SlotHeader setNewPage={setNewPage} selectedPage={innerPage} hasMutation={hasMutation} hasSlotMachine={hasSlotMachine} slotMachineReady={slotMachineReady} setInnerPage={setInnerPage} resetTrait={resetTrait} projectID={currentProject}/>
              <PendingNFTs projectID={currentProject} setInnerPage={setInnerPage} setPendingTrait={setPendingTrait} page={page}/>
            </section>
          )
        }
      }
      else if (innerPage === "createdTraits"){
        if (selectedCreatedTrait){
          return (
            <section className='w-[94%] relative h-full bg-gray-light ml-auto py-16'>
              <SlotHeader setNewPage={setNewPage} selectedPage={innerPage} hasMutation={hasMutation} hasSlotMachine={hasSlotMachine} slotMachineReady={slotMachineReady} setInnerPage={setInnerPage} resetTrait={resetTrait} projectID={currentProject}/>
              <IndividualLiveTrait projectID={currentProject} setCreatedTrait={setCreatedTrait} traitID={selectedCreatedTrait} resetTrait={resetTrait}/>
            </section>
          )
        }
        else{
          return (
            <section className='w-[94%] relative h-full bg-gray-light ml-auto py-16'>
              <SlotHeader setNewPage={setNewPage} selectedPage={innerPage} hasMutation={hasMutation} hasSlotMachine={hasSlotMachine} slotMachineReady={slotMachineReady} setInnerPage={setInnerPage} resetTrait={resetTrait} projectID={currentProject}/>
              <CreatedTraits projectID={currentProject} setInnerPage={setInnerPage} setCreatedTrait={setCreatedTrait} page={page}/>
            </section>
          )
        }
      }
      else if (innerPage === "createdSlotTraits"){
        return (
          <section className='w-[94%] h-full bg-gray-light ml-auto relative py-16 z-index-1'>
            <SlotHeader setNewPage={setNewPage} selectedPage={innerPage} hasMutation={hasMutation} hasSlotMachine={hasSlotMachine} slotMachineReady={slotMachineReady} setInnerPage={setInnerPage} resetTrait={resetTrait} projectID={currentProject}/>
            <CreatedSlotTraits projectID={currentProject} setCreatedSlotTrait={setCreatedSlotTrait} resetTrait={resetTrait} projectName = {props.projectDict[currentProject].projectName}/>
          </section>
        )

      }
    }
  }

  return (
    <div>
      <select className="absolute left-2 top-2 bg-gray-light border-2 border-gray-400 font-bold text-[18px] px-2 uppercase cursor-pointer text-grey-deep w-[30%] h-12 leading-8" value={currentProject} onChange={(e) => changeProject(e.target.value)}>
          {Object.keys(props.projectDict).map((projectID) => (
              <option value={projectID} className="bg-gray-400 text-[16px] font-bold uppercase hover:bg-gray-deep cursor-pointer text-white hover:text-yellow-light px-2 py-[2px]">{props.projectDict[projectID].projectName}</option>
          ))}
      </select>
      {renderPage()}
    </div>
  );

}

export default WelcomePage
