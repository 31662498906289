import React, {useState, useEffect} from "react";
import Arweave from 'arweave';
import fs from 'fs';
import IndividualPendingNFT from "./IndividualPendingNFT";
import axios from "axios";
import { uploadFile } from 'react-s3';
import S3 from 'react-aws-s3';
import Loader from "../assets/img/loading.gif"
import CustomBtn from '../Components/CustomBtn'
import Header from '../Components/Header'
import TraitHeader from '../Components/TraitHeader'
import TraitThumbnail from '../Components/TraitThumbnail';
import thumbnailImg from '../assets/img/thumbnail.png';
import PendingNFTs from './PendingNFTs'
import CreatedSlotTraits from './CreatedSlotTraits'
import IndividualLiveTrait from "./IndividualLiveTrait"
window.Buffer = window.Buffer || require("buffer").Buffer;

const SlotMachineHome = (props) => {
  // console.log(props)
  const [page, setPage] = useState("slotMachineHome")
  const [createdNFTs, setCreatedNFTs] = useState([])
  const [filterName, setFilterName] = useState("")
  const [filterID, setFilterID] = useState("")
  const [selectedHash, setSelectedHash] = useState(null)
  const [rollPrice, setRollPrice] = useState()
  const [secondRollPrice, setSecondRollPrice] = useState()
  const [secondPurchasingCoin, setSecondPurchasingCoin] = useState()
  const [reRollAttempts, setReRollAttempts] = useState()
  const [tokenName, setTokenName] = useState()
  const [secondTokenName, setSecondTokenName] = useState()
  const [slotMachineSubmitReady, setSlotMachineSubmitReady] = useState(false)
  const [radioCurrencyType, setRadioCurrencyType] = useState()
  const [secondRadioCurrencyType, setSecondRadioCurrencyType] = useState()
  const [purchasingCoin, setPurchasingCoin] = useState()
  const [editingSlot, setEditingSlot] = useState(false)
  const [popupState, setPopupState] = useState()
  const [popup, setPopup] = useState(false)
  const [hasSecondCurrency, setHasSecondCurrency] = useState(false)

  useEffect(() => {
    console.log(props.slotMachineReady, editingSlot)
    if (props.slotMachineReady && !editingSlot){
      var data = JSON.stringify({
        "action": "pullSlotMachineData",
        "projectID": props.projectID
      });

      var config = {
        method: 'post',
        url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/setnewnfts',
        headers: {
          'x-api-key': process.env.GATEWAY_KEY,
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
      .then(function (response) {
        console.log(response)
        setRollPrice(response.data.rollPrice)
        setReRollAttempts(response.data.reRollAttempts)
        if (response.data.purchasingCoin){
          setPurchasingCoin(response.data.purchasingCoin)
          setTokenName(response.data.tokenName)
          setRadioCurrencyType("SPLToken")
        } else {
          setRadioCurrencyType("solana")
        }
        if (response.data.secondRollPrice){
          setSecondRollPrice(response.data.secondRollPrice)
        }
        if (response.data.secondPurchasingCoin){
          setSecondPurchasingCoin(response.data.secondPurchasingCoin)
          setSecondTokenName(response.data.secondTokenName)
          setSecondRadioCurrencyType("SPLTokensecond")
        } else {
          setSecondRadioCurrencyType("solanasecond")
        }
      })
      .catch(function (error) {
        console.log(error);
      })
    }
  }, [editingSlot]);

  const editSlotMachine = async () => {
    var data = JSON.stringify({
      "projectID": props.projectID,
      "onboarded": props.slotMachineReady,
      "rollPrice": rollPrice,
      "tokenName": tokenName,
      "purchasingCoin": purchasingCoin,
      "hasSecondCurrency": hasSecondCurrency,
      "secondRollPrice": secondRollPrice ? secondRollPrice : 0,
      "secondTokenName": secondTokenName ? secondTokenName : 0,
      "secondPurchasingCoin": secondPurchasingCoin ? secondPurchasingCoin : 0,
      "reRollAttempts": 0,
      "action": 'editSlotMachine'
    });
    console.log(data)

    var config = {
      method: 'post',
      url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/setnewnfts',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      console.log(response)
      setEditingSlot(false)
    })
    .catch(function (error) {
      console.log(error);
    })
  }

  const onChange = (e) => {
    setSlotMachineSubmitReady(false)
    if (e.target.name === "rollPrice"){
      setRollPrice(e.target.value)
    }
    else if (e.target.name === "reRollAttempts"){
      setReRollAttempts(e.target.value)
    }
    else if (e.target.name === "purchasingCoin"){
      setPurchasingCoin(e.target.value)
    }
    else if (e.target.name === 'tokenName'){
      setTokenName(e.target.value)
    }
    else if (e.target.name === "solana" || e.target.name === "splToken"){
      if (e.target.name === "solana"){
        setPurchasingCoin('XXX')
        setTokenName('SOL')
      }
      setRadioCurrencyType(e.target.name)
    }
    if (e.target.name === "secondRollPrice"){
      // console.log(e.target.value)
      setSecondRollPrice(e.target.value)
    }
    else if (e.target.name === "secondPurchasingCoin"){
      // console.log(e.target.value)
      setSecondPurchasingCoin(e.target.value)
    }
    else if (e.target.name === 'secondtokenName'){
      // console.log(e.target.value)
      setSecondTokenName(e.target.value)
    }
    else if (e.target.name === "secondsolana" || e.target.name === "secondsplToken"){
      console.log(e.target.name)
      if (e.target.name === "secondsolana"){
        setSecondPurchasingCoin('XXX')
        setSecondTokenName('SOL')
      }
      setSecondRadioCurrencyType(e.target.name)
    }
  }

  const checkData = () => {
    console.log('heree first')
    if (rollPrice && radioCurrencyType){
      if (radioCurrencyType === "solana" || (rollPrice%1 == 0 && purchasingCoin && tokenName)){
        console.log('got here')
        setSlotMachineSubmitReady(true)
      }
      else{
        setPopupState("currencyIssue")
        setPopup(true)
        console.log('got here bitch')
      }
    }
    else{
      setPopupState("formIssue")
      setPopup(true)
      console.log('got here mfer')
    }
  }

  const reEditSlot = async () => {
    setRollPrice()
    setReRollAttempts()
    setTokenName()
    setRadioCurrencyType()
    setPurchasingCoin()
    setEditingSlot(true)
    setSecondRollPrice()
    setSecondPurchasingCoin()
    setSecondTokenName()
  }

  const headBack = async () => {
    setSlotMachineSubmitReady(false)
    setEditingSlot(false)
    setHasSecondCurrency(false)
  }

  useEffect(() => {
    if (props.projectID == 40){
      var data = JSON.stringify({
        "action": "getSlotTraits",
        "traitType": "alltraits",
        "projectID": props.projectID
      });

      var config = {
        method: 'post',
        url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/getnfts',
        headers: {
          'x-api-key': process.env.GATEWAY_KEY,
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
      .then(function (response) {
        console.log(response)
        if (response.data.data.length !== 0){
          var createdNFTs = []
          response.data.data.forEach(trait => {
            createdNFTs.push({
                id: trait.id,
                name: trait.traitName,
                traitCategory: trait.traitCategory,
                rollOdds: trait.rollOdds,
                supply: trait.supply,
                imageLink: trait.imageLink,
                collectionName: trait.collectionName,
                identifyingHash: trait.identifyingHash,
                live: trait.live,
                soldOut: trait.soldOut,
                amountPurchased: trait.amountPurchased
              })
          })
        }
        setCreatedNFTs(createdNFTs)
      })
      .catch(function (error) {
        console.log(error);
      })

    }
    else {
      var data = JSON.stringify({
        "traitType": "alltraits",
        "projectID": props.projectID
      });

      var config = {
        method: 'post',
        url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/gettraits',
        headers: {
          'x-api-key': process.env.GATEWAY_KEY,
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
      .then(function (response) {
        console.log(response)
        if (response.data.data.length !== 0){
          var createdNFTs = []
          if (props.page === 'traitShop'){
            var newArray = response.data.data.filter((nft) =>
                nft.isSlotTrait !== 1
            )
          }
          else if (props.page === 'slotMachine'){
            var newArray = response.data.data.filter((nft) =>
                nft.isSlotTrait === 1
            )
          }
          newArray.forEach(trait => {
            createdNFTs.push({
                id: trait.id,
                name: trait.traitName,
                type: trait.type,
                creator: trait.creator,
                supply: trait.supply,
                price: trait.price,
                currency: trait.currencyHash,
                imageLink: trait.image,
                description: trait.description,
                live: trait.live,
                soldOut: trait.soldOut,
                amountPurchased: trait.amountPurchased,
                identifyingHash: trait.identifyingHash
              })
          })
        }
        setCreatedNFTs(createdNFTs)
      })
      .catch(function (error) {
        console.log(error);
      })
    }
  }, []);

  const renderCreatedTraits = () => {
    const traitsList = []
    const limitedList = []
    var num = 0
    if (createdNFTs.length !== 0){
      createdNFTs.forEach(trait => {
        const traitInfo = (
          <div className=''>
              <TraitThumbnail src={trait.imageLink} alt="Trait Thumbnail" to={"/"}/>
          </div>
        )
        if (num < 4){
          limitedList.push(traitInfo)
          num += 1
        }
        if (filterID === "" && filterName === ""){
          traitsList.push(traitInfo)
        }
        else if (filterID !== "" && parseInt(filterID) === trait.id){
          traitsList.push(traitInfo)
        }
        else if (filterName !== "" && trait.name.includes(filterName)){
          traitsList.push(traitInfo)
        }
      })
    }
    return limitedList
  }

  const renderPage = () => {
    if (page === "slotMachineHome"){
      return (
        <>
        {props.slotMachineReady ?
          <>
          {
            editingSlot ?
            <div className='w-[80%] ml-auto px-5 mb-20'>
              <div className='flex items-center justify-between mb-5'>
                  <h2 className='text-gray-deep text-2xl font-bold'>Edit Trait Slot Machine</h2>
              </div>
              <div className='text-left mb-5'>
                  <button className="bg-gray-deep text-yellow-light font-semibold flex items-start text-xl rounded-sm px-5 py-1" onClick={() => {headBack()}}>BACK</button>
                  <p className='text-lg text-gray-400 font-medium'>all edits will be lost</p>
              </div>
              <div className='text-left mb-5'>
                  <h3 className='font-gilroy-bold text-xl text-gray-deep'>Roll Price</h3>
                  <p className='text-lg text-gray-400 font-medium'>set the amount to be paid per roll.</p>
                  <input name="rollPrice" type="text" maxLength={26} onChange={e => {onChange(e)}} className="text-gray-deep font-bold bg-gray-light w-[40%] border-2 border-gray-400 p-[1px] focus:outline-0" autoComplete="off"/>
              </div>
              <div className='text-left mb-10'>
                <div>
                  <h3 className='font-gilroy-bold text-xl text-gray-deep'>Select the purchasing coin for your slot machine</h3>
                  <p className='text-lg text-gray-400 font-medium'>Rolls can be paid in SOL or in any SPL-Token.</p>
                  <br></br>
                  <div className='flex justify-between w-[60%] m-auto '>
                    <div className='text-center'>
                      <input className='font-gilroy-bold text-xl text-gray-deep' onChange={e => {onChange(e)}} checked={radioCurrencyType === "solana"} type="radio" value="solana" name="solana"/>
                      <p className='text-lg text-gray-deep font-medium'>Sell in SOL</p>
                    </div>
                    <div className='text-center'>
                      <input className='font-gilroy-bold text-xl text-gray-deep' onChange={e => {onChange(e)}} checked={radioCurrencyType === "splToken"} type="radio" value="splToken" name="splToken" />
                      <p className='text-lg text-gray-deep font-medium'>Sell in another SPL Token</p>
                    </div>
                  </div>
                  <br></br>
                  {
                    radioCurrencyType ?
                      radioCurrencyType !== "solana" ?
                        <div>
                          <div className='text-left mb-5'>
                            <div>
                              <h3 className='font-gilroy-bold text-xl text-gray-deep'>SPL Hash</h3>
                              <p className='text-lg text-gray-400 font-medium'>please input the hash address of the SPL-Token you want to sell this trait in.</p>
                              <input name="purchasingCoin" type="text" onChange={e => {onChange(e)}} className="text-gray-deep font-bold bg-gray-light w-[40%] border-2 border-gray-400 p-[1px] focus:outline-0 " autoComplete="off"/>
                            </div>
                          </div>
                          <div className='text-left mb-5'>
                            <div>
                              <h3 className='font-gilroy-bold text-xl text-gray-deep'>Token Name</h3>
                              <p className='text-lg text-gray-400 font-medium'>please input the name of the SPL-Token. examples: $DUST, $SCRAP, $HADES</p>
                              <input name="tokenName" type="text" onChange={e => {onChange(e)}} className="text-gray-deep font-bold bg-gray-light w-[40%] border-2 border-gray-400 p-[1px] focus:outline-0 " autoComplete="off"/>
                            </div>
                          </div>
                        </div>
                        :
                        <div>
                        </div>
                      :
                      <div>
                      </div>
                  }
                </div>
              </div>
              <div className='text-left mb-5'>
                  {
                    hasSecondCurrency ?
                    <>
                    <div className='text-left mb-5'>
                        <h3 className='font-gilroy-bold text-xl text-gray-deep'>Roll Price</h3>
                        <p className='text-lg text-gray-400 font-medium'>set the amount to be paid per roll.</p>
                        <input name="secondRollPrice" type="text" maxLength={26} onChange={e => {onChange(e)}} className="text-gray-deep font-bold bg-gray-light w-[40%] border-2 border-gray-400 p-[1px] focus:outline-0" autoComplete="off"/>
                    </div>
                    <div className='text-left mb-5'>
                      <div>
                        <h3 className='font-gilroy-bold text-xl text-gray-deep'>Select the purchasing coin for your slot machine</h3>
                        <p className='text-lg text-gray-400 font-medium'>Rolls can be paid in SOL or in any SPL-Token.</p>
                        <br></br>
                        <div className='flex justify-between w-[60%] m-auto '>
                          <div className='text-center'>
                            <input className='font-gilroy-bold text-xl text-gray-deep' onChange={e => {onChange(e)}} checked={secondRadioCurrencyType === "secondsolana"} type="radio" value="secondsolana" name="secondsolana"/>
                            <p className='text-lg text-gray-deep font-medium'>Sell in SOL</p>
                          </div>
                          <div className='text-center'>
                            <input className='font-gilroy-bold text-xl text-gray-deep' onChange={e => {onChange(e)}} checked={secondRadioCurrencyType === "secondsplToken"} type="radio" value="secondsplToken" name="secondsplToken" />
                            <p className='text-lg text-gray-deep font-medium'>Sell in another SPL Token</p>
                          </div>
                        </div>
                        <br></br>
                        {
                          secondRadioCurrencyType ?
                            secondRadioCurrencyType !== "secondsolana" ?
                              <div>
                                <div className='text-left mb-5'>
                                  <div>
                                    <h3 className='font-gilroy-bold text-xl text-gray-deep'>SPL Hash</h3>
                                    <p className='text-lg text-gray-400 font-medium'>please input the hash address of the SPL-Token you want to sell this trait in.</p>
                                    <input name="secondPurchasingCoin" type="text" onChange={e => {onChange(e)}} className="text-gray-deep font-bold bg-gray-light w-[40%] border-2 border-gray-400 p-[1px] focus:outline-0 " autoComplete="off"/>
                                  </div>
                                </div>
                                <div className='text-left mb-5'>
                                  <div>
                                    <h3 className='font-gilroy-bold text-xl text-gray-deep'>Token Name</h3>
                                    <p className='text-lg text-gray-400 font-medium'>please input the name of the SPL-Token. examples: $DUST, $SCRAP, $HADES</p>
                                    <input name="secondtokenName" type="text" onChange={e => {onChange(e)}} className="text-gray-deep font-bold bg-gray-light w-[40%] border-2 border-gray-400 p-[1px] focus:outline-0 " autoComplete="off"/>
                                  </div>
                                </div>
                              </div>
                              :
                              <div>
                              </div>
                            :
                            <div>
                            </div>
                        }
                      </div>
                    </div>
                    </>
                    :
                    <button onClick={() => {setHasSecondCurrency(true)}} className="bg-red-deep text-white p-2  font-bold text-lg text-center rounded-md">
                      Add Second Currency
                    </button>
                  }
              </div>
              <div className='text-left'>
                {
                  slotMachineSubmitReady ?
                  <button onClick={() => {editSlotMachine()}} className="bg-red-deep text-white py-2 w-36 font-bold text-lg text-center rounded-md">
                    Submit!
                  </button>
                  :
                  <button onClick={() => {checkData()}} className="bg-gray-deep text-white py-2 w-44 font-bold text-lg text-center rounded-md">
                    Ready to Submit?
                  </button>
                }
              </div>
            </div>
            :
            <>
            <div className='w-[80%] ml-auto px-5 mb-20'>
              <div className='flex items-center justify-between mb-5'>
                  <h2 className='text-gray-deep text-2xl font-bold'>Edit Trait Slot Machine</h2>
              </div>
              <div className='text-left mb-5'>
                  <h3 className='font-gilroy-bold text-xl text-gray-deep'>Roll Price</h3>
                  {
                    purchasingCoin ?
                    <p className='text-lg text-gray-400 font-medium'> {rollPrice} {tokenName}</p>
                    :
                    <p className='text-lg text-gray-400 font-medium'> {rollPrice} SOL</p>
                  }
              </div>
              {
                secondRollPrice ?
                <div className='text-left mb-5'>

                    <h3 className='font-gilroy-bold text-xl text-gray-deep'>Second Roll Price</h3>
                    {
                      secondPurchasingCoin ?
                      <p className='text-lg text-gray-400 font-medium'> {secondRollPrice} {secondTokenName}</p>
                      :
                      <p className='text-lg text-gray-400 font-medium'> {secondRollPrice} SOL</p>
                    }
                </div>
                :
                ""
              }
              <div className='mb-5'>
                  <button className="bg-gray-deep text-yellow-light font-semibold flex items-start text-xl rounded-sm px-5 py-1" onClick={() => {reEditSlot(true)}}>Edit Slot Machine</button>
              </div>
            </div>
            { createdNFTs ?
            <div className='w-[80%] ml-auto px-5'>
                <div className='max-w-3xl mr-auto relative mb-5'>
                    <div className='flex items-center justify-between mb-2'>
                        <h2 className='text-gray-deep text-2xl font-bold'>Created Slot Traits</h2>
                        {
                          props.projectID === "40" ?
                          <button className='text-gray-deep text-2xl font-normal italic' onClick={() => {props.setInnerPage("createdSlotTraits")}}>See All</button>
                          :
                          <button className='text-gray-deep text-2xl font-normal italic' onClick={() => {props.setInnerPage("createdTraits")}}>See All</button>
                        }
                    </div>
                    <div className='grid grid-cols-4 gap-5'>
                        {createdNFTs ?
                          renderCreatedTraits()
                          :
                          <div>
                          </div>
                        }
                    </div>
                    <div className='absolute top-1/2 -right-24 -translate-y-1/2 flex gap-3'>
                        <span className='bg-red-light inline-block w-3 h-3 rounded-full'></span>
                        <span className='bg-red-light inline-block w-3 h-3 rounded-full'></span>
                        <span className='bg-red-light inline-block w-3 h-3 rounded-full'></span>
                    </div>
                </div>
                {
                  props.projectID == 40 ?
                  <div className='mb-5'>
                      <button className="bg-gray-deep text-yellow-light font-semibold flex items-start text-xl rounded-sm px-5 py-1" onClick={() => {props.setInnerPage("addSlotTraits")}}>Add New Slot Trait</button>
                  </div>
                  :
                  ""
                }
            </div>
            :
            <>
            {
              props.projectID === "40" ?
              <div className='w-[80%] ml-auto px-5'>
                  <div className='max-w-3xl mr-auto mb-5 relative'>
                    <div className='flex items-left justify-between mb-2'>
                        <p className='text-gray-deep text-2xl font-bold text-left'>No traits have been created. Click on the Add New Traits button to get started.</p>
                    </div>
                  </div>
                  <div className='mb-5'>
                      <button className="bg-gray-deep text-yellow-light font-semibold flex items-start text-xl rounded-sm px-5 py-1" onClick={() => {props.setInnerPage("addSlotTraits")}}>Add New Slot Trait</button>
                  </div>
              </div>
              :
              <div className='w-[80%] ml-auto px-5'>
                <div className='max-w-3xl mr-auto mb-5 relative'>
                  <div className='flex items-left justify-between mb-2'>
                      <p className='text-gray-deep text-2xl font-bold text-left'>No traits have been added to the slot machine. To add traits head over to the 'add a new slot trait' tab under 'Slot Machine'.</p>
                  </div>
                </div>
              </div>
            }
            </>
            }
            </>
          }
          </>
          :
          <div className='w-[80%] ml-auto px-5 mb-20'>
            {
              popup  ?
                renderPopup()
                :
              <div>
              </div>
            }
            <div className='flex items-center justify-between mb-5'>
                <h2 className='text-gray-deep text-2xl font-bold'>Edit Trait Slot Machine</h2>
            </div>
            <div className='text-left mb-5'>
                <h3 className='font-gilroy-bold text-xl text-gray-deep'>Roll Price</h3>
                <p className='text-lg text-gray-400 font-medium'>set the amount to be paid per roll.</p>
                <input name="rollPrice" type="text" maxLength={26} onChange={e => {onChange(e)}} className="text-gray-deep font-bold bg-gray-light w-[40%] border-2 border-gray-400 p-[1px] focus:outline-0" autoComplete="off"/>
            </div>
            <div className='text-left mb-5'>
              <div>
                <h3 className='font-gilroy-bold text-xl text-gray-deep'>Select the purchasing coin for your slot machine</h3>
                <p className='text-lg text-gray-400 font-medium'>Rolls can be paid in SOL or in any SPL-Token.</p>
                <br></br>
                <div className='flex justify-between w-[60%] m-auto '>
                  <div className='text-center'>
                    <input className='font-gilroy-bold text-xl text-gray-deep' onChange={e => {onChange(e)}} checked={radioCurrencyType === "solana"} type="radio" value="solana" name="solana"/>
                    <p className='text-lg text-gray-deep font-medium'>Sell in SOL</p>
                  </div>
                  <div className='text-center'>
                    <input className='font-gilroy-bold text-xl text-gray-deep' onChange={e => {onChange(e)}} checked={radioCurrencyType === "splToken"} type="radio" value="splToken" name="splToken" />
                    <p className='text-lg text-gray-deep font-medium'>Sell in another SPL Token</p>
                  </div>
                </div>
                <br></br>
                {
                  radioCurrencyType ?
                    radioCurrencyType !== "solana" ?
                      <div>
                        <div className='text-left mb-5'>
                          <div>
                            <h3 className='font-gilroy-bold text-xl text-gray-deep'>SPL Hash</h3>
                            <p className='text-lg text-gray-400 font-medium'>please input the hash address of the SPL-Token you want to sell this trait in.</p>
                            <input name="purchasingCoin" type="text" onChange={e => {onChange(e)}} className="text-gray-deep font-bold bg-gray-light w-[40%] border-2 border-gray-400 p-[1px] focus:outline-0 " autoComplete="off"/>
                          </div>
                        </div>
                        <div className='text-left mb-5'>
                          <div>
                            <h3 className='font-gilroy-bold text-xl text-gray-deep'>Token Name</h3>
                            <p className='text-lg text-gray-400 font-medium'>please input the name of the SPL-Token. examples: $DUST, $SCRAP, $HADES</p>
                            <input name="tokenName" type="text" onChange={e => {onChange(e)}} className="text-gray-deep font-bold bg-gray-light w-[40%] border-2 border-gray-400 p-[1px] focus:outline-0 " autoComplete="off"/>
                          </div>
                        </div>
                      </div>
                      :
                      <div>
                      </div>
                    :
                    <div>
                    </div>
                }
              </div>
            </div>
            <div className='text-left mb-5'>
              {
                slotMachineSubmitReady ?
                <button onClick={() => {editSlotMachine()}} className="bg-red-deep text-white py-2 w-36 font-bold text-lg text-center rounded-md">
                  Submit!
                </button>
                :
                <button onClick={() => {checkData()}} className="bg-gray-deep text-white py-2 w-44 font-bold text-lg text-center rounded-md">
                  Ready to Submit?
                </button>
              }
            </div>
          </div>
        }
        </>
    )
    }
    else if (page === "createdTraits"){
      return(
        <CreatedSlotTraits setPage={page} projectID={props.projectID} identifyingHash={selectedHash} setSelectedHash={setSelectedHash} createdSlotTraits = {createdNFTs}/>
      )
    }
  }

  const renderPopup = () => {
    if (popupState === "uploadingAssets"){
      return(
        <div style={{position: "absolute" , height: "100%", width: "100%"}} >
          <div className='fixed bg-red-deep p-10 left-1/2 top-1/2 w-fit h-fit -translate-x-1/2 -translate-y-1/2 text-center rounded-full duration-100 z-10'>
              <div className='w-24 mx-auto mb-5'>
                  <img className='max-w-[25px] mx-auto' src={Loader} alt="loading..."/>
              </div>
              <p className='max-w-[300px] mx-auto font-text text-white'> Your traits are being uploaded - this shouldn't take too long</p>
          </div>
        </div>
      )
    }
    else if (popupState === "formIssue"){
      return (
        <div style={{position: "absolute" , height: "100%", width: "100%"}} >
          <div className='fixed bg-red-deep p-10 left-1/2 top-1/2 w-fit h-fit -translate-x-1/2 -translate-y-1/2 text-center rounded-full duration-100 z-10'>
              <p className='max-w-[300px] mx-auto font-text text-white'> Oops! It seems you are missing input values - please review your trait form carefully and try again.</p>
              <button onClick={() => {resetPopup()}} className='text-2xl text-white'>&#10761;</button>
          </div>
        </div>
      )
    }
    else if (popupState === "currencyIssue"){
      return(
        <div style={{position: "absolute" , height: "100%", width: "100%"}} >
          <div className='fixed bg-red-deep p-10 left-1/2 top-1/2 w-fit h-fit -translate-x-1/2 -translate-y-1/2 text-center rounded-full duration-100 z-10'>
              <p className='max-w-[300px] mx-auto font-text text-white'> Oops! It seems you have an input error surrounding your purchasing information. Please ensure that you are inputting a whole number for price if purchasing coin is an SPL Token.</p>
              <button onClick={() => {resetPopup()}} className='text-2xl text-white'>&#10761;</button>
          </div>
        </div>
      )
    }
  }

  const resetPopup = () => {
    setPopup(false)
    setPopupState()
  }

  return (
    <div>
      {renderPage()}
    </div>
  )

}

export default SlotMachineHome
