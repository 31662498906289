import React from 'react';

const SlotHeader = (props) => {
  console.log(props)
  return (
    <nav className='absolute -left-[115px] top-16 z-50'>
      <ul className='flex flex-col justify-end items-end text-end gap-[2px]'>
          <li className='w-[310px] bg-yellow-light px-2 py-1 text-white text-xl font-bold uppercase' onClick={() => {props.setNewPage("traitShop")}}>Traits Shop</li>
          <li className='w-[310px] bg-rose-light px-2 py-1 text-white text-lg font-bold uppercase' onClick={() => {props.setNewPage("upgrades")}} >Upgrade Approvals</li>
          <li className='w-[310px] bg-yellow-deep px-2 py-1 text-white text-lg font-bold uppercase' onClick={() => {props.setNewPage("builder")}}>Collection Builder</li>
          {!props.hasMutation ? <div></div> : <li className='w-[310px] bg-red-light px-2 py-1 text-white text-lg font-bold uppercase' onClick={() => {props.setNewPage("mutations")}}>Mutations</li>}
          <li className='w-[310px] bg-red-deep px-2 py-1 text-white text-lg font-bold uppercase' onClick={() => {props.setInnerPage("default")}}>Slot Machine</li>
          {
            props.selectedPage === "addSlotTraits" ?
              <li className='w-[195px] bg-gray-deep px-2 py-1 text-yellow-deep text-lg font-normal' onClick={() => {props.setInnerPage("addSlotTraits")}}>Add A New Slot Trait</li>
              :
              <li className='w-[195px] bg-gray-deep px-2 py-1 text-gray-light text-lg font-normal' onClick={() => {props.setInnerPage("addSlotTraits")}}>Add A New Slot Trait</li>
          }
          {
            props.projectID !== "40" ?
            <>
            {
              props.selectedPage === "pendingTraits" ?
                <li className='w-[195px] bg-gray-deep px-2 py-1 text-yellow-deep text-lg font-normal' onClick={() => {props.resetTrait("pending")}}>View Pending Traits</li>
                :
                <li className='w-[195px] bg-gray-deep px-2 py-1 text-gray-light text-lg font-normal' onClick={() => {props.resetTrait("pending")}}>View Pending Traits</li>
            }
            {
              props.selectedPage === "createdTraits" ?
                <li className='w-[195px] bg-gray-deep px-2 py-1 text-yellow-deep text-lg font-normal' onClick={() => {props.resetTrait("created")}}>View Created Traits</li>
                :
                <li className='w-[195px] bg-gray-deep px-2 py-1 text-gray-light text-lg font-normal' onClick={() => {props.resetTrait("created")}}>View Created Traits</li>
            }
            </>
            :
            <>
            {
              props.selectedPage === "createdSlotTraits" ?
                <li className='w-[195px] bg-gray-deep px-2 py-1 text-yellow-deep text-lg font-normal' onClick={() => {props.resetTrait("createdSlot")}}>View All Traits</li>
                :
                <li className='w-[195px] bg-gray-deep px-2 py-1 text-gray-light text-lg font-normal' onClick={() => {props.resetTrait("createdSlot")}}>View All Traits</li>
            }
            </>
          }
      </ul>
    </nav>
  )
}

export default SlotHeader
