import React, {useState, useEffect} from "react";
import axios from "axios";
import Arweave from 'arweave';
import ProgressBar from "@ramonak/react-progress-bar";


import { Metaplex, keypairIdentity, walletAdapterIdentity, toMetaplexFileFromBrowser } from "@metaplex-foundation/js";
import { Connection, clusterApiUrl, Keypair, PublicKey, SystemProgram, Transaction, LAMPORTS_PER_SOL } from "@solana/web3.js";
import * as bs58 from "bs58";

import { WalletNotConnectedError } from '@solana/wallet-adapter-base';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';

import { uploadFile } from 'react-s3';
import S3 from 'react-aws-s3';
window.Buffer = window.Buffer || require("buffer").Buffer;


const Setup = (props) => {
  const [uploadedHash, setUploadedhash] = useState()
  const [collectionName, setCollectionName] = useState()


  const saveSetup = async () => {
    console.log(uploadedHash)

    let fileName = "hashes/" + props.projectID.toString() + "/" + uploadedHash.name.replaceAll(" ", "_")

    const s3Config = {
      bucketName:"maxinbodyshop",
      region: "us-east-2",
      accessKeyId: process.env.AWS_KEY,
      secretAccessKey: process.env.AWS_SECRET,
      s3Url: 'https://maxinbodyshop.s3.amazonaws.com'
    }

    const ReactS3Client = new S3(s3Config);

    const imageUpload = await ReactS3Client.uploadFile(uploadedHash, fileName)

    var data = JSON.stringify({
      "projectID": props.projectID,
      "hashListURL": imageUpload.location,
      "collectionName": collectionName,
      "action": "setup"
    });

    var config = {
      method: 'post',
      url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/editClients',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      console.log(response)
      props.setPage("dash")
    })
    .catch(function (error) {
      console.log(error);
    })

    // const maxinWallet = Keypair.fromSecretKey(bs58.decode(process.env.REACT_APP_SECRET_KEY))
    // const connection = new Connection(clusterApiUrl("mainnet-beta"));
    // console.log(wallet)

    // let metaplex = Metaplex.make(connection).use(keypairIdentity(maxinWallet))
    // use the below to make users sign transactions rather than using the key

    // let metaplex = Metaplex.make(connection).use(walletAdapterIdentity(wallet))

    //
    // const { uri, metadata } = await metaplex.nfts().uploadMetadata({
    //   name: "My NFT",
    //   symbol: "maxinTrait",
    //   description: "hellloooooo",
    //   seller_fee_basis_points: "1000",
    //   animation_url: await toMetaplexFileFromBrowser(uploadedVideo),
    //   image: await toMetaplexFileFromBrowser(uploadedImage),
    //   attributes: [
    //     {
    //       trait_type: "Test",
    //       value: "yes"
    //     }
    //   ],
    //   properties: {
    //       creators: [
    //         {
    //           address: "HhDTCmNWfgd5YWZd4obr5Ui6CDnBfUD8iKNA5jvFmxrK",
    //           share: 80
    //         },
    //         {
    //           address: "97g43tP1YdU4C7PbSkhyRfc21hJc4b1MVK1vvzRNu49k",
    //           share: 20
    //         }
    //       ],
    //       files: [
    //           {
    //               type: "video/mp4",
    //               uri: await toMetaplexFileFromBrowser(uploadedVideo)
    //           },
    //           {
    //               type: "image/png",
    //               uri: await toMetaplexFileFromBrowser(uploadedImage)
    //           }
    //       ]
    //   }
    // });

    // const { uri, metadata } = await metaplex.nfts().uploadMetadata({
    //   name: "My NFT",
    //   // image: await toMetaplexFileFromBrowser(uploadedImage),
    //   properties: {
    //           files: [
    //               {
    //                   type: "video/mp4",
    //                   // uri: await toMetaplexFileFromBrowser(uploadedVideo),
    //               },
    //           ]
    //       }
    // });


    // console.log(metadata)
    // console.log(uri)

  }

  const handleInput = (event) => {
    if (event.target.name === "collectionName"){
      setCollectionName(event.target.value)
    }
  }


  return (
    <div>
      <button onClick={() => {props.setPage("dash")}}>
        Back
      </button>
      <br></br><br></br><br></br>
      Testing NFT Creation
      <br></br><br></br><br></br><br></br>
        <label style={{width: 200, color: "black"}}>
          Upload hash
        </label>
        <br></br>
        <input style={{width: 200, color: "grey"}} name="json" type="file" onChange={e => {setUploadedhash(e.target.files[0])}} accept=".json"/>
        <br></br><br></br>
        <label style={{width: 200, color: "black"}}>
          Collection Name
        </label>
        <br></br>
        <input style={{width: 200, color: "grey"}} name="collectionName" type="text" onChange={e => {handleInput(e)}}/>
        <br></br> <br></br>
        <button style={{height: 30, width: 100}} onClick={() => {saveSetup()}}>
          Save
        </button>
    </div>
  );
};

export default Setup;
