import React, {useState, useEffect} from "react";
import axios from "axios";
import S3 from 'react-aws-s3';
import Loader from "../assets/img/loading.gif"
window.Buffer = window.Buffer || require("buffer").Buffer;


const AddTrait = (props) => {
  const [assetDict, setAssetDict] = useState({})
  const [selectedCategory, setSelectedCategory] = useState()
  const [selectedTrait, setSelectedTrait] = useState()
  const [builderStates, setBuilderStates] = useState([])
  const [collectionName, setCollectionName] = useState("Base")

  // Form variables
  const [awsUploadImage, setAWSUploadImage] = useState()
  const [awsUploadVideo, setAWSUploadVideo] = useState()
  const [imageType, setImageType] = useState()
  const [videoType, setVideoType] = useState()
  const [name, setName] = useState()
  const [symbol, setSymbol] = useState()
  const [externalLink, setExternalLink] = useState()
  const [supply, setSupply] = useState()
  const [sellerFee, setSellerFee] = useState()
  const [description, setDescription] = useState()
  const [price, setPrice] = useState()
  const [purchasingCoin, setPurchasingCoin] = useState()
  const [traitCategory, setTraitCategory] = useState()
  const [radioType, setRadioType] = useState()
  const [traitMetadataName, setTraitMetadataName] = useState()
  const [radioCurrencyType, setRadioCurrencyType] = useState()
  const [cosmetic, setCosmetic] = useState('normal')
  const [newTraitFile, setNewTraitFile] = useState()
  const [newTraitFileType, setNewTraitFileType] = useState()
  const [hasGating, setHasGating] = useState('nogating')

  // TODO: need to add abilitiy to remove attributes all together
  const [attributeCount, setAttributeCount] = useState(1)
  const [attributeList, setAttributeList] = useState([{trait_type: "", value: ""}])

  // checker variable
  const [readyToCreate, setReadyToCreate] = useState(false)

  const [popupState, setPopupState] = useState()
  const [popup, setPopup] = useState(false)

  // get assets from collection and upgrading information
  useEffect(() => {
    var data = JSON.stringify({
      "projectID": props.projectID,
      "action": "getAssetDict2"
    });

    var config = {
      method: 'post',
      url: 'https://59200uzilj.execute-api.us-east-1.amazonaws.com/Production/renderimage',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      setAssetDict(response.data.assetDict)
      setBuilderStates(Object.keys(response.data.assetDict))
      setSelectedCategory(Object.keys(response.data.assetDict[collectionName])[0])
    })
    .catch(function (error) {
      console.log(error);
    })
  }, []);

  useEffect(() => {
    setReadyToCreate(false)
  }, [selectedTrait]);

  // setting state variables with user inputs
  const onChange = (e) => {
    setReadyToCreate(false)

    // image/video upload
    if (e.target.files) {
      const files = e.target.files
      if (e.target.name === "mp4"){
        setAWSUploadVideo(files[0])
        setVideoType(files[0].type.replace("video/",""))
      }
      else if (e.target.name === "png"){
        setAWSUploadImage(files[0])
        setImageType(files[0].type.replace("image/",""))
      }
      else if (e.target.name === "newTraitFile"){
        setNewTraitFile(files[0])
        setNewTraitFileType(files[0].type.replace("image/",""))
      }
    }

    else{
      if (e.target.name === "name"){
        setName(e.target.value)
      }
      else if (e.target.name === "symbol"){
        setSymbol(e.target.value)
      }
      else if (e.target.name === "externalLink"){
        setExternalLink(e.target.value)
      }
      else if (e.target.name === "supply"){
        setSupply(e.target.value)
      }
      else if (e.target.name === "sellerFee"){
        setSellerFee(e.target.value)
      }
      else if (e.target.name === "description"){
        setDescription(e.target.value)
      }
      else if (e.target.name === "price"){
        setPrice(parseFloat(e.target.value))
      }
      else if (e.target.name === 'purchasingCoin'){
        setPurchasingCoin(e.target.value)
      }
      else if (e.target.name === 'traitCategory'){
        setTraitCategory(e.target.value)
      }
      else if (e.target.name === 'traitMetadataName'){
        setTraitMetadataName(e.target.value)
      }
      else if (e.target.name.includes("attribute")){
        const arrayPosition = parseInt(e.target.name.slice(-1), 10)
        if (e.target.name.includes("Name")){
          attributeList[arrayPosition].trait_type = e.target.value
        }
        else if (e.target.name.includes("Value")){
          attributeList[arrayPosition].value = e.target.value
        }
      }
      else if (e.target.name === "uploadNew" || e.target.name === "selectTrait"){
        setRadioType(e.target.name)
      }
      else if (e.target.name === "solana" || e.target.name === "splToken"){
        setRadioCurrencyType(e.target.name)
      }
      else if (e.target.name === "normal" || e.target.name === "cosmetic"){
        setCosmetic(e.target.name)
      }
      else if (e.target.name === "nogating" || e.target.name === "gating"){
        setHasGating(e.target.name)
      }
    }
  }

  // form checker - set error popup
  const checkData = () => {
    if (imageType && symbol && externalLink && supply && description){
      if (selectedTrait || (selectedCategory && traitMetadataName)){
        setReadyToCreate(true)
      }
      else{
        setPopupState("formIssue")
        setPopup(true)
      }
    }
    else{
      setPopupState("formIssue")
      setPopup(true)
    }
  }

  // TODO: need to do some user research to see if people even want attributes on their traits
  const renderAttributes = () => {
    let attributeListTemp = []

    for (let i = 0; i < attributeCount; i++) {
      const attributeName = "attributeName" + i.toString()
      const attributeValue = "attributeValue" + i.toString()

      attributeListTemp.push(
        <div className='grid grid-cols-2'>
            <div className='text-center'>
                <div>
                    <h3 className='font-gilroy-bold text-xl text-gray-deep'>Attribute Name</h3>
                    <input name={attributeName} type="text" onChange={e => {onChange(e)}} className="text-gray-deep font-normal bg-gray-light w-[97%] border-2 border-gray-400 p-[1px] focus:outline-0" autoComplete="off"/>
                </div>
            </div>
            <div className='text-center'>
                <div>
                    <h3 className='font-gilroy-bold text-xl text-gray-deep'>Attribute Value</h3>
                    <input name={attributeValue} type="text" onChange={e => {onChange(e)}} className="text-gray-deep font-normal bg-gray-light w-[97%] border-2 border-gray-400 p-[1px] focus:outline-0" autoComplete="off"/>
                </div>
            </div>
        </div>
      )
    }

    return attributeListTemp
  }

  const createAttribute = () => {
    setAttributeCount(attributeCount + 1)
    attributeList.push({trait_type: "", value: ""})
  }

  const removeAttribute = () => {
    setAttributeCount(attributeCount - 1)
    attributeList.pop()
  }

  // randomly create a hash to attach to a trait
  // TODO: add a checker to ensure uniqueness of hashes (api call)
  const randomHash = (length) => {
    let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let hash = '';
    for (let i = 0; i < length; i++) {
        hash += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return hash;
  }

  // S3 bucket interaction
  // TODO: check out the CORS issue that has been persisting and error check on that for future deployments
  const uploadAssets = async () => {
    setPopupState("uploadingAssets")
    setPopup(true)

    let identifyingHashTemp = randomHash(50)

    let imageName = "traitImages/" + props.projectID + "/" + collectionName + "/" + identifyingHashTemp
    let imageLink = "https://maxinbodyshop.s3.us-east-2.amazonaws.com/" + imageName + "." + imageType

    // uploading image to use as cover of trait
    const s3Config = {
      bucketName:"maxinbodyshop",
      region: "us-east-2",
      accessKeyId: process.env.AWS_KEY,
      secretAccessKey: process.env.AWS_SECRET,
      s3Url: 'https://maxinbodyshop.s3.amazonaws.com'
    }
    const ReactS3Client = new S3(s3Config);

    await ReactS3Client.uploadFile(awsUploadImage, imageName)

    // uploading video if there is one
    let videoLink
    if (awsUploadVideo){
      let videoName = "traitVideos/" + props.projectID + "/" + collectionName + "/" + identifyingHashTemp
      videoLink = "https://maxinbodyshop.s3.us-east-2.amazonaws.com/" + videoName + "." + videoType
      await ReactS3Client.uploadFile(awsUploadVideo, videoName)
    }

    // if trait is new we need to upload it to the database and add it to the builder
    if (radioType === "uploadNew"){

      // generate the new image link for the builder
      let newTraitName = "builder/" + props.projectID + "/" + collectionName + "/" + selectedCategory.replaceAll(" ", "_") + "/" + traitMetadataName.replaceAll(" ", "_")
      await ReactS3Client.uploadFile(newTraitFile, newTraitName)
      const link = "https://maxinbodyshop.s3.us-east-2.amazonaws.com/" + newTraitName + ".png"

      // add it to the database as a new trait
      var data = JSON.stringify({
        "projectID": props.projectID,
        "collectionName": collectionName,
        "category": selectedCategory,
        "traitName": traitMetadataName,
        "link": link,
        "action": "addImage"
      });

      var config = {
        method: 'post',
        url: 'https://59200uzilj.execute-api.us-east-1.amazonaws.com/Production/uploadimages',
        headers: {
          'x-api-key': process.env.GATEWAY_KEY,
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
      .then(function (response) {
        uploadJSON(response.data.assetID, ReactS3Client, identifyingHashTemp, imageLink, videoLink)
      })
      .catch(function (error) {
        // console.log(error);
      })
    }

    else{
      uploadJSON(JSON.parse(selectedTrait).id, ReactS3Client, identifyingHashTemp, imageLink, videoLink)
    }


  }

  const uploadJSON = (assetID, reactS3Client, identifyingHashTemp, imageLink, videoLink) => {
    // adding our royalty to the trait
    // TODO: need to define if we want to continue this moving forward when the trait marketplace is implemented
    let seller_fee_basis_points = (parseInt(sellerFee) + 2) * 100
    let maxinShare = Math.floor(200 / sellerFee)
    let creatorShare = 100 - maxinShare

    // metadata links being set
    let files = [{ uri: imageLink, type: "image/" + imageType}]
    if (awsUploadVideo){
      files.push(
        {uri: videoLink, type: "video/" + videoType}
      )
    }

    // SFT creation dictionary
    let jsonDict = {
      name: props.projectName + ' Trait',
      symbol: symbol,
      description: description,
      seller_fee_basis_points: seller_fee_basis_points,
      image: imageLink,
      attributes: attributeList,
      properties:{
        creators:[
          {
            address: props.creatorWallet,
            share: creatorShare
          },
          {
            address: "HhDTCmNWfgd5YWZd4obr5Ui6CDnBfUD8iKNA5jvFmxrK",
            share: maxinShare
          }
        ],
        files: files
      }
    }

    if (awsUploadVideo){
      jsonDict = { ...jsonDict, animation_url: videoLink }
    }

    // upload to S3 for SFT creation in next step
    const jsonFile = JSON.stringify(jsonDict)
    let jsonName = "jsons/" + props.projectID + "/" + identifyingHashTemp + ".json"
    let jsonLink = "https://maxinbodyshop.s3.us-east-2.amazonaws.com/" + jsonName

    // upload to S3 and store in database
    reactS3Client.uploadFile(jsonFile, jsonName).then(response => {

      var data = JSON.stringify({
        "action": "setSFTs2",
        "projectID": props.projectID,
        "collectionName": collectionName,
        "identifyingHash": identifyingHashTemp,
        "name": props.projectName + ' Trait',
        "symbol": symbol,
        "description": description,
        "sellerFee": '0',
        "attributeList": attributeList,
        "imageLink": imageLink,
        "videoLink": videoLink ? videoLink : "",
        "jsonLink": jsonLink,
        "creatorAddress": props.creatorWallet,
        "imageType": imageType,
        "videoType": videoType ? videoType : "",
        "price": 0,
        "purchasingCoin": 'XXX',
        "traitCategory": selectedCategory,
        "supply": supply,
        "assetID": assetID,
        "cosmetic": cosmetic,
        "hasGating": hasGating,
        'hasSlotTrait': 'yes'
      });

      var config = {
        method: 'post',
        url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/setnewnfts',
        headers: {
          'x-api-key': process.env.GATEWAY_KEY,
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
      // Successful upload - move onto next page
      .then(function (response) {
        console.log(response)
        setPopupState()
        setPopup(false)
        props.setPendingTrait(identifyingHashTemp)
      })
      .catch(function (error) {
        // console.log(error);
      })
    })

  }

  // showcase all the traits that exist in the builder for sale
  const renderSelectExisting = () => {
    const categoryArray = []
    const traitArray = []

    categoryArray.push(
      <select className="bg-gray-light border-2 border-gray-400 font-bold text-[18px] px-2 uppercase cursor-pointer text-grey-deep w-full h-12 leading-8" value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
          {Object.keys(assetDict[collectionName]).map((category) => (
              <option value={category} className="bg-gray-400 text-[16px] font-bold uppercase hover:bg-gray-deep cursor-pointer text-white hover:text-yellow-light px-2 py-[2px]">
                  {category}
              </option>
          ))}
      </select>
    )

    traitArray.push(
      <select className="bg-gray-light border-2 border-gray-400 font-bold text-[18px] px-2 uppercase cursor-pointer text-grey-deep w-full h-12 leading-8" value={selectedTrait} onChange={(e) => setSelectedTrait(e.target.value)}>
          <option className="bg-gray-400 text-[16px] font-bold uppercase hover:bg-gray-deep cursor-pointer text-white hover:text-yellow-light px-2 py-[2px]">
              ----------------
          </option>
          {assetDict[collectionName][selectedCategory].map(trait => (
              <option value={JSON.stringify(trait)} className="bg-gray-400 text-[16px] font-bold uppercase hover:bg-gray-deep cursor-pointer text-white hover:text-yellow-light px-2 py-[2px]">{trait.traitName}</option>
          ))}
      </select>
    )

    return (
      <div className='flex justify-between items-start'>
          {categoryArray}
          {traitArray}
      </div>
    )
  }

  // popup states for different actions
  const renderPopup = () => {

    if (popupState === "uploadingAssets"){
      return(
        <div style={{position: "absolute" , height: "100%", width: "100%"}} >
            <div className='fixed bg-red-deep p-10 left-1/2 top-1/2 w-fit h-fit -translate-x-1/2 -translate-y-1/2 text-center rounded-full duration-100 z-10'>
                <div className='w-24 mx-auto mb-5'>
                    <img className='max-w-[25px] mx-auto' src={Loader} alt="loading..."/>
                </div>
                <p className='max-w-[300px] mx-auto font-text text-white'> Your traits are being uploaded - this shouldn't take too long</p>
            </div>
        </div>
      )
    }

    else if (popupState === "formIssue"){
      return (
        <div style={{position: "absolute" , height: "100%", width: "100%"}} >
            <div className='fixed bg-red-deep p-10 left-1/2 top-1/2 w-fit h-fit -translate-x-1/2 -translate-y-1/2 text-center rounded-full duration-100 z-10'>
                <p className='max-w-[300px] mx-auto font-text text-white'> Oops! It seems you are missing input values - please review your trait form carefully and try again.</p>
                <button onClick={() => {resetPopup()}} className='text-2xl text-white'>&#10761;</button>
            </div>
        </div>
      )
    }

    else if (popupState === "currencyIssue"){
      return(
        <div style={{position: "absolute" , height: "100%", width: "100%"}} >
            <div className='fixed bg-red-deep p-10 left-1/2 top-1/2 w-fit h-fit -translate-x-1/2 -translate-y-1/2 text-center rounded-full duration-100 z-10'>
                <p className='max-w-[300px] mx-auto font-text text-white'> Oops! It seems you have an input error surrounding your purchasing information. Please ensure that you are inputting a whole number for price if purchasing coin is an SPL Token.</p>
                <button onClick={() => {resetPopup()}} className='text-2xl text-white'>&#10761;</button>
            </div>
        </div>
      )
    }
  }

  const resetPopup = () => {
    setPopup(false)
    setPopupState()
  }

  // TODO: should convert this to a form for better input checking
  return(
    <div>
        <div className='w-[80%] ml-auto px-5'>
            <div className='max-w-xl ml-36 mr-auto mb-5 relative grid gap-7'>
                { popup  ?
                    renderPopup()
                    :
                    <div>
                    </div>
                }
                { builderStates.length ?
                    <div>
                        <select className="bg-gray-light border-2 border-gray-400 font-bold text-[18px] px-2 uppercase cursor-pointer text-grey-deep w-[30%] h-12 leading-8" value={collectionName} onChange={(e) => setCollectionName(e.target.value)}>
                            {builderStates.map((collectionNameTemp) => (
                                <option value={collectionNameTemp} className="bg-gray-400 text-[16px] font-bold uppercase hover:bg-gray-deep cursor-pointer text-white hover:text-yellow-light px-2 py-[2px]">
                                    {collectionNameTemp}
                                </option>
                            ))}
                        </select>
                    </div>
                    :
                    <div>
                    </div>
                }
                {/* Images and Video Upload */}
                <div className='flex justify-between items-start'>
                    <div className='text-center mr-5'>
                        <div>
                            <h3 className='font-gilroy-bold text-xl text-gray-deep'>Upload Image</h3>
                            <p className='text-lg text-gray-400 font-medium'>seen in front end of your trait marketplace</p>
                            <input onChange={e => {onChange(e)}} name="png" accept="image/*" type="file" className="text-gray-deep font-bold" />
                        </div>
                    </div>
                    <div className='text-center ml-5'>
                        <div>
                            <h3 className='font-gilroy-bold text-xl text-gray-deep'>Upload Video</h3>
                            <p className='text-lg text-gray-400 font-medium'>this field is optional</p>
                            <input onChange={e => {onChange(e)}} name="mp4" accept="video/*" type="file" className="text-gray-deep font-bold" />
                        </div>
                    </div>
                </div>

                {/* Define new trait or tie it to already existing trait */}
                <div className='text-center'>
                    <h3 className='font-gilroy-bold text-xl text-gray-deep'>Define the collection artwork attached to this trait</h3>
                    <p className='text-lg text-gray-400 font-medium'>you can upload a new image or use an existing trait</p>
                    <br></br>
                    <div className='flex justify-between w-[60%] m-auto '>
                        <div className='text-center'>
                            <input className='font-gilroy-bold text-xl text-gray-deep' onChange={e => {onChange(e)}} checked={radioType === "selectTrait"} type="radio" value="selectTrait" name="selectTrait" />
                            <p className='text-lg text-gray-400 font-medium'>Select Existing Trait</p>
                        </div>
                        <div className='text-center'>
                            <input className='font-gilroy-bold text-xl text-gray-deep' onChange={e => {onChange(e)}} checked={radioType === "uploadNew"} type="radio" value="uploadNew" name="uploadNew" />
                            <p className='text-lg text-gray-400 font-medium'>Upload a New Trait</p>
                        </div>
                    </div>
                    <br></br>
                    { radioType ?
                        radioType === "uploadNew" ?
                            <div>
                                <div className='text-center'>
                                    <h3 className='font-gilroy-bold text-xl text-gray-deep'>Upload Collection Artwork</h3>
                                    <p className='text-lg text-gray-400 font-medium'>this is the linked artwork to your collection</p>
                                    <input onChange={e => {onChange(e)}} name="newTraitFile" accept="image/*" type="file" className="text-gray-deep font-bold" />
                                </div>
                                <br></br>
                                <div className='text-center'>
                                    <div>
                                        <h3 className='font-gilroy-bold text-xl text-gray-deep'>Metadata Name</h3>
                                        <p className='text-lg text-gray-400 font-medium'>this is how the trait will appear in the metadata</p>
                                        <input name="traitMetadataName" type="text" onChange={e => {onChange(e)}} className="text-gray-deep font-bold bg-gray-light w-[40%] border-2 border-gray-400 p-[1px] focus:outline-0" />
                                    </div>
                                </div>
                                <br></br>
                                <div className='flex flex-col w-[40%] m-auto relative'>
                                    <h3 className='font-gilroy-bold text-xl text-gray-deep'>Trait Category</h3>
                                    <select className="bg-gray-light border-2 border-gray-400 font-bold text-[18px] px-2 uppercase cursor-pointer text-grey-deep w-full h-12 leading-8" value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
                                        {Object.keys(assetDict[collectionName]).map((category) => (
                                            <option value={category} className="bg-gray-400 text-[16px] font-bold uppercase hover:bg-gray-deep cursor-pointer text-white hover:text-yellow-light px-2 py-[2px]">{category}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            :
                            <div>
                              { !selectedTrait ?
                                  renderSelectExisting()
                                  :
                                  <div className='text-center'>
                                      <div>
                                          <h3 className='font-gilroy-bold text-xl text-gray-deep'>The trait NFT will map to this collection trait</h3>
                                          <br></br>
                                          <div className='grid grid-cols-2 m-auto bg-gray-300 gap-2 p-5 w-[40%]'>
                                              <div className='flex flex-col relative'>
                                                  <div className="bg-gray-400 relative font-bold text-[16px] px-2 uppercase cursor-pointer text-white w-full h-7 leading-8">
                                                    Category
                                                  </div>
                                                  <p className='text-gray-deep text-lg p-1 leading-normal'>{JSON.parse(selectedTrait).category}</p>
                                              </div>
                                              <div className='flex flex-col relative'>
                                                  <div className="bg-gray-400 relative font-bold text-[16px] px-2 uppercase cursor-pointer text-white w-full h-7 leading-8">
                                                      Trait Name
                                                  </div>
                                                  <p className='text-gray-deep text-lg p-1 leading-normal'>{JSON.parse(selectedTrait).traitName}</p>
                                              </div>
                                          </div>
                                          <br></br>
                                          <button onClick={() => {setSelectedTrait()}} className="bg-gray-deep text-white py-2 w-20 font-bold text-sm text-center rounded-md">
                                              Edit Trait
                                          </button>
                                      </div>
                                  </div>
                              }
                            </div>
                        :
                        <div>
                        </div>
                    }
                </div>

                {/* Attribute Trait or Cosmetic Trait */}
                <div className='text-center'>
                    { !props.hasTraitSwaps ?
                        <>
                            <h3 className='font-gilroy-bold text-xl text-gray-deep'>Define if trait will affect NFT metadata/image or only affect the NFT image?</h3>
                            <p className='text-lg text-gray-400 font-medium'>You can make it so that customizing with this trait doesn't affect the NFTs metadata and the upgrade is purely cosmetic.</p>
                            <br></br>
                            <div className='flex justify-between w-[60%] m-auto '>
                                  <div className='text-center'>
                                      <input className='font-gilroy-bold text-xl text-gray-deep' onChange={e => {onChange(e)}} checked={cosmetic === "normal"} type="radio" value="normal" name="normal" />
                                      <p className='text-lg text-gray-400 font-medium'>Affects Metadata</p>
                                  </div>
                                  <div className='text-center'>
                                      <input className='font-gilroy-bold text-xl text-gray-deep' onChange={e => {onChange(e)}} checked={cosmetic === "cosmetic"} type="radio" value="cosmetic" name="cosmetic" />
                                      <p className='text-lg text-gray-400 font-medium'>Doesn't Affect Metadata</p>
                                  </div>
                            </div>
                        </>
                        :
                        <></>
                    }
                </div>

                {/* NFT Name */}

                {/* NFT Symbol */}
                <div className='text-center'>
                    <div>
                        <h3 className='font-gilroy-bold text-xl text-gray-deep'>Symbol</h3>
                        <p className='text-lg text-gray-400 font-medium'>this will appear in the metadata of the NFT - only regular characters are allowed</p>
                        <input name="symbol" type="text" maxLength={10} onChange={e => {onChange(e)}} className="text-gray-deep font-bold bg-gray-light w-[40%] border-2 border-gray-400 p-[1px] focus:outline-0" autoComplete="off"/>
                    </div>
                </div>

                {/* External link */}
                {/* TODO: make this optional moving forward */}
                <div className='text-center'>
                    <div>
                        <h3 className='font-gilroy-bold text-xl text-gray-deep'>External link</h3>
                        <p className='text-lg text-gray-400 font-medium'>this appears when viewing the NFT in your wallet</p>
                        <input name="externalLink" type="text" onChange={e => {onChange(e)}} className="text-gray-deep font-bold bg-gray-light w-[40%] border-2 border-gray-400 p-[1px] focus:outline-0" autoComplete="off"/>
                    </div>
                </div>

                {/* Supply of new trait */}
                {/* TODO: allow users to edit the supply of a trait */}
                <div className='text-center'>
                    <div>
                        <h3 className='font-gilroy-bold text-xl text-gray-deep'>Supply</h3>
                        <p className='text-lg text-gray-400 font-medium'>this cannot be edited later</p>
                        <input name="supply" type="text" onChange={e => {onChange(e)}} className="text-gray-deep font-bold bg-gray-light w-[40%] border-2 border-gray-400 p-[1px] focus:outline-0" autoComplete="off"/>
                    </div>
                </div>
                {/* Description */}
                <div className='text-center flex flex-col justify-center items-center'>
                    <label className='font-gilroy-bold text-xl text-gray-deep mb-1'>Description</label>
                    <textarea onChange={e => {onChange(e)}} name="description" className='w-[40%] text-gray-deep font-bold bg-gray-light h-56 border-2 border-gray-400 p-[1px] focus:outline-0' />
                </div>

                {/* Attributes */}
                <div className='text-center'>
                    <div className='mt-10 flex justify-center gap-3'>
                        <button onClick={() => {createAttribute()}} className="bg-gray-400 text-white px-5 py-1 text-lg font-bold rounded-md"> Add attribute</button>
                        <button onClick={() => {removeAttribute()}} className="bg-gray-400 text-white px-5 py-1 text-lg font-bold rounded-md"> Remove attribute</button>
                    </div>
                </div>
                {renderAttributes()}
                {
                  props.projectID == 51 ?
                  <div className='text-center'>
                    <div>
                      <h3 className='font-gilroy-bold text-xl text-gray-deep'>Gating Trait Sales to specific Holders.</h3>
                      <div className='flex justify-between w-[60%] m-auto '>
                            <div className='text-center'>
                                <input className='font-gilroy-bold text-xl text-gray-deep' onChange={e => {onChange(e)}} checked={hasGating === "nogating"} type="radio" value="nogating" name="nogating" />
                                <p className='text-lg text-gray-400 font-medium'>Not Exclusive to Series 1 Holders</p>
                            </div>
                            <div className='text-center'>
                                <input className='font-gilroy-bold text-xl text-gray-deep' onChange={e => {onChange(e)}} checked={hasGating === "gating"} type="radio" value="gating" name="gating" />
                                <p className='text-lg text-gray-400 font-medium'>Exclusive to Series 1 Holders</p>
                            </div>
                      </div>
                    </div>
                  </div>
                  :
                  ""
                }

                {/* Creation Button */}
                <div className='text-center'>
                    { readyToCreate ?
                        <button onClick={() => {uploadAssets()}} className="bg-red-deep text-white py-2 w-36 font-bold text-lg text-center rounded-md">
                            Submit!
                        </button>
                        :
                        <button onClick={() => {checkData()}} className="bg-gray-deep text-white py-2 w-44 font-bold text-lg text-center rounded-md">
                            Ready to Submit?
                        </button>
                    }
                </div>

            </div>
        </div>
    </div>
  )
};

export default AddTrait;
