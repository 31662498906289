import React, {useCallback, useState, useEffect} from "react";
import axios from "axios";
import moment from 'moment';
import TraitHeader from '../Components/TraitHeader';
import IndividualCreatedTrait from '../Components/IndividualCreatedTrait';
import PendingTraitImg from '../assets/img/thumbnail.png';
import { IoMdArrowDropright } from 'react-icons/io';
import ReadyToPurchaseBox from '../Components/ReadyToPurchaseBox';
import PurchasedBox from '../Components/PurchasedBox';
import Loader from "../assets/img/loading.gif";
import Failed from "../assets/img/failedtransaction.png";
import bs58 from "bs58";
import { WalletNotConnectedError } from "@solana/wallet-adapter-base";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import * as web3 from "@solana/web3.js";
import {
  getAccount,
  createTransferCheckedInstruction,
  getAssociatedTokenAddress,
  createAssociatedTokenAccountInstruction,
  createTransferInstruction,
  TOKEN_PROGRAM_ID,
  ASSOCIATED_TOKEN_PROGRAM_ID,
} from "@solana/spl-token";


const IndividualLiveTrait = (props) => {
  const [selectedHash, setSelectedHash] = useState(null)
  const [hashFilter, setHashFilter] = useState("")

  const [nfts, setNFTs] = useState([])

  const [isLive, setIsLive] = useState()
  const [amountPurchased, setAmountPurchased] = useState()
  const [price, setPrice] = useState()
  const [currencyHash, setCurrencyHash] = useState()
  const [supply, setSupply] = useState()
  const [image, setImage] = useState()
  const [name, setName] = useState()
  const [traitHash, setTraitHash] = useState()
  const [amountToSend, setAmountToSend] = useState()

  const [statusField, setStatusField] = useState('');
  const [statusArrow, setstatusArrow] = useState(false);

  const [editPrice, setEditPrice] = useState()
  const [editPurchasingCoin, setEditPurchasingCoin] = useState()

  const { connection } = useConnection();
  const { publicKey, sendTransaction, signTransaction } = useWallet();

  const [status,setStatus] = useState('')

  const [popupState, setPopupState] = useState()
  const [popup, setPopup] = useState(false)

  const onClickstatus = event => {
    setStatusField(current => !current);
    setstatusArrow(current => !current);
  };
  const onChangestatus = (e)=>{
    setStatus(e.target.innerText)
   };
  const onChange = (e) => {
     if (e.target.name === "sendBulk"){
       setAmountToSend(e.target.value)
     }
   }

  const currencyDict = {
    "CA6nNPCCKhf4AEEnXBnzcxms4c4gu7scFrA2WRqczHoW": "$Otaku 2.0",
    "XXX": "SOL"
  }

  useEffect(() => {
    var data = JSON.stringify({
      "traitID": props.traitID,
      "action": "getNFTs"
    });

    var config = {
      method: 'post',
      url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/getnfts',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      console.log(response)
      let nftArray = []
      response.data.transactions.map((nft) => {
        nftArray.push(nft)
      })
      setNFTs(nftArray)
      setIsLive(response.data.isLive)
      setAmountPurchased(response.data.amountPurchased)
      setPrice(response.data.price)
      setCurrencyHash(response.data.currencyHash)
      setSupply(response.data.supply)
      setImage(response.data.image)
      setName(response.data.name)
      setTraitHash(response.data.SFTHash)
    })
    .catch(function (error) {
      console.log(error);
    })
  }, [isLive]);

  useEffect(() => {
    var data = JSON.stringify({
      "traitID": props.traitID,
      "action": "getNFTs"
    });

    var config = {
      method: 'post',
      url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/getnfts',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      console.log(response)
      let nftArray = []
      response.data.nfts.map((nft) => {
        nftArray.push(nft)
      })
      setNFTs(nftArray)
      setIsLive(response.data.isLive)
      setAmountPurchased(response.data.amountPurchased)
      setPrice(response.data.price)
      setCurrencyHash(response.data.currencyHash)
      setSupply(response.data.supply)
      setImage(response.data.image)
      setName(response.data.name)
    })
    .catch(function (error) {
      console.log(error);
    })
  }, []);

  const editNFT = (e) => {
    if (e.target.name === "price"){
      setEditPrice(e.target.value)
    }
    else if (e.target.name === "purchasingCoin"){
      setEditPurchasingCoin(e.target.value)
    }
  }

  const renderNFTs = () => {
    if (isLive){
      let traitsArray = []
      nfts.map((nft) => {
        let nftInfo = (
            <div className="w-[95%]">
                <div className='bg-red-deep p-2 text-center mb-1 break-words'>
                    <p className='text-black text-md font-semibold'>Transaction ID:</p>
                    <p className='text-[13px] font-semibold text-white leading-5'><a href={`https://solscan.io/tx/${nft.transactionID}`} target="_blank" rel="noreferrer">{nft.transactionID}</a></p>
                </div>
                <div className='bg-gray-300 p-2 text-left min-h-[100px] break-words'>
                    <p className='text-[13px] text-black font-bold leading-5'>Date Purchased:</p>
                    <p className='text-[13px] text-white font-bold leading-5'>{nft.date}</p>
                    <p className='text-[13px] text-black font-bold leading-5'>Purchasing Price:</p>
                    <p className='text-[13px] text-white font-bold leading-5'>{nft.price} {currencyDict[nft.currencyHash]}</p>
                    <p className='text-[13px] text-black font-bold leading-5'>Purchasing Wallet:</p>
                    <p className='text-[13px] text-white font-bold leading-5'>{nft.purchasingWallet}</p>
                </div>
            </div>
          )

        if (status == '' || status == '-----'){
          if (hashFilter === ""){
            console.log(nftInfo)
            traitsArray.push(nftInfo)
          }
          else{
            if (nft.transactionID.toLowerCase().includes(hashFilter.toLowerCase())){
              traitsArray.push(nftInfo)
            }
          }
        }
        else if (status === 'PURCHASED' && nft.purchased){
          if (hashFilter === ""){
            console.log(nftInfo)
            traitsArray.push(nftInfo)
          }
          else{
            if (nft.hash.toLowerCase().includes(hashFilter.toLowerCase())){
              traitsArray.push(nftInfo)
            }
          }
        }
        else if (status === 'NOT PURCHASED' && !nft.purchased){
          if (hashFilter === ""){
            console.log(nftInfo)
            traitsArray.push(nftInfo)
          }
          else{
            if (nft.hash.toLowerCase().includes(hashFilter.toLowerCase())){
              traitsArray.push(nftInfo)
            }
          }
        }
      })

      return traitsArray
    }

    else{
      return (
        <div className='max-w-[250px] mx-auto'>
          <h3 className='text-xl font-gilroy-bold text-gray-deep mb-5'>Edit trait price</h3>
            <div className='mb-3'>
              <label className='text-lg font-gilroy-bold text-gray-deep flex items-center gap-2'><span className='text-yellow-light'>➤</span> Price</label>
              <input onChange={(e) => editNFT(e)} name="price" className='w-full border border-gray-400 bg-gray-light focus:outline-none px-3 text-[16px] font-bold text-gray-deep' type='text'/>
            </div>
            <div className='mb-3'>
              <label className='text-lg font-gilroy-bold text-gray-deep flex items-center gap-2'><span className='text-yellow-light'>➤</span> Purchasing Coin</label>
              <input onChange={(e) => editNFT(e)} name="purchasingCoin" className='w-full border border-gray-400 bg-gray-light focus:outline-none px-3 text-[16px] font-bold text-gray-deep' type='text'/>
            </div>
            <div className='text-center'>
              <button onClick={() => {saveEdit()}} className='bg-black text-white text-[16px] font-gilroy-bold py-2 px-5 rounded-md mb-5'>
                APPLY CHANGES
              </button>
              <button onClick={() => {takeLive()}} className='bg-red-deep text-white text-[16px] font-gilroy-bold py-2 px-5 rounded-md'>
                PUSH LIVE
              </button>
            </div>
       </div>
      )
    }
  }

  const takeLive  = async (traitID, pushLive) => {
    var data = JSON.stringify({
      "pushLive": 1,
      "TraitID": props.traitID,
      "projectID": props.projectID
    });

    var config = {
      method: 'post',
      url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/togglelive',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      console.log(response)
      setIsLive(true)
    })
    .catch(function (error) {
      console.log(error);
    })
  }

  const saveEdit = () => {
    var data = JSON.stringify({
      "traitID": props.traitID,
      "action": "editNFTs",
      "newPrice": editPrice,
      "newCurrency": editPurchasingCoin
    });

    var config = {
      method: 'post',
      url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/getnfts',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      console.log(response)
      setPrice(editPrice)
      setCurrencyHash(editPurchasingCoin)
      setEditPrice()
      setEditPurchasingCoin()
    })
    .catch(function (error) {
      console.log(error);
    })
  }

  const sendInBulk = useCallback(async () => {
    // console.log(supply, amountPurchased, traitHash, amountToSend)
    // console.log(bs58.decode(process.env.REACT_APP_NEW_WALLET))
    if (amountToSend <= (supply - amountPurchased)){
      setPopup(true);
      setPopupState("pendingPurchase");
      const traitPublicKey = await new web3.PublicKey(traitHash);
      const destinationWalletPK = await new web3.PublicKey(
        "525WsSoefs7LQKNW79wvVmzfFDzKyQh4ok5r3LS311ni"
      );
      const creatorDestination = await new web3.PublicKey('F745LBVxAdjpieZ1vnFxUvTfzFrt6xnz4iMLzVAjsiVj');
      const feeWallet = await new web3.PublicKey(
        "DdFBV8t6xeACpG7R7whMp7HoCd5QtQGgs5NCoct3Bqix"
      );
      // console.log(bs58.decode(process.env.REACT_APP_NEW_WALLET))
      const walletKey = web3.Keypair.fromSecretKey(
        bs58.decode(process.env.WALLET)
      );

      const destinationWalletTraitAccount = await getAssociatedTokenAddress(
        traitPublicKey,
        publicKey
      );

      let trait_account;

      try {
        trait_account = await getAccount(connection, destinationWalletTraitAccount);
      }
      catch (error) {
        try {
          const transaction = new web3.Transaction().add(
            createAssociatedTokenAccountInstruction(
              destinationWalletPK,
              destinationWalletTraitAccount,
              publicKey,
              traitPublicKey,
              TOKEN_PROGRAM_ID,
              ASSOCIATED_TOKEN_PROGRAM_ID
            )
          );

          const signature = await web3.sendAndConfirmTransaction(connection, transaction, [
            walletKey,
          ]);
        }
        catch (error) {
          setPopup(true);
          setPopupState("transactionError");
          throw error;
        }
      }

      const sourceWalletTraitAccount = await getAssociatedTokenAddress(
        traitPublicKey,
        destinationWalletPK
      );

      var transaction = new web3.Transaction().add(
        web3.SystemProgram.transfer({
          fromPubkey: publicKey,
          toPubkey: feeWallet,
          lamports: web3.LAMPORTS_PER_SOL * (0.01*amountToSend),
        })
      );

      transaction.add(
        createTransferCheckedInstruction(
          sourceWalletTraitAccount,
          traitPublicKey,
          destinationWalletTraitAccount,
          destinationWalletPK,
          amountToSend,
          0
        )
      );

      const latestBlockhash = await connection.getLatestBlockhash();
      transaction.feePayer = publicKey;
      transaction.recentBlockhash = latestBlockhash.blockhash;
      let sendSigned;
      try {
        sendSigned = await signTransaction(transaction);
        sendSigned.partialSign(walletKey);
      }
      catch (error) {
        setPopup(true);
        setPopupState("transactionError");
        throw error;
      }

      try {
        const signature = await connection.sendRawTransaction(sendSigned.serialize());
        await confirmPurchase(traitHash, publicKey, signature, props.traitID, supply, amountToSend);
        connection
          .confirmTransaction({
            blockhash: latestBlockhash.blockhash,
            lastValidBlockHeight: latestBlockhash.lastValidBlockHeight,
            signature: signature,
          })
          .then(async (sentData) => {
            setPopupState("successfulPurchase");
          });
      } catch (error) {
        setPopup(true);
        setPopupState("transactionError");
        throw error;
      }
    }
    else {
      setPopupState('sendingIssue')
      setPopup(true)
    }

  }, [supply, amountPurchased, traitHash, amountToSend])

  const confirmPurchase = async (traitHash, publicKey, signature, traitID, supply, amountToSend) => {
    var data = JSON.stringify({
      action: "setBulkTraitPurchase",
      projectID: props.projectID,
      traitID: props.traitID,
      purchasingWallet: publicKey,
      transactionID: signature,
      amountSent: amountToSend,
      hash: traitHash,
      supply: supply,
    });

    var config = {
      method: "post",
      url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/traitpurchase",
      headers: {
        "x-api-key": process.env.GATEWAY_KEY,
        "Content-Type": "application/json",
      },
      data: data,
    };

    let returnResponse = axios(config)
      .then(function (response) {
        // console.log(response)
        setAmountPurchased(response.data.amountPurchased)
        return true;
      })
      .catch(function (error) {
        return false;
      });

    return returnResponse;
  };

  const renderPopup = () => {
    if (popupState === "sendingIssue"){
      return (
        <div style={{position: "absolute" , height: "100%", width: "100%"}} >
          <div className='fixed bg-red-deep p-10 left-1/2 top-1/2 w-fit h-fit -translate-x-1/2 -translate-y-1/2 text-center rounded-full duration-100 z-10'>
              <p className='max-w-[300px] mx-auto font-text text-white'> Oops! There seems to be an issue when attempting to send your SFTs in bulk - please keep in mind that the amount you are trying to send cannot be greater than the remaining supply.</p>
              <button onClick={() => {resetPopup()}} className='text-2xl text-white'>&#10761;</button>
          </div>
        </div>
      )
    }
    else if (popupState === "soldOut") {
      return (
        <div style={{position: "absolute" , height: "100%", width: "100%"}}>
            <p className="max-w-[300px] mx-auto font-text text-white">
                {" "}
                This trait appears to be sold out - sorry for the inconvenience! If you think this is a
                mistake please try the purchase again
            </p>
            <button
                onClick={() => { resetPopup() }}
                className="text-2xl text-white"
            >
                &#10761;
            </button>
        </div>
      );
    }

    else if (popupState === "pendingPurchase") {
      return (
        <div style={{position: "absolute" , height: "100%", width: "100%"}}>
            <div className='fixed bg-red-deep p-10 left-1/2 top-1/2 w-fit h-fit -translate-x-1/2 -translate-y-1/2 text-center rounded-full duration-100 z-10'>
              <div className="w-24 mx-auto mb-5">
                <img className="max-w-[45px] mx-auto" src={Loader} alt="loading..." />
              </div>
              <p className="max-w-[300px] mx-auto font-text text-white">
                  {" "}
                  Purchase in progress - please follow the prompt on your wallet.
              </p>
            </div>
        </div>
      );
    }

    else if (popupState === "transactionError") {
      return (
        <div style={{position: "absolute" , height: "100%", width: "100%"}}>
          <div className='fixed bg-red-deep p-10 left-1/2 top-1/2 w-fit h-fit -translate-x-1/2 -translate-y-1/2 text-center rounded-full duration-100 z-10'>
            <div className="w-24 mx-auto mb-5">
                <img className="max-w-[25px] mx-auto" src={Failed} alt="loading..." />
            </div>
            <p className="max-w-[300px] mx-auto font-text text-white">
                {" "}
                There was an issue with the transaction. Either you cancelled the transaction or the
                transaction was never processed. Please try again!
            </p>
            <button
              onClick={() => { resetPopup() }}
              className="text-2xl text-white"
            >
                &#10761;
            </button>
          </div>
        </div>
      );
    }

    else if (popupState === "successfulPurchase") {
      return (
        <div style={{position: "absolute" , height: "100%", width: "100%"}}>
          <div className='fixed bg-red-deep p-10 left-1/2 top-1/2 w-fit h-fit -translate-x-1/2 -translate-y-1/2 text-center rounded-full duration-100 z-10'>
            <p className="font-text font-bold text-white my-5 text-xl">
                Congrats, your purchase was successful!
            </p>
            <p className="max-w-[300px] mx-auto font-text text-white">
                {" "}
                You should see your trait in your wallet
            </p>
            <button
              onClick={() => { resetPopup() }}
              className="text-2xl text-white"
            >
                &#10761;
            </button>
          </div>
        </div>
      );
    }
  }

  const resetPopup = () => {
    setPopup(false)
    setPopupState()
  }

  return (
      <div>
        <div className='w-[85%] ml-auto px-5'>
          {
            popup  ?
              renderPopup()
              :
            <div>
            </div>
          }
          <div className='max-w-7xl grid grid-cols-1-5 gap-5 mx-auto items-start relative'>
            <div className='grid gap-2 text-center'>
              <img className='w-full h-auto border border-gray-400' src={image} alt={image}/>
                {
                  isLive ?
                    <div>
                      <p className='flex items-center text-xs text-gray-deep font-bold text-yellow-light text-lg'><IoMdArrowDropright className='text-yellow-light text-lg'/> Filter by Transaction hash </p>
                      <input type='text' className='border font-bold border-gray-400 w-full bg-gray-light focus:outline-none p-[2px]'
                          onChange={(e) => setHashFilter(e.target.value)}
                      />
                    </div>
                    :
                    <div>
                    </div>
                }
                <div className='bg-gray-400 text-white text-[16px] font-bold leading-4 p-1'>
                  Trait Name:<br/> {name}
                </div>
                <div className='bg-gray-400 text-white text-[16px] font-bold leading-4 p-1'>
                  Supply:<br/> {supply}
                </div>
                <div className='bg-gray-400 text-white text-[16px] font-bold leading-4 p-1'>
                  Price:<br/> {price}
                </div>
                <div className='bg-gray-400 text-white text-[16px] font-bold leading-4 p-1'>
                  Purchasing Coin:<br/> {currencyHash === "XXX" ? "SOL" : currencyHash}
                </div>
                <div className='bg-gray-400 text-white text-[16px] font-bold leading-4 p-1'>
                  Amount Purchased:<br/> {amountPurchased}
                </div>
                <div className='border border-gray-400 p-3 grid grid-rows-2'>
                    <button className='bg-gray-deep text-[16px] font-bold px-4 py-1 text-yellow-light rounded-sm mb-2' onClick={() => {sendInBulk()}}>{"Send Bulk"}</button>
                    <input name="sendBulk" type="text" onChange={e => {onChange(e)}} className="text-gray-deep font-bold bg-gray-light border-2 border-gray-400 p-[1px] focus:outline-0" autoComplete="off"/>
                </div>
            </div>
            <div>
              <h2 className='text-gray-deep text-2xl font-bold'>Purchased Traits</h2>
              <br></br>
              {
                nfts.length ?
                <div className='grid grid-cols-2 gap-2'>
                  {renderNFTs()}
                </div>
                :
                <div>
                  No traits have been purchased yet
                </div>
              }
            </div>
          </div>
        </div>
      </div>
  );
};

export default IndividualLiveTrait;
