import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { Metaplex, keypairIdentity, walletAdapterIdentity, TransactionBuilder } from "@metaplex-foundation/js";
import { updateMetadataAccountArgsV2, createUpdateMetadataAccountV2Instruction, PROGRAM_ID } from "@metaplex-foundation/mpl-token-metadata";
import { Connection, clusterApiUrl, Keypair, PublicKey, SystemProgram, Transaction, sendAndConfirmTransaction, LAMPORTS_PER_SOL } from "@solana/web3.js";
import * as bs58 from "bs58";
import * as web3 from "@solana/web3.js";
import {
  getAccount,
  createTransferCheckedInstruction,
  getAssociatedTokenAddress,
  createAssociatedTokenAccountInstruction,
  TOKEN_PROGRAM_ID,
  ASSOCIATED_TOKEN_PROGRAM_ID,
} from "@solana/spl-token";

import { WalletNotConnectedError } from '@solana/wallet-adapter-base';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';

import EditBuilderConfig from './EditBuilderConfig';
import BuilderHeader from '../Components/BuilderHeader';
import BackLink from '../Components/BackLink';
import builderimg from '../assets/img/builderimg.png'
import BuilderArray from '../Components/BuilderArray';
import EditBuilderImages from './EditBuilderImages'
import TraitImageThumbnail from '../Components/TraitImageThumbnail'
import thumbnailImg from '../assets/img/thumbnail.png';
import { FaRegTimesCircle } from 'react-icons/fa';
import ProgressBar from "@ramonak/react-progress-bar";
import Loader from "../assets/img/loading.gif";
import Failed from "../assets/img/failedtransaction.png";

import lock from "./lock.png"

import axios from "axios";

const UpgradeApprovals = (props) => {
  // console.log(props.projectID)
  const { connection } = useConnection();
  const { publicKey, sendTransaction, signAllTransactions, sendRawTransaction } = useWallet();
  const [nftArray, setNFTArray] = useState([])
  const [selectedNFTs, setSelectedNFTs] = useState([])
  const [newData, setNewData] = useState()
  const [popupState, setPopupState] = useState()
  const [popup, setPopup] = useState(false)
  const [images, setImages] = useState(false)
  const [num, setNum] = useState()
  const [swappedTraitsSend, setSwappedTraitsSend] = useState([])
  const [swap, setSwap] = useState(false)

  useEffect(() => {
    var data = JSON.stringify({
      "action": "getPendingUpgrades",
      "projectID": props.projectID
    });

    var config = {
      method: 'post',
      url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/finalizeupgrades',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      console.log(response)
      setSwap(response.data.swap)
      const nftArray = []
      response.data.upgrades.forEach(upgrade => {
        console.log(upgrade)
        const nftInfo = {
          id: upgrade.id,
          nftHash: upgrade.nftHash,
          attributeDict: upgrade.attributeDict,
          attributeIDDict: upgrade.attributeIDDict,
          identifyingHash: upgrade.identifyingHash,
          traitHash: upgrade.traitHash,
          imageLink: upgrade.imageLink,
          metadataLink: upgrade.metadataLink,
          name: upgrade.name,
          selected: 0,
          upgradeType: upgrade.upgradeType,
          collectionName: upgrade.collectionName,
          digitalTraitID: upgrade.digitalTraitID,
          sortedData: upgrade.sortedData
        }
        nftArray.push(nftInfo)
      }
    )
    setNFTArray(nftArray)
    setImages(true)
    })
    .catch(function (error) {
      console.log(error);
    })
  }, []);

  const resetPage = () => {
    setImages(false)
    setNFTArray([])
    setSelectedNFTs([])
    setNewData()
    var data = JSON.stringify({
      "action": "getPendingUpgrades",
      "projectID": props.projectID
    });

    var config = {
      method: 'post',
      url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/finalizeupgrades',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      setSwap(response.data.swap)
      const nftArray = []
      response.data.upgrades.forEach(upgrade => {
        console.log(upgrade)
        const nftInfo = {
          id: upgrade.id,
          nftHash: upgrade.nftHash,
          attributeDict: upgrade.attributeDict,
          attributeIDDict: upgrade.attributeIDDict,
          identifyingHash: upgrade.identifyingHash,
          traitHash: upgrade.traitHash,
          imageLink: upgrade.imageLink,
          metadataLink: upgrade.metadataLink,
          name: upgrade.name,
          selected: 0,
          upgradeType: upgrade.upgradeType,
          collectionName: upgrade.collectionName,
          digitalTraitID: upgrade.digitalTraitID,
          sortedData: upgrade.sortedData
        }
        nftArray.push(nftInfo)
      }
    )
    setNFTArray(nftArray)
    setImages(true)
    })
    .catch(function (error) {
      console.log(error);
    })
  };

  const selectAll = () => {
    for (const nft of nftArray){
      if (!selectedNFTs.includes(nft.identifyingHash)){
        nft.selected = 1
        setSelectedNFTs(current => [...current, nft.identifyingHash]);
      }
    }
  }

  const selectForUpgrade = (nft) => {
    console.log(nft, nftArray)
    nft.selected = 1
    setSelectedNFTs(current => [...current, nft.identifyingHash]);
  }

  const deselectForUpgrade = (nft) => {
    // console.log(nft.selected)
    nft.selected = 0
    setSelectedNFTs((current) =>
      current.filter((id) => id !== nft.identifyingHash));
  }

  const approveUpgradesNew = async () => {
    setPopupState("upgradingNFTs")
    setPopup(true)
    setNum(nftArray.length)

    try{
      let metaplex = Metaplex.make(connection).use(walletAdapterIdentity(publicKey))

      const bulkUpdate = []
      const allInstructions = []
      var groupedInstructions = []
      var num = nftArray.length

      for (const nft of nftArray){
        if (nft.selected === 1){
          // console.log("3", nft.id)
          // console.log(nft.id)
          const mintAddress = new PublicKey(nft.nftHash)
          const upgradedNFT = await metaplex.nfts().findByMint({mintAddress: mintAddress});
          console.log(upgradedNFT.sellerFeeBasisPoints)
          // console.log(upgradedNFT.updateAuthorityAddress, upgradedNFT.address)

          const input_data = await
          fetch(nft.metadataLink)
          .then((response) => {
              return response.json()
            })
          .catch(function(error) {
              console.log('error')
            });

          console.log(input_data)
          console.log(input_data.image + '?nocache='  + (new Date()).getTime())

          const new_data = {
            name: input_data.name,
            symbol: input_data.symbol ? input_data.symbol : upgradedNFT.symbol ? upgradedNFT.symbol : "SYMBOL",
            uri: nft.metadataLink,
            sellerFeeBasisPoints: upgradedNFT.sellerFeeBasisPoints,
            creators: upgradedNFT.creators,
            collection: upgradedNFT.collection,
            uses: upgradedNFT.uses,
          }
          console.log(new_data)

          const updateInstructionData = {
            data: new_data,
            isMutable: upgradedNFT.isMutable,
            primarySaleHappened: upgradedNFT.primarySaleHappened,
            updateAuthority: upgradedNFT.updateAuthorityAddress
          }

          // console.log(updateInstructionData)
          //
          const instruction = createUpdateMetadataAccountV2Instruction(
            {
              metadata: metaplex.nfts().pdas().metadata({
                mint: upgradedNFT.address,
                PROGRAM_ID,
              }),
              updateAuthority: upgradedNFT.updateAuthorityAddress,
            },
            { updateMetadataAccountArgsV2: updateInstructionData },
            PROGRAM_ID
          )
          allInstructions.push({instruction, nft})
          await new Promise(r => setTimeout(r, 1000));
        }
      }

      // console.log(allInstructions)

      const allTransactions = []
      const nftsInTrans = []
      for (const instruction of allInstructions){
        const transaction = new Transaction().add(
          instruction['instruction']
        )
        nftsInTrans.push(instruction['nft'])

        const latestBlockhash = await connection.getLatestBlockhash();
        transaction.feePayer = publicKey;
        transaction.recentBlockhash = latestBlockhash.blockhash;
        allTransactions.push(transaction)
      }

      // console.log(allTransactions)
      // console.log(nftsInTrans)
      // console.log(nftsInTrans[0].sortedData)
      const signedTransactions = await signAllTransactions(allTransactions).then(
        setPopupState("upgradingNFTs"),
        setPopup(true)
      )

      setPopupState("upgradingNFTs")
      setPopup(true)
      // console.log(signedTransactions)
      // console.log(nftsInTrans[0].sortedData)
      // for (let index = 0; index < signedTransactions.length; index++) {
      //   const signed = signedTransactions[index];
      //   const latestBlockhash = await connection.getLatestBlockhash();
      //   const signature = await connection.sendRawTransaction(signed.serialize());
      //   const sentData = await connection.confirmTransaction({
      //     blockhash: latestBlockhash.blockhash,
      //     lastValidBlockHeight: latestBlockhash.lastValidBlockHeight,
      //     signature: signature,
      //   });
      //
      //   console.log(sentData)
      //   var data = JSON.stringify({
      //     "action": "finalizeUpgrades",
      //     "upgradeID": nftsInTrans[index].id,
      //     "hash": nftsInTrans[index].nftHash,
      //     'attributeDict': nftsInTrans[index].attributeDict,
      //     'metadataLink': nftsInTrans[index].metadataLink,
      //     'projectID': props.projectID,
      //     'attributeIDDict': nftsInTrans[index].attributeIDDict,
      //     'upgradeType': nftsInTrans[index].upgradeType,
      //     'collectionName': nftsInTrans[index].collectionName,
      //     'identifyingHash': nftsInTrans[index].identifyingHash,
      //     'sortedData': nftsInTrans[index].sortedData,
      //     'swap': swap,
      //     'transactionID': signature,
      //     'description': sentData,
      //     'transactionType': 'projectApproval'
      //   });
      //   var config = {
      //     method: 'post',
      //     url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/finalizeupgrades',
      //     headers: {
      //       'x-api-key': process.env.GATEWAY_KEY,
      //       'Content-Type': 'application/json'
      //     },
      //     data: data
      //   };
      //   let returnVar = await axios(config)
      //   .then(function (response) {
      //     console.log(response.data)
      //     num -= 1
      //     setNum(num)
      //     return 1
      //   })
      //   .catch(function (error) {
      //         console.log(error);
      //       })
      //   console.log('got here')
      //
      // }

      let allSavedUpdates = []
      await Promise.all(signedTransactions.map(async (signed, index) => {
        const latestBlockhash = await connection.getLatestBlockhash();
        const signature = await connection.sendRawTransaction(signed.serialize());
        await connection.confirmTransaction({
            blockhash: latestBlockhash.blockhash,
            lastValidBlockHeight: latestBlockhash.lastValidBlockHeight,
            signature: signature,
          }).then(async (sentData) => {
            console.log(signature, sentData)
            var data = JSON.stringify({
              "action": "finalizeUpgradesTest",
              "upgradeID": nftsInTrans[index].id,
              "hash": nftsInTrans[index].nftHash,
              'attributeDict': nftsInTrans[index].attributeDict,
              'metadataLink': nftsInTrans[index].metadataLink,
              'projectID': props.projectID,
              'attributeIDDict': nftsInTrans[index].attributeIDDict,
              'upgradeType': nftsInTrans[index].upgradeType,
              'collectionName': nftsInTrans[index].collectionName,
              'identifyingHash': nftsInTrans[index].identifyingHash,
              'sortedData': nftsInTrans[index].sortedData,
              'swap': swap,
              'transactionID': signature,
              'description': sentData,
              'transactionType': 'projectApproval'
            });
            var config = {
              method: 'post',
              url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/finalizeupgrades',
              headers: {
                'x-api-key': process.env.GATEWAY_KEY,
                'Content-Type': 'application/json'
              },
              data: data
            };
            await axios(config)
            .then(function (response) {
              console.log(response.data.finalData)
              allSavedUpdates.push(response.data.finalData)
              num -= 1
              setNum(num)
            })
            .catch(function (error) {
              console.log(error);
            })
        })
      }))

      if (allSavedUpdates.length > 0){
        var data = JSON.stringify({
          "action": "updateHashURL",
          "allSavedUpdates": allSavedUpdates,
          "projectID": props.projectID
        });
        var config = {
          method: 'post',
          url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/finalizeupgrades',
          headers: {
            'x-api-key': process.env.GATEWAY_KEY,
            'Content-Type': 'application/json'
          },
          data: data
        };
        await axios(config)
        .then(function (response) {
          console.log(response)
        })
        .catch(function (error) {
          console.log(error);
        })

      }


      console.log('finalized')
      resetPopup()
      resetPage()
    }
    catch(error){
      console.log(error)
      setPopupState("failed")
    }
  }

  const renderPopup = () => {
    if (popupState === "upgradingNFTs"){
      return(
        <div style={{position: "absolute" , height: "100%", width: "100%"}} >
          <div className='fixed bg-red-deep p-10 left-1/2 top-1/2 w-fit h-fit -translate-x-1/2 -translate-y-1/2 text-center rounded-full duration-100 z-10'>
            <div className='w-24 mx-auto mb-5'>
                <img className='max-w-[25px] mx-auto' src={Loader} alt="loading..."/>
            </div>
              <p className='max-w-[300px] mx-auto font-text text-white'> The NFTs are currently upgrading! Please dont leave this page until they are completed.</p>
              <br></br>
              <p className='max-w-[300px] mx-auto font-text text-white'>{num}/{nftArray.length} left to upgrade.</p>
          </div>
        </div>
      )
    }
    else if (popupState === "failed"){
      return(
        <div style={{position: "absolute" , height: "100%", width: "100%"}} >
          <div className='fixed bg-red-deep p-10 left-1/2 top-1/2 w-fit h-fit -translate-x-1/2 -translate-y-1/2 text-center rounded-full duration-100 z-10'>
              <div className="w-24 mx-auto mb-5">
                <img className="max-w-[25px] mx-auto" src={Failed} alt="loading..." />
              </div>
              <p className='max-w-[300px] mx-auto font-text text-white'> Some NFTs might have failed to upgrade please make sure you are connected with the wallet that has update authority for the collection and try again!</p>
              <br></br>
              <p className='max-w-[300px] mx-auto font-text text-white'>If the issue persists please contact support in our discord.</p>
              <button
                onClick={() => {
                  resetPopup();
                }}
                className="text-2xl text-white"
              >
                &#10761;
              </button>
          </div>
        </div>
      )
    }
  }

  const resetPopup = () => {
    setPopup(false)
    setPopupState()
  }

  const renderImages = () => {
    return(
      <>
      {
        nftArray.length ?
          <>
          <div className='grid grid-cols-4 gap-7 mb-10'>
            {
              nftArray.map((nft) => {
                if (selectedNFTs.includes(nft.identifyingHash)){
                  return(
                    <div className='relative'>
                        <img className='w-full h-auto border-2 border-red-deep mb-1' src={`${nft.imageLink}?${new Date().getTime()}`}/>
                        <button onClick={() => deselectForUpgrade(nft)} type='submit' className='absolute right-2 top-2 text-gray-deep text-xl'>{<FaRegTimesCircle/>}</button>
                        <p className='bg-yellow-light px-1 w-full text-[16px] text-gray-deep uppercase font-gilroy-bold mb-1 text-center'>{nft.name}</p>
                    </div>
                  )
                }
                else{
                  return(
                    <div className='relative'>
                        <img onClick={() => selectForUpgrade(nft)} className='w-full h-auto border border-gray-400 mb-1' src={`${nft.imageLink}?${new Date().getTime()}`}/>
                        <p className='bg-yellow-light px-1 w-full text-[16px] text-gray-deep uppercase font-gilroy-bold mb-1 text-center'>{nft.name}</p>
                    </div>
                  )
                }
              })
            }
          </div>
          <button disabled={selectedNFTs.length == 0} className="text-white bg-red-light bg-yellow-light font-gilroy-bold text-lg py-1 px-7 align-bottom rounded-md mb-5 text-[18px]" onClick={() => approveUpgradesNew()}>
            APPROVE SELECTED UPGRADES
          </button>
          </>
        :
        <div>
        </div>
      }
      </>
    )
  }

  return (

      <div className='w-[80%] ml-auto px-5'>
          <div className='max-w-5xl mr-auto bg-gray-200 min-h-[560px] p-5 text-left'>
              {
                popup ?
                  renderPopup()
                  :
                <>
                </>
              }
              <div className='mb-10'>
                  {
                    nftArray.length ?
                    <>
                    <h2 className='text-xl font-gilroy-bold text-gray-deep mb-5 text-left'>Approve Upgrades</h2>
                    <button disabled={selectedNFTs.length == nftArray.length} className="text-white bg-yellow-light font-gilroy-bold text-lg py-1 px-7 align-bottom rounded-md mb-5 text-[18px] object-left" onClick={() => selectAll()}>
                      Select All
                    </button>
                    </>
                    :
                    <>
                    <h2 className='text-xl font-gilroy-bold text-gray-deep mb-5 text-left'>No Upgrades Available</h2>
                    </>
                  }
              </div>
              {
                nftArray.length ?
                  <>
                  <div className='grid grid-cols-4 gap-7 mb-10'>
                    {
                      nftArray.map((nft) => {
                        if (selectedNFTs.includes(nft.identifyingHash)){
                          return(
                            <div className='relative'>
                                <img className='w-full h-auto border-2 border-red-deep mb-1' src={`${nft.imageLink}?${new Date().getTime()}`}/>
                                <button onClick={() => deselectForUpgrade(nft)} type='submit' className='absolute right-2 top-2 text-gray-deep text-xl'>{<FaRegTimesCircle/>}</button>
                                <p className='bg-yellow-light px-1 w-full text-[16px] text-gray-deep uppercase font-gilroy-bold mb-1 text-center'>{nft.name}</p>
                            </div>
                          )
                        }
                        else{
                          return(
                            <div className='relative'>
                                <img onClick={() => selectForUpgrade(nft)} className='w-full h-auto border border-gray-400 mb-1' src={`${nft.imageLink}?${new Date().getTime()}`}/>
                                <p className='bg-yellow-light px-1 w-full text-[16px] text-gray-deep uppercase font-gilroy-bold mb-1 text-center'>{nft.name}</p>
                            </div>
                          )
                        }
                      })
                    }
                  </div>
                  <button disabled={selectedNFTs.length == 0} className="text-white bg-red-light hover:bg-yellow-light font-gilroy-bold text-lg py-1 px-7 align-bottom rounded-md mb-5 text-[18px]" onClick={() => approveUpgradesNew()}>
                    APPROVE SELECTED UPGRADES
                  </button>
                  </>
                :
                <div>
                </div>
              }
          </div>
      </div>

  )

}

export default UpgradeApprovals;
