import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { WalletNotConnectedError } from '@solana/wallet-adapter-base';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';

import axios from "axios";

import AdminDash from "./AdminDash"
import WelcomePage from "./WelcomePage"
import LandingPageLogo from '../assets/img/main-logo.png'



const Blockade = (props) => {
  const [page, setPage] = useState("dash")
  const [approved, setApproved] = useState(false)
  const [projectID, setProjectID] = useState()
  const [projectName, setProjectName] = useState()
  const [creatorWallet, setCreatorWallet] = useState()
  const [onboarding, setOnboarding] = useState()
  const [collectionHash, setCollectionHash] = useState()
  const [hasMutation, setHasMutation] = useState()
  const [hasTraitSwaps, setHasTraitSwaps] = useState()
  const [hasSlotMachine, setHasSlotMachine] = useState()
  const [slotMachineReady, setSlotMachineReady] = useState()
  const [projectDict, setProjectDict] = useState([])

  const { connection } = useConnection();
  const { publicKey, sendTransaction } = useWallet();


  useEffect(() => {
    if (publicKey){
      var data = JSON.stringify({
        "publicKey": publicKey.toBase58()
      });

      var config = {
        method: 'post',
        url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/confirmclient',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
      .then(function (response) {
        console.log(response)
        if (response.data.isClient){
          setProjectDict(response.data.data)
          // setProjectID(response.data.data.projectID)
          // setProjectName(response.data.data.projectName)
          // setCreatorWallet(response.data.data.creatorWallet)
          // setCollectionHash(response.data.data.collectionHash)
          // setOnboarding(response.data.data.completedOnboarding)
          // setHasMutation(response.data.data.hasMutation)
          // setHasTraitSwaps(response.data.data.swapTraits)
          // setHasSlotMachine(response.data.data.hasSlotMachine)
          // setSlotMachineReady(response.data.data.slotMachineReady)
          setApproved(true)
        }
        else{
          setApproved(false)
        }
      })
      .catch(function (error) {
        console.log(error);
      })
    }

  }, [publicKey]);

  return (
    <div>
      { publicKey ?
        <div>
          {
            approved ?
              <WelcomePage projectDict={projectDict} collectionHash={collectionHash} onboarding={onboarding} projectID={projectID} projectName={projectName} creatorWallet={creatorWallet} hasMutation={hasMutation} hasTraitSwaps={hasTraitSwaps} hasSlotMachine={hasSlotMachine} slotMachineReady={slotMachineReady}/>
              :
              <div className='bg-gray-light py-32 w-full'>
                  <div className='container mx-auto px-5'>
                      <div className='text-center max-w-md px-10 py-5 mx-auto bg-gray-deep'>
                          <p className='text-lg text-yellow-light uppercase font-semibold mb-5'>Oops! Theres seems to have been a mistake
                          </p>
                          <p className='text-lg text-yellow-light mb-10'>This wallet is not in our system - please select a new wallet or contact us if this is a mistake</p>
                      </div>
                  </div>
              </div>
          }
        </div>
        :
        <div className='bg-gray-light py-32 w-full'>
            <div className='container mx-auto px-5'>
                <div className='max-w-[200px] mx-auto mb-10'>
                    <img className='w-full h-auto' src={LandingPageLogo} alt="Landing Page Logo"/>
                </div>
                <div className='text-center max-w-md mx-auto'>
                    <div className=' bg-gray-deep px-5 py-1 text-lg text-white uppercase'to="/welcome"> Please
                      <span className='text-yellow-light'> Connect your Wallet</span> to Get Started
                    </div>
                    <p className='mt-5 text-lg text-gray-deep'>
                      The <b>the body shop creator portal</b> powered by maxin' has two main utilities right now - a customizable B2B <b>trait shop </b>
                      and a robust collection builder designed to help creator and artists produce  the best collection imaginable.
                    </p>
                </div>
            </div>
        </div>
      }
    </div>
  );
};

export default Blockade;
