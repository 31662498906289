import React from 'react'


const CreatedTraitsLiveScroll = ({src,alt,traitNameTitle,traitName,traitSupply,
    traitSupplyStats,traitAmount, traitAmountStats, traitsRemaining, traitsRemainingStats, traitOdds, traitsOddsStats, traitsCategory, traitsCategoryStats}) => {
  return (
    <div className='grid gap-2 text-center'>
        <img className='w-full h-auto border border-gray-400' src={src} alt={alt}/>
        <div className='bg-gray-400 text-white text-[16px] font-bold leading-4 p-1'>
            {traitNameTitle}<br/> {traitName}
        </div>
        <div className='bg-gray-400 text-white text-[16px] font-bold leading-4 p-1'>{traitsCategory}<br/>{traitsCategoryStats}</div>
        <div className='bg-gray-400 text-white text-[16px] font-bold leading-4 p-1'>
            {traitSupply}<br/> {traitSupplyStats}</div>
        <div className='bg-gray-400 text-white text-[16px] font-bold leading-4 p-1'>{traitsRemaining}<br/>{traitsRemainingStats}</div>
    </div>
  )
}

export default CreatedTraitsLiveScroll
