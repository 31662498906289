import React, {useState, useEffect} from "react";
import Arweave from 'arweave';
import fs from 'fs';
import IndividualPendingNFT from "./IndividualPendingNFT";
import axios from "axios";
import { uploadFile } from 'react-s3';
import S3 from 'react-aws-s3';
import Loader from "../assets/img/loading.gif"
window.Buffer = window.Buffer || require("buffer").Buffer;

const AddSMTraits = (props) => {
  console.log(props)
  const [page, setPage] = useState("addTrait")
  const [creatingNFTs, setCreatingNFTs] = useState(false)

  const [assetDict, setAssetDict] = useState({})
  const [selectedCategory, setSelectedCategory] = useState()
  const [selectedTrait, setSelectedTrait] = useState()
  const [builderStates, setBuilderStates] = useState([])

  const [awsUploadImage, setAWSUploadImage] = useState()
  const [awsUploadVideo, setAWSUploadVideo] = useState()

  const [imageType, setImageType] = useState()
  const [videoType, setVideoType] = useState()
  const [name, setName] = useState()
  const [symbol, setSymbol] = useState()
  const [externalLink, setExternalLink] = useState()
  const [supply, setSupply] = useState()
  const [sellerFee, setSellerFee] = useState()
  const [description, setDescription] = useState()
  const [odds, setOdds] = useState()
  const [price, setPrice] = useState()
  const [purchasingCoin, setPurchasingCoin] = useState()
  const [traitCategory, setTraitCategory] = useState()
  const [radioType, setRadioType] = useState()
  const [traitMetadataName, setTraitMetadataName] = useState()
  const [radioCurrencyType, setRadioCurrencyType] = useState()
  const [cosmetic, setCosmetic] = useState('normal')
  const [collectionName, setCollectionName] = useState("Base")

  const [newTraitFile, setNewTraitFile] = useState()
  const [newTraitFileType, setNewTraitFileType] = useState()

  const [attributeCount, setAttributeCount] = useState(1)
  const [attributeList, setAttributeList] = useState([{trait_type: "", value: ""}])
  const [creatorAddress, setCreatorAddress] = useState("HhDTCmNWfgd5YWZd4obr5Ui6CDnBfUD8iKNA5jvFmxrK")

  const [identifyingHash, setIdentifyingHash] = useState()

  const [readyToCreate, setReadyToCreate] = useState(false)

  const [popupState, setPopupState] = useState()
  const [popup, setPopup] = useState(false)

  useEffect(() => {
    var data = JSON.stringify({
      "projectID": props.projectID,
      "action": "getAssetDict2"
    });

    // // console.log(data)

    var config = {
      method: 'post',
      url: 'https://59200uzilj.execute-api.us-east-1.amazonaws.com/Production/renderimage',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      // // console.log(response)
      setAssetDict(response.data.assetDict)
      setBuilderStates(Object.keys(response.data.assetDict))
      setSelectedCategory(Object.keys(response.data.assetDict[collectionName])[0])
    })
    .catch(function (error) {
      console.log(error);
    })
  }, []);

  useEffect(() => {
    setReadyToCreate(false)
  }, [selectedTrait]);

  const onChange = (e) => {
    setReadyToCreate(false)

    if (e.target.files) {
      const files = e.target.files
      if (e.target.name === "mp4"){
        setAWSUploadVideo(files[0])
        setVideoType(files[0].type.replace("video/",""))
      }
      else if (e.target.name === "png"){
        // console.log(files[0])
        setAWSUploadImage(files[0])
        setImageType(files[0].type.replace("image/",""))
      }
      else if (e.target.name === "newTraitFile"){
        setNewTraitFile(files[0])
        setNewTraitFileType(files[0].type.replace("image/",""))
      }
    }

    else{
      if (e.target.name === "name"){
        setName(e.target.value)
      }
      else if (e.target.name === "supply"){
        setSupply(e.target.value)
      }
      else if (e.target.name === "odds"){
        setOdds(e.target.value)
      }
      else if (e.target.name === 'traitCategory'){
        setTraitCategory(e.target.value)
      }
      else if (e.target.name === 'traitMetadataName'){
        setTraitMetadataName(e.target.value)
      }
      else if (e.target.name === "uploadNew" || e.target.name === "selectTrait"){
        setRadioType(e.target.name)
      }
      else if (e.target.name === "normal" || e.target.name === "cosmetic"){
        setCosmetic(e.target.name)
      }
    }
  }

  const checkData = () => {
    if (imageType && name && supply && radioType && cosmetic){
      if(selectedTrait || (selectedCategory && traitMetadataName)){
        setReadyToCreate(true)
      }
      else{
        setPopupState("formIssue")
        setPopup(true)
      }
    }
    else{
      setPopupState("formIssue")
      setPopup(true)
    }
  }

  const randomHash = (length) => {
    let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let str = '';
    for (let i = 0; i < length; i++) {
        str += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return str;
  }

  const uploadAssets = async () => {
    setPopupState("uploadingAssets")
    setPopup(true)
    let identifyingHashTemp = randomHash(50)

    let imageName = "traitImages/" + props.projectID + "/" + collectionName + "/" + identifyingHashTemp
    // // console.log(imageName)
    let imageLink = "https://maxinbodyshop.s3.us-east-2.amazonaws.com/" + imageName + "." + imageType
    // console.log(imageLink)

    const s3Config = {
      bucketName:"maxinbodyshop",
      region: "us-east-2",
      accessKeyId: process.env.AWS_KEY,
      secretAccessKey: process.env.AWS_SECRET,
      s3Url: 'https://maxinbodyshop.s3.amazonaws.com'
    }

    const ReactS3Client = new S3(s3Config);

    const imageUpload = await ReactS3Client.uploadFile(awsUploadImage, imageName)

    let videoLink
    if (awsUploadVideo){
      let videoName = "traitVideos/" + props.projectID + "/" + collectionName + "/" + identifyingHashTemp
      videoLink = "https://maxinbodyshop.s3.us-east-2.amazonaws.com/" + videoName + "." + videoType
      const videoUpload = await ReactS3Client.uploadFile(awsUploadVideo, videoName)
    }

    if (radioType === "uploadNew"){
      let newTraitName = "builder/" + props.projectID + "/" + collectionName + "/" + selectedCategory.replaceAll(" ", "_") + "/" + traitMetadataName.replaceAll(" ", "_")
      const newTraitUpload = await ReactS3Client.uploadFile(newTraitFile, newTraitName)
      const link = "https://maxinbodyshop.s3.us-east-2.amazonaws.com/" + newTraitName + ".png"

      var data = JSON.stringify({
        "projectID": props.projectID,
        "collectionName": collectionName,
        "category": selectedCategory,
        "traitName": traitMetadataName,
        "link": link,
        "action": "addImage"
      });

      var config = {
        method: 'post',
        url: 'https://59200uzilj.execute-api.us-east-1.amazonaws.com/Production/uploadimages',
        headers: {
          'x-api-key': process.env.GATEWAY_KEY,
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
      .then(function (response) {
        console.log(response.data.assetID, ReactS3Client, identifyingHashTemp, imageLink, videoLink)
        uploadTrait(response.data.assetID, ReactS3Client, identifyingHashTemp, imageLink, videoLink)
      })
      .catch(function (error) {
        console.log(error);
      })
    }
    else{
      console.log(JSON.parse(selectedTrait).id, ReactS3Client, identifyingHashTemp, imageLink, videoLink)
      uploadTrait(JSON.parse(selectedTrait).id, ReactS3Client, identifyingHashTemp, imageLink, videoLink)
    }
  }

  const uploadTrait = (assetID, reactS3Client, identifyingHashTemp, imageLink, videoLink) => {
    console.log(props.projectID, collectionName, identifyingHashTemp, name, imageLink, selectedCategory, supply, assetID, cosmetic,odds)
    var data = JSON.stringify({
      "action": "setSlotTraits",
      "projectID": props.projectID,
      "collectionName": collectionName,
      "identifyingHash": identifyingHashTemp,
      "name": name,
      "imageLink": imageLink,
      "videoLink": videoLink ? videoLink : "",
      "traitCategory": selectedCategory,
      "supply": supply,
      "assetID": assetID,
      "cosmetic": cosmetic,
      "odds": 1
    });

    var config = {
      method: 'post',
      url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/setnewnfts',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      console.log(response)
      setPopupState('traitCreated')
    })
    .catch(function (error) {
      console.log(error);
    })

  }

  const renderSelectExisting = () => {
    const categoryArray = []

    categoryArray.push(
      <select className="bg-gray-light border-2 border-gray-400 font-bold text-[18px] px-2 uppercase cursor-pointer text-grey-deep w-full h-12 leading-8" value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
          {Object.keys(assetDict[collectionName]).map((category) => (
              <option value={category} className="bg-gray-400 text-[16px] font-bold uppercase hover:bg-gray-deep cursor-pointer text-white hover:text-yellow-light px-2 py-[2px]">{category}</option>
          ))}
      </select>
    )

    const traitArray = []
    traitArray.push(
      <select className="bg-gray-light border-2 border-gray-400 font-bold text-[18px] px-2 uppercase cursor-pointer text-grey-deep w-full h-12 leading-8" value={selectedTrait} onChange={(e) => setSelectedTrait(e.target.value)}>
        <option className="bg-gray-400 text-[16px] font-bold uppercase hover:bg-gray-deep cursor-pointer text-white hover:text-yellow-light px-2 py-[2px]">----------------</option>
        {assetDict[collectionName][selectedCategory].map(trait => (
            <option value={JSON.stringify(trait)} className="bg-gray-400 text-[16px] font-bold uppercase hover:bg-gray-deep cursor-pointer text-white hover:text-yellow-light px-2 py-[2px]">{trait.traitName}</option>
        ))}
      </select>
    )

    return (
      <div className='flex justify-between items-start'>
        {categoryArray}
        {traitArray}
      </div>
    )
  }

  const renderPopup = () => {
    if (popupState === "uploadingAssets"){
      return(
        <div style={{position: "absolute" , height: "100%", width: "100%"}} >
          <div className='fixed bg-red-deep p-10 left-1/2 top-1/2 w-fit h-fit -translate-x-1/2 -translate-y-1/2 text-center rounded-full duration-100 z-10'>
              <div className='w-24 mx-auto mb-5'>
                  <img className='max-w-[25px] mx-auto' src={Loader} alt="loading..."/>
              </div>
              <p className='max-w-[300px] mx-auto font-text text-white'> Your traits are being uploaded - this shouldn't take too long</p>
          </div>
        </div>
      )
    }
    else if (popupState === "formIssue"){
      return (
        <div style={{position: "absolute" , height: "100%", width: "100%"}} >
          <div className='fixed bg-red-deep p-10 left-1/2 top-1/2 w-fit h-fit -translate-x-1/2 -translate-y-1/2 text-center rounded-full duration-100 z-10'>
              <p className='max-w-[300px] mx-auto font-text text-white'> Oops! It seems you are missing input values - please review your trait form carefully and try again.</p>
              <button onClick={() => {resetPopup()}} className='text-2xl text-white'>&#10761;</button>
          </div>
        </div>
      )
    }
    else if (popupState === "currencyIssue"){
      return(
        <div style={{position: "absolute" , height: "100%", width: "100%"}} >
          <div className='fixed bg-red-deep p-10 left-1/2 top-1/2 w-fit h-fit -translate-x-1/2 -translate-y-1/2 text-center rounded-full duration-100 z-10'>
              <p className='max-w-[300px] mx-auto font-text text-white'> Oops! It seems you have an input error surrounding your purchasing information. Please ensure that you are inputting a whole number for price if purchasing coin is an SPL Token.</p>
              <button onClick={() => {resetPopup()}} className='text-2xl text-white'>&#10761;</button>
          </div>
        </div>
      )
    }
    else if (popupState === "traitCreated"){
      return(
        <div style={{position: "absolute" , height: "100%", width: "100%"}} >
          <div className='fixed bg-red-deep p-10 left-1/2 top-1/2 w-fit h-fit -translate-x-1/2 -translate-y-1/2 text-center rounded-full duration-100 z-10'>
            <p className='max-w-[300px] mx-auto font-text text-white mb-3'> Congrats! Your Trait has been created, you can click on 'VIEW TRAITS' or 'CONTINUE' to keep creating traits for your slot machine.</p>
            <button className='bg-gray-deep text-yellow-light font-semibold items-start text-xl rounded-sm px-5 py-1 mb-5' onClick={() => {props.resetTrait("createdSlot")}}> VIEW TRAITS </button>
            <div>
              <button onClick={() => {resetPopup()}} className='bg-gray-deep text-yellow-light font-semibold items-start text-xl rounded-sm px-5 py-1'>CONTINUE</button>
            </div>
          </div>
        </div>
      )
    }
  }

  const resetPopup = () => {
    setPopup(false)
    setPopupState()
  }

  return(
    <div>
      <div className='w-[80%] ml-auto px-5'>
        <div className='max-w-xl ml-36 mr-auto mb-5 relative grid gap-7'>
          {
            popup  ?
              renderPopup()
              :
            <div>
            </div>
          }
          {
            builderStates.length ?
              <div>
                <select className="bg-gray-light border-2 border-gray-400 font-bold text-[18px] px-2 uppercase cursor-pointer text-grey-deep w-[30%] h-12 leading-8" value={collectionName} onChange={(e) => setCollectionName(e.target.value)}>
                    {builderStates.map((collectionNameTemp) => (
                        <option value={collectionNameTemp} className="bg-gray-400 text-[16px] font-bold uppercase hover:bg-gray-deep cursor-pointer text-white hover:text-yellow-light px-2 py-[2px]">{collectionNameTemp}</option>
                    ))}
                </select>
              </div>
              :
              <div>
              </div>
          }
          <div className='flex justify-between items-start'>
            <div className='text-center mr-5'>
              <div>
                <h3 className='font-gilroy-bold text-xl text-gray-deep'>Upload Image</h3>
                <p className='text-lg text-gray-400 font-medium'>seen in front end of your trait marketplace</p>
                <input onChange={e => {onChange(e)}} name="png" accept="image/*" type="file" className="text-gray-deep font-bold" />
              </div>
            </div>
            <div className='text-center ml-5'>
              <div>
                <h3 className='font-gilroy-bold text-xl text-gray-deep'>Upload Video</h3>
                <p className='text-lg text-gray-400 font-medium'>this field is optional</p>
                <input onChange={e => {onChange(e)}} name="mp4" accept="video/*" type="file" className="text-gray-deep font-bold" />
              </div>
            </div>
          </div>
          <div className='text-center'>
            <h3 className='font-gilroy-bold text-xl text-gray-deep'>Define the collection artwork attached to this trait</h3>
            <p className='text-lg text-gray-400 font-medium'>you can upload a new image or use an existing trait</p>
            <br></br>
            <div className='flex justify-between w-[60%] m-auto '>
              <div className='text-center'>
                <input className='font-gilroy-bold text-xl text-gray-deep' onChange={e => {onChange(e)}} checked={radioType === "selectTrait"} type="radio" value="selectTrait" name="selectTrait" />
                <p className='text-lg text-gray-400 font-medium'>Select Existing Trait</p>
              </div>
              <div className='text-center'>
                <input className='font-gilroy-bold text-xl text-gray-deep' onChange={e => {onChange(e)}} checked={radioType === "uploadNew"} type="radio" value="uploadNew" name="uploadNew" />
                <p className='text-lg text-gray-400 font-medium'>Upload a New Trait</p>
              </div>
            </div>
            <br></br>
            {
              radioType ?
                radioType === "uploadNew" ?
                  <div>
                    <div className='text-center'>
                      <h3 className='font-gilroy-bold text-xl text-gray-deep'>Upload Collection Artwork</h3>
                      <p className='text-lg text-gray-400 font-medium'>this is the linked artwork to your collection</p>
                      <input onChange={e => {onChange(e)}} name="newTraitFile" accept="image/*" type="file" className="text-gray-deep font-bold" />
                    </div>
                    <br></br>
                    <div className='text-center'>
                      <div>
                        <h3 className='font-gilroy-bold text-xl text-gray-deep'>Metadata Name</h3>
                        <p className='text-lg text-gray-400 font-medium'>this is how the trait will appear in the metadata</p>
                        <input name="traitMetadataName" type="text" onChange={e => {onChange(e)}} className="text-gray-deep font-bold bg-gray-light w-[40%] border-2 border-gray-400 p-[1px] focus:outline-0" />
                      </div>
                    </div>
                    <br></br>
                    <div className='flex flex-col w-[40%] m-auto relative'>
                      <h3 className='font-gilroy-bold text-xl text-gray-deep'>Trait Category</h3>
                      <select className="bg-gray-light border-2 border-gray-400 font-bold text-[18px] px-2 uppercase cursor-pointer text-grey-deep w-full h-12 leading-8" value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
                          {Object.keys(assetDict[collectionName]).map((category) => (
                              <option value={category} className="bg-gray-400 text-[16px] font-bold uppercase hover:bg-gray-deep cursor-pointer text-white hover:text-yellow-light px-2 py-[2px]">{category}</option>
                          ))}
                      </select>
                    </div>
                  </div>
                  :
                  <div>
                    { !selectedTrait ?
                      renderSelectExisting()
                      :
                      <div className='text-center'>
                        <div>
                          <h3 className='font-gilroy-bold text-xl text-gray-deep'>The trait NFT will map to this collection trait</h3>
                          <br></br>
                          <div className='grid grid-cols-2 m-auto bg-gray-300 gap-2 p-5 w-[40%]'>
                            <div className='flex flex-col relative'>
                              <div className="bg-gray-400 relative font-bold text-[16px] px-2 uppercase cursor-pointer text-white w-full h-7 leading-8">
                                Category
                              </div>
                              <p className='text-gray-deep text-lg p-1 leading-normal'>{JSON.parse(selectedTrait).category}</p>
                            </div>
                            <div className='flex flex-col relative'>
                              <div className="bg-gray-400 relative font-bold text-[16px] px-2 uppercase cursor-pointer text-white w-full h-7 leading-8">
                                  Trait Name
                              </div>
                              <p className='text-gray-deep text-lg p-1 leading-normal'>{JSON.parse(selectedTrait).traitName}</p>
                            </div>
                          </div>
                          <br></br>
                          <button onClick={() => {setSelectedTrait()}} className="bg-gray-deep text-white py-2 w-20 font-bold text-sm text-center rounded-md">
                            Edit Trait
                          </button>
                        </div>
                      </div>
                    }
                  </div>
                :
                <div>
                </div>
            }
          </div>
          <div className='text-center'>
            <div>
              <h3 className='font-gilroy-bold text-xl text-gray-deep'>Name of Trait</h3>
              <p className='text-lg text-gray-400 font-medium'>this will appear in the metadata of the NFT</p>
              <input name="name" type="text" maxLength={26} onChange={e => {onChange(e)}} className="text-gray-deep font-bold bg-gray-light w-[40%] border-2 border-gray-400 p-[1px] focus:outline-0" autoComplete="off"/>
            </div>
          </div>
          <div className='text-center'>
            <div>
              <h3 className='font-gilroy-bold text-xl text-gray-deep'>Supply Cap</h3>
              <p className='text-lg text-gray-400 font-medium'>the number of max traits that the slot machine can produce.</p>
              <input name="supply" type="text" onChange={e => {onChange(e)}} className="text-gray-deep font-bold bg-gray-light w-[40%] border-2 border-gray-400 p-[1px] focus:outline-0" autoComplete="off"/>
            </div>
          </div>
          <div className='text-center'>
            {
              readyToCreate ?
              <button onClick={() => {uploadAssets()}} className="bg-red-deep text-white py-2 w-36 font-bold text-lg text-center rounded-md">
                Submit!
              </button>
              :
              <button onClick={() => {checkData()}} className="bg-gray-deep text-white py-2 w-44 font-bold text-lg text-center rounded-md">
                Ready to Submit?
              </button>
            }

          </div>
        </div>
      </div>
    </div>
  )
};

export default AddSMTraits;
