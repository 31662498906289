import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { WalletNotConnectedError } from '@solana/wallet-adapter-base';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { FaTrashAlt } from 'react-icons/fa';

import axios from "axios";

const EditCategoryConflicts = (props) => {

  const [builderState, setBuilderState] = useState("Base")
  const [builderStates, setBuilderStates] = useState([])

  const [orderArrayDict, setOrderArrayDict] = useState({})

  const [conflictDict, setConflictDict] = useState({})
  const [conflicts, setConflicts] = useState([])
  const [selectedConflict, setSelectedConflict] = useState()
  const [addNew, setAddNew] = useState(false)
  const [edit, setEdit] = useState(false)

  const [assetDict, setAssetDict] = useState({})

  const [editConflict, setEditConflict] = useState()
  const [editConflictTraitArray, setEditConflictTraitArray] = useState()

  const [conflictName, setConflictName] = useState("")
  const [firstCategory, setFirstCategory] = useState()
  const [secondCategory, setSecondCategory] = useState()

  const [categories, setCategories] = useState([])
  const [categoriesSubsection, setCategoriesSubsection] = useState([])

  const [firstConflict, setFirstConflict] = useState()

  const [conflictTraits, setConflictTraits] = useState([])

  useEffect(() => {
    var data = JSON.stringify({
      "projectID": props.projectID,
      "action": "getCatConflicts",
    });

    var config = {
      method: 'post',
      url: 'https://59200uzilj.execute-api.us-east-1.amazonaws.com/Production/updatebuilderconfig',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      console.log(response)
      setConflictDict(response.data.conflicts)
      setOrderArrayDict(response.data.categoryOrderArray)
      setBuilderStates(Object.keys(response.data.categoryOrderArray))
      setFirstCategory(response.data.categoryOrderArray[builderState][0])
      setSecondCategory(response.data.categoryOrderArray[builderState][1])
      setCategories(response.data.categoryOrderArray[builderState])
      setCategoriesSubsection(response.data.categoryOrderArray[builderState])
      setAssetDict(response.data.assetDict)
    })
    .catch(function (error) {
      console.log(error);
    })
  }, []);

  useEffect(() => {
    if (firstCategory === secondCategory){
      setSecondCategory(categoriesSubsection.find(element => {
        return element !== firstCategory
      }))
    }
    setCategoriesSubsection(categories.filter(category => {
      return category !== firstCategory
    }))
    setConflictTraits([])

  }, [firstCategory]);

  const onChange = (e) => {
    if (e.target.name === "conflictName"){
        setConflictName(e.target.value)
    }
  }

  const addToConflict = (traitName) => {

    let conflictTraitsTemp = [...conflictTraits]
    if (!conflictTraitsTemp.includes(traitName)){
      conflictTraitsTemp.push(traitName)
    }
    setConflictTraits(conflictTraitsTemp)
  }

  const removeFromConflict = (traitName) => {
    let index = conflictTraits.indexOf(traitName);
    if (index > -1) {
      let conflictTraitsTemp = [...conflictTraits]
      conflictTraitsTemp.splice(index, 1)
      setConflictTraits(conflictTraitsTemp)
    }
  }

  const addToEditConflict = (traitName) => {
    let conflictTraitsTemp = [...editConflictTraitArray]
    if (!conflictTraitsTemp.includes(traitName)){
      conflictTraitsTemp.push(traitName)
    }
    setEditConflictTraitArray(conflictTraitsTemp)
  }

  const removeFromEditConflict = (traitName) => {
    console.log("got here")
    let index = editConflictTraitArray.indexOf(traitName);
    if (index > -1) {
      let conflictTraitsTemp = [...editConflictTraitArray]
      conflictTraitsTemp.splice(index, 1)
      setEditConflictTraitArray(conflictTraitsTemp)
    }
  }

  const saveConflict = () => {
    console.log(builderState)
    var data = JSON.stringify({
      "projectID": props.projectID,
      "action": "addConflict",
      "conflictName": conflictName,
      "traitsInvolved": conflictTraits,
      "categoriesInvolved": [firstCategory, secondCategory],
      "collectionName": builderState,
      "priority": conflictDict[builderState]?.length ? conflictDict[builderState].length + 1 : 1
    });

    var config = {
      method: 'post',
      url: 'https://59200uzilj.execute-api.us-east-1.amazonaws.com/Production/updatebuilderconfig',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      console.log(response)
      setConflictDict(response.data.conflicts)
      setAddNew(false)
      setConflictTraits([])
    })
    .catch(function (error) {
      console.log(error);
    })
  }

  const saveEditConflict = () => {
    var data = JSON.stringify({
      "projectID": props.projectID,
      "action": "editConflict",
      "conflictName": editConflict.conflictName,
      "traitsInvolved": editConflictTraitArray
    });

    var config = {
      method: 'post',
      url: 'https://59200uzilj.execute-api.us-east-1.amazonaws.com/Production/updatebuilderconfig',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      console.log(response)
      setConflictDict(response.data.conflicts)
      setEdit(false)
      setEditConflict()
      setEditConflictTraitArray()
    })
    .catch(function (error) {
      console.log(error);
    })
  }

  const renderConflicts = () => {
    let conflictsArray = Array(conflictDict[builderState].length)
    conflictDict[builderState].map(conflict => {
      conflictsArray[conflict.priority - 1] = (
        <div className='mb-10'>
          <div className='max-w-[250px] text-white uppercase font-bold mx-auto border-2 border-yellow-deep bg-gray-400 py-[2px] px-8' onClick={() => handlePrioritySwitch(conflict)}>{conflict.conflictName} - {conflict.priority}</div>
          <div className='mt-2'>
              <button onClick={() => {setToEdit(conflict)}} className="border border-gray-400 w-28 rounded-md text-sm font-gilroy-bold text-gray-400 py-1 mr-2">edit!</button>
              <button onClick={() => {deleteConflict(conflict)}} className="border border-gray-400 w-28 rounded-md text-sm font-gilroy-bold text-gray-400 py-1">delete!</button>
          </div>
        </div>
        )
    })
    return conflictsArray
  }

  const deleteConflict = (conflict) => {
    console.log(conflict)
    var data = JSON.stringify({
      "projectID": props.projectID,
      "action": "deleteConflict",
      "conflict": conflict
    });

    var config = {
      method: 'post',
      url: 'https://59200uzilj.execute-api.us-east-1.amazonaws.com/Production/updatebuilderconfig',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      console.log(response)
      setConflictDict(response.data.conflicts)
    })
    .catch(function (error) {
      console.log(error);
    })
  }

  const handlePrioritySwitch = (conflict) => {
    console.log(firstConflict, conflict)
    if (firstConflict){
      var data = JSON.stringify({
        "projectID": props.projectID,
        "action": "editConflictPriority",
        "firstConflict": firstConflict,
        "secondConflict": conflict
      });

      var config = {
        method: 'post',
        url: 'https://59200uzilj.execute-api.us-east-1.amazonaws.com/Production/updatebuilderconfig',
        headers: {
          'x-api-key': process.env.GATEWAY_KEY,
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
      .then(function (response) {
        console.log(response)
        setConflictDict(response.data.conflicts)
        setFirstConflict()
      })
      .catch(function (error) {
        console.log(error);
      })
    }

    else{
      setFirstConflict(conflict)
    }
  }

  const setToEdit = (conflict) => {
    setEdit(true)
    setEditConflict(conflict)
    setEditConflictTraitArray(conflict.traitsInvolved)
  }

  const renderEditConflict = () => {
    console.log(editConflict, 'edit')
    return (
      <div className='w-full min-h-[500]'>
          <div className='bg-gray-200 w-full min-h-[500px] p-5'>
              <h2 className='text-xl text-gray-deep font-gilroy-bold mb-3 uppercase'>{editConflict.conflictName}</h2>
              <p className='text-[18px] leading-6 font-semibold text-gray-deep mb-2 text-left'>
                Conflict Categories:
              </p>
              <p className='text-[18px] leading-6 font-lekton text-gray-deep mb-5 text-left'>
                {editConflict.categoriesInvolved[0]} | {editConflict.categoriesInvolved[1]}
              </p>
              <div className='mt-3'>
                 <h3 className='text-lg text-gray-deep font-gilroy-bold mb-1 text-end'>Current conflict traits</h3>
                   <div className='grid grid-cols-2 gap-5 items-start'>
                     <ul className='flex flex-col gap-1'>
                         {
                           assetDict[builderState][editConflict.categoriesInvolved[0]].map(trait => {
                             return(
                               <li onClick={() => addToEditConflict(trait.traitName)} className='text-lg font-bold text-red-light cursor-pointer'>{trait.traitName}</li>
                             )
                           })
                         }
                     </ul>
                     <div>
                         <ul className='grid gap-2'>
                             {editConflictTraitArray.map(trait => {
                               console.log(trait)
                               return (
                                 <li onClick={() => removeFromEditConflict(trait)} className='flex items-center justify-between border-2 border-gray-300 py-1 px-2 text-lg font-semibold text-gray-deep'>{trait}<button><FaTrashAlt/></button></li>
                               )
                             })}
                         </ul>
                     </div>
                  </div>
              </div>
          </div>
          <div className='text-center'>
            <p className='text-[16px] leading-6 text-gray-deep my-2'>click any to swap the order</p>
            <button className="bg-black py-2 px-5 text-[16px] font-gilroy-bold rounded-md text-white" onClick={() => {saveEditConflict()}}>Save Conflict!</button>
          </div>
      </div>
    )
  }

  const switchBuilderState = (collectionName) => {
    console.log(collectionName)
    setBuilderState(collectionName)
    setFirstCategory(orderArrayDict[collectionName][0])
    setSecondCategory(orderArrayDict[collectionName][1])
    setCategories(orderArrayDict[collectionName])
    setCategoriesSubsection(orderArrayDict[collectionName])
  }

  return (
    <div className='w-[80%] ml-auto px-5'>
        {
          builderStates.length ?
            <div>
              <select className="bg-gray-light border-2 border-gray-400 font-bold text-[18px] px-2 uppercase cursor-pointer text-grey-deep w-[30%] h-12 leading-8" value={builderState} onChange={(e) => switchBuilderState(e.target.value)}>
                  {builderStates.map((collectionName) => (
                      <option value={collectionName} className="bg-gray-400 text-[16px] font-bold uppercase hover:bg-gray-deep cursor-pointer text-white hover:text-yellow-light px-2 py-[2px]">{collectionName}</option>
                  ))}
              </select>
            </div>
            :
            <div>
            </div>
        }
        <div className='max-w-4xl grid grid-cols-2 gap-10 p-5  items-center'>
          {
            addNew ?
            <>
            <div className='w-full min-h-[500]'>
                <div className='bg-gray-200 w-full min-h-[500px] p-5'>
                     <h2 className='text-xl text-gray-deep font-gilroy-bold mb-1'>NEW CONFLICT</h2>
                     <input className='w-3/4 bg-gray-200 border-2 border-gray-300 px-1 mb-3 focus:outline-none text-[16px] placeholder:italic placeholder:font-semibold py-[2px]' name="conflictName" type="text" maxLength={26} onChange={e => {onChange(e)}}/>
                     <p className='text-[14px] leading-6 font-lekton text-gray-deep mb-2 text-left'>
                          conflict categories (these are the categories that will switch ordering for any traits you select)
                     </p>
                     <p className='text-[14px] leading-6 font-lekton text-gray-deep mb-2 text-left'>
                          if any of these traits are selected, the default layering is overwritten and the primary and secondary categories selected will be swapped.
                     </p>
                     <p className='text-[14px] leading-6 font-lekton text-gray-deep mb-2 text-left'>
                        select the categories that	make up this conflict
                     </p>
                     <div className='grid grid-cols-2 gap-5'>
                        <div className='flex flex-col relative'>
                          <select className="bg-gray-400 relative font-bold text-[14px] px-2 uppercase cursor-pointer text-white w-full" value={firstCategory} onChange={(e) => setFirstCategory(e.target.value)}>
                            {categories.map((category) => (
                              <option className='text-white absolute top-0 right-2' value={category}>{category}</option>
                            ))}
                          </select>
                        </div>
                        <div className='flex flex-col relative'>
                          <select className="bg-gray-400 relative font-bold text-[14px] px-2 uppercase cursor-pointer text-white w-full" value={secondCategory} onChange={(e) => setSecondCategory(e.target.value)}>
                            {categoriesSubsection.map((category) => (
                              <option value={category}>{category}</option>
                            ))}
                          </select>
                        </div>
                     </div>
                     <div className='mt-3'>
                        <h3 className='text-lg text-gray-deep font-gilroy-bold mb-1 text-end'>Selected conflict traits</h3>
                          <div className='grid grid-cols-2 gap-5 items-start'>
                            <ul className='flex flex-col gap-1'>
                              {
                              assetDict[builderState][firstCategory].map(trait => {
                                return(
                                  <li onClick={() => addToConflict(trait.traitName)} className='text-lg font-bold text-red-light cursor-pointer'>{trait.traitName}</li>
                                )
                              })
                              }
                            </ul>
                            <div>
                                <ul className='grid gap-2'>
                                  {conflictTraits.map(trait => {
                                    return (
                                      <li onClick={() => removeFromConflict(trait)} className='flex items-center justify-between border-2 border-gray-300 py-1 px-2 text-lg font-semibold text-gray-deep'>{trait}<button><FaTrashAlt/></button></li>
                                    )
                                  })}
                                </ul>
                            </div>
                          </div>
                     </div>
                </div>
                <div className='text-center'>
                  {
                      conflictTraits.length === 0 ?
                      ""
                      :
                      <>
                      <p className='text-[16px] leading-6 text-gray-deep my-2'>click any to swap the order</p>
                      <button className="bg-black py-2 px-5 text-[16px] font-gilroy-bold rounded-md text-white" onClick={() => {saveConflict()}}>Save Conflict!</button>
                      </>
                    }
                </div>
            </div>
            </>
            :
            <>
            {
              edit ?
              renderEditConflict()
              :
              <div className='w-full bg-gray-200 p-5 text-center min-h-[600px]'>
                  <h2 className='text-xl text-gray-deep font-gilroy-bold text-left mb-5'>Category Conflicts</h2>
                  <button className="bg-gray-deep text-yellow-light font-gilroy-regular text-lg py-1 px-10 rounded-md" onClick={() => {setAddNew(true)}}>Add Conflict</button>
                  <div className='mt-5'>
                      {conflictDict[builderState]?.length ?
                            <>
                              <p className='text-[14px] leading-6 font-lekton text-gray-deep mb-5 text-left'>
                                  These are all the conflicts - click any two to change their priority (conflicts are managed in order of priority).
                              </p>
                              {
                                renderConflicts()
                              }
                            </>
                            :
                            <>
                            <p className='text-lg leading-6 text-gray-deep text-center max-w-[350px] mx-auto mb-5'>
                              There are no conflicts
                            </p>
                            </>
                      }
                  </div>
              </div>
            }
            </>
          }
          {
            addNew ?
            <p className='text-lg text-gray-400 text-left text-[20px]'>
                The layering order for the primary category on the left selected and the secondary category on the right selected are swapped
                whenever the chosen traits are selected. This is meant to help solve specific layering conflicts
                within your collection.
            </p>
            :
            <p className='text-lg text-gray-400 text-left text-[20px]'>
                Category conflicts are a useful tool to define custom layering logic tied to specific traits. This
                supersedes the default layering order defined by your builder and customizer.
            </p>
          }

        </div>
    </div>
  )

};

export default EditCategoryConflicts;
