import React, {useState, useEffect} from "react";
import axios from "axios";
import Arweave from 'arweave';
import ProgressBar from "@ramonak/react-progress-bar";

import Loader from "../assets/img/loading.gif"

import { Metaplex, keypairIdentity, walletAdapterIdentity, toMetaplexFileFromBrowser } from "@metaplex-foundation/js";
import { Connection, clusterApiUrl, PublicKey } from "@solana/web3.js";
import * as bs58 from "bs58";

import { WalletNotConnectedError } from '@solana/wallet-adapter-base';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';

import { uploadFile } from 'react-s3';
import S3 from 'react-aws-s3';
window.Buffer = window.Buffer || require("buffer").Buffer;


const MutationDash = (props) => {

  const [cost, setCost] = useState()
  const [currencyHash, setCurrencyHash] = useState()
  const [tokenNeeded, setTokenNeeded] = useState()
  const [tokenHash, setTokenHash] = useState()
  const [live, setLive] = useState()

  const [radioCurrencyType, setRadioCurrencyType] = useState()

  const [hasMutation, setHasMutation] = useState(false)

  useEffect(() => {
    var data = JSON.stringify({
      "action": "getMutationDetails",
      "projectID": props.projectID
    });

    var config = {
      method: 'post',
      url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/mutations',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': process.env.GATEWAY_KEY
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      console.log(response.data)
      if (response.data.hasMutation){
        setHasMutation(1)
        setCost(response.data.mutationDetails.cost)
        setCurrencyHash(response.data.mutationDetails.currencyHash)
        setTokenNeeded(response.data.mutationDetails.tokenNeeded)
        setTokenHash(response.data.mutationDetails.tokenHash)
        setLive(response.data.mutationDetails.live)
      }
    })
    .catch(function (error) {
      console.log(error);
    })
  }, []);

  const onChange = (e) => {
    if (e.target.name === "cost"){
      setCost(e.target.value)
    }

    else if (e.target.name === "solana" || e.target.name === "splToken"){
      setRadioCurrencyType(e.target.name)
      if (e.target.name === "solana"){
        setCurrencyHash('XXX')
      }
    }

    else if (e.target.name === 'purchasingCoin'){
      setCurrencyHash(e.target.value)
    }
    // else if (e.target.name === "symbol"){
    //   setSymbol(e.target.value)
    // }
    // else if (e.target.name === "externalLink"){
    //   setExternalLink(e.target.value)
    // }
    // else if (e.target.name === "supply"){
    //   setSupply(e.target.value)
    // }
    // else if (e.target.name === "sellerFee"){
    //   setSellerFee(e.target.value)
    // }
    // else if (e.target.name === "description"){
    //   setDescription(e.target.value)
    // }
    // else if (e.target.name === "price"){
    //   setPrice(parseFloat(e.target.value))
    // }

    // else if (e.target.name === 'traitCategory'){
    //   setTraitCategory(e.target.value)
    // }
    // else if (e.target.name === 'traitMetadataName'){
    //   setTraitMetadataName(e.target.value)
    // }
  }

  const renderForm = () => {
    return (
      <div className='w-[80%] ml-auto px-5'>
        <div className='max-w-xl ml-36 mr-auto mb-5 relative grid gap-7'>

          <div className='text-center'>
            <div>
              <h3 className='font-gilroy-bold text-xl text-gray-deep'>Select the purchasing coin for your mutation</h3>
              <p className='text-lg text-gray-400 font-medium'>you can sell the trait in SOL or in any SPL-Token</p>
              <br></br>
              <div className='flex justify-between w-[60%] m-auto '>
                <div className='text-center'>
                  <input className='font-gilroy-bold text-xl text-gray-deep' onChange={e => {onChange(e)}} checked={radioCurrencyType === "solana"} type="radio" value="solana" name="solana" />
                  <p className='text-lg text-gray-deep font-medium'>Sell in SOL</p>
                </div>
                <div className='text-center'>
                  <input className='font-gilroy-bold text-xl text-gray-deep' onChange={e => {onChange(e)}} checked={radioCurrencyType === "splToken"} type="radio" value="splToken" name="splToken" />
                  <p className='text-lg text-gray-deep font-medium'>Sell in another SPL Token</p>
                </div>
              </div>
              <br></br>
              {
                radioCurrencyType ?
                  radioCurrencyType !== "solana" ?
                    <div>
                      <div className='text-center'>
                        <div>
                          <h3 className='font-gilroy-bold text-xl text-gray-deep'>SPL Hash</h3>
                          <p className='text-lg text-gray-400 font-medium'>please input the hash of the SPL-Token you want to sell this mutation in</p>
                          <input name="purchasingCoin" type="text" onChange={e => {onChange(e)}} className="text-gray-deep font-bold bg-gray-light w-[40%] border-2 border-gray-400 p-[1px] focus:outline-0 " autoComplete="off"/>
                        </div>
                      </div>
                    </div>
                    :
                    <div>
                    </div>
                  :
                  <div>
                  </div>
              }
            </div>
          </div>

          <div className='text-center'>
            <div>
              <h3 className='font-gilroy-bold text-xl text-gray-deep'>Input the cost of a mutation</h3>
              <p className='text-lg text-gray-400 font-medium'>please input a whole number</p>
              <input name="cost" type="text" maxLength={26} onChange={e => {onChange(e)}} className="text-gray-deep font-bold bg-gray-light w-[40%] border-2 border-gray-400 p-[1px] focus:outline-0" autoComplete="off"/>
            </div>
          </div>
          <br></br>
          <button onClick={() => {saveMutation()}} className="m-auto bg-red-deep text-white py-2 w-40 font-bold text-lg text-center rounded-md">Save Mutation</button>
        </div>
      </div>
    )
  }

  const saveMutation = () => {
    var data = JSON.stringify({
      "projectID": props.projectID,
      "cost": cost,
      "currencyHash": currencyHash,
      "action": "saveMutationDetails"
    });

    var config = {
      method: 'post',
      url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/mutations',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      console.log(response)
      setHasMutation(true)
    })
    .catch(function (error) {
      console.log(error);
    })
  }


  const renderMutation = () => {
    return (
      <div>
        Mutation Information
      </div>
    )
  }

  return (
    <div>
      Need to add analytics and upgrading here - do we want to allow for editing?
    </div>
  )

};

export default MutationDash;
